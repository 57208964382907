import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Label,
  Table,
  Button,
  Input,
  FormGroup,
  Col,
  Spinner
} from 'reactstrap';
import ProjectTaskTemplateService from '../../../../Services/projectTaskTemplateService';
import TenantCurrencyService from '../../../../Services/tenantCurrencyService';
import { withLocalization } from '../../../Shared/LocalizationProvider';

class TaskTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      tenantCurrencies: [],
      loading: false
    };

    this.templateService = new ProjectTaskTemplateService(props.translate);
    this.tenantCurrencyService = new TenantCurrencyService(
      sessionStorage.tid,
      props.translate
    );
  }

  componentDidMount() {
    this.getTemplates();
    this.getCurrencies();
  }

  componentWillUnmount() {
    this.templateService.abortController.abort();
    this.tenantCurrencyService.abortController.abort();
  }

  async getCurrencies() {
    const results = await this.tenantCurrencyService.getTenantsCurrencies();
    const defaultCurrency = results.find(currency => currency.isDefault)
      .currencyCode;
    this.setState({
      tenantCurrencies: results,
      defaultCurrency
    });
  }

  getTemplates = async () => {
    this.setState({ loading: true }, async () => {
      const results = await this.templateService.getTemplates();
      this.setState({ templates: results.results, loading: false });
    });
  };

  handleKeyPress = (event, data) => {
    if (event.key === 'Enter') {
      this.updateTemplate(event, data);
    }
  };

  updateBillable = async (template, checked) => {
    const newTemplate = { ...template, isBillable: checked };
    const result = await this.templateService.updateTemplate(newTemplate);
    if (result) {
      await this.getTemplates();
    }
  };

  validateCurrency = value => {
    const { translate } = this.props;
    let errorMessage;
    if (!value) errorMessage = translate('Projects.Required');
    return errorMessage;
  };

  addTemplate = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(false);

    const result = await this.templateService.addTemplate(values);
    if (result) {
      this.getTemplates();
      resetForm();
    }
  };

  updateTemplate = async (event, template) => {
    const { templates } = this.state;
    const cIndex = templates.findIndex(t => t.id === template.id);
    this.name = event.target.name;
    this.value = event.target.value;

    if (this.name === 'name') {
      if (template.name !== this.value) {
        if (this.value.length > 0) {
          const updatedTemplate = { ...template, name: this.value };
          if (
            !templates.some(
              t =>
                t.name === updatedTemplate.name &&
                t.currencyCode === updatedTemplate.currencyCode
            )
          ) {
            templates[cIndex].nameClassName = 'form-control';
            const result = await this.templateService.updateTemplate(
              updatedTemplate
            );
            if (result) {
              await this.getTemplates();
            }
          } else {
            templates[cIndex].nameClassName = 'form-control is-invalid';
            this.setState({ templates });
          }
        }
      }
    } else if (this.name === 'unitPrice') {
      if (this.value >= 0) {
        const newValue = !(typeof this.value === 'number')
          ? parseFloat(this.value)
          : this.value;

        if (template.unitPrice !== newValue && !Number.isNaN(newValue)) {
          const updatedTemplate = { ...template, unitPrice: newValue };
          const result = await this.templateService.updateTemplate(
            updatedTemplate
          );
          if (result) {
            await this.getTemplates();
          }
        }
      }
    } else if (this.name === 'currencyCode') {
      const updatedTemplate = { ...template, currencyCode: this.value };
      if (
        !templates.some(
          t =>
            t.name === updatedTemplate.name &&
            t.currencyCode === updatedTemplate.currencyCode
        )
      ) {
        templates[cIndex].currencyClassName = 'form-control';
        const result = await this.templateService.updateTemplate(
          updatedTemplate
        );
        if (result) {
          await this.getTemplates();
        }
      } else {
        templates[cIndex].currencyClassName = 'form-control is-invalid';
        this.setState({ templates });
      }
    }
  };

  async deleteTemplate(id) {
    await this.templateService.deleteTemplate(id);
    this.getTemplates();
  }

  render() {
    const {
      templates,
      tenantCurrencies,
      defaultCurrency,
      loading
    } = this.state;
    const { translate } = this.props;

    let templateTable;
    let tenantCurrencyOptions;

    if (tenantCurrencies.length > 0) {
      tenantCurrencyOptions = tenantCurrencies.map(obj => (
        <option key={obj.currencyCode} value={obj.currencyCode}>
          {obj.name}
        </option>
      ));
    } else if (tenantCurrencies.length === 0) {
      tenantCurrencyOptions = (
        <option key="empty" value="">
          {translate('Projects.Empty')}
        </option>
      );
    }

    if (templates.length === 0 && loading === false) {
      templateTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (templates.length > 0 && loading === false) {
      templateTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.Templates')}</th>
              <th>{translate('Projects.UnitPrice')}</th>
              <th>{translate('Projects.Currency')}</th>
              <th>{translate('Projects.Billable')}</th>
              <th className="text-right">{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {templates.map(row => (
              <tr key={row.id}>
                <td>
                  <Input
                    id={`project-task-template-list-name-input-${row.id}`}
                    name="name"
                    defaultValue={row.name}
                    pattern=".{1,}"
                    className={row.nameClassName}
                    required
                    onBlur={e => this.updateTemplate(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <Input
                    id={`project-task-template-list-unitPrice-input-${row.id}`}
                    type="number"
                    min="0"
                    step="any"
                    name="unitPrice"
                    defaultValue={row.unitPrice}
                    onBlur={e => this.updateTemplate(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <Input
                    id={`project-task-template-list-currencyCode-input-${
                      row.id
                    }`}
                    type="select"
                    name="currencyCode"
                    value={row.currencyCode}
                    className={row.currencyClassName}
                    onChange={e => this.updateTemplate(e, row)}
                  >
                    {tenantCurrencyOptions}
                  </Input>
                </td>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      id={`project-task-template-list-isBillable-input-${
                        row.id
                      }`}
                      type="checkbox"
                      name="isBillable"
                      defaultChecked={row.isBillable}
                      onChange={() => {
                        const isBillable = !row.isBillable;
                        this.updateBillable(row, isBillable);
                      }}
                    />
                  </div>
                </td>
                <td className="text-right">
                  <Button
                    id={`project-task-template-list-button-delete-${row.id}`}
                    className="btn btn-danger"
                    onClick={() => this.deleteTemplate(row.id)}
                  >
                    {translate('Actions.Delete')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else if (loading === true) {
      templateTable = <Spinner type="grow" color="success" size="lg" />;
    }

    return (
      <div className="margin-bottom">
        <Label size="lg">{translate('Projects.TitleTaskTemplates')}</Label>
        {templateTable}
        <Label size="lg">{translate('Projects.CreateTemplate')}</Label>
        <Formik
          initialValues={{
            name: '',
            unitPrice: 0.0,
            isBillable: false,
            currencyCode: defaultCurrency || ''
          }}
          validate={values => {
            const errors = {};
            const unitPrice = Number(values.unitPrice);

            if (
              templates.some(
                t =>
                  t.name === values.name &&
                  t.currencyCode === values.currencyCode
              )
            )
              errors.name = translate('Projects.TemplateExists');
            if (!values.name) errors.name = translate('Projects.Required');
            if (!values.currencyCode)
              errors.currencyCode = translate('Projects.Required');
            if (values.name.length > 256)
              errors.name = translate('Projects.ErrorNameLength256');
            if (values.unitPrice < 0) {
              errors.unitPrice = translate('Projects.UnitPriceErrorNegative');
            }
            if (Number.isNaN(unitPrice)) {
              errors.unitPrice = translate(
                'Projects.UnitPriceErrorMustBeNumber'
              );
            }

            return errors;
          }}
          enableReinitialize
          onSubmit={this.addTemplate}
        >
          {({ values, errors, touched }) => (
            <Form>
              <FormGroup row>
                <Col sm={4}>
                  <Label>{translate('Projects.Name')}</Label>
                  <Field name="name">
                    {({ field }) => (
                      <div>
                        <input
                          {...field}
                          id="project-task-template-create-form-name-input"
                          className="form-control"
                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={2}>
                  <Field name="unitPrice">
                    {({ field }) => (
                      <div>
                        <Label>{translate('Projects.UnitPrice')}</Label>
                        <input {...field} className="form-control" />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={4}>
                  <Label for="project-task-template-create-form-currencyCode-input">
                    {translate('Projects.Currency')}
                  </Label>
                  <Field
                    id="project-task-template-form-currencyCode-input"
                    className="form-control"
                    name="currencyCode"
                    selected={values.currencyCode}
                    validate={this.validateCurrency}
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.SelectCurrency')}
                    </option>
                    {tenantCurrencyOptions}
                  </Field>
                  {errors.currencyCode && touched.currencyCode ? (
                    <div className="errors text-danger">
                      {errors.currencyCode}
                    </div>
                  ) : null}
                </Col>
                <Col sm={2}>
                  <Field name="isBillable">
                    {({ field }) => (
                      <div>
                        <Label for="project-task-template-create-form-isBillable-input">
                          {translate('Projects.Billable')}
                        </Label>
                        <input
                          {...field}
                          checked={values.isBillable}
                          type="checkbox"
                          id="project-time-task-create-form-isBillable-input"
                        />
                      </div>
                    )}
                  </Field>
                </Col>
              </FormGroup>
              <button
                type="submit"
                id="project-task-template-form-submitButton"
                className="btn btn-secondary"
              >
                {translate('Projects.AddTemplate')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withLocalization(TaskTemplates);
