import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Label,
  Table,
  Button,
  Input,
  FormGroup,
  Col,
  Spinner
} from 'reactstrap';
import ProjectExpenseCategoryService from '../../../../Services/projectExpenseCategoryService';
import TenantCurrencyService from '../../../../Services/tenantCurrencyService';
import UnitService from '../../../../Services/unitService';
import { withLocalization } from '../../../Shared/LocalizationProvider';

class ExpenseCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      tenantCurrencies: [],
      units: [],
      loading: false
    };

    this.categoryService = new ProjectExpenseCategoryService(props.translate);
    this.tenantCurrencyService = new TenantCurrencyService(
      sessionStorage.tid,
      props.translate
    );
    this.unitService = new UnitService(props.translate);
  }

  componentDidMount() {
    this.getCategories();
    this.getCurrencies();
    this.getUnits();
  }

  componentWillUnmount() {
    this.categoryService.abortController.abort();
    this.tenantCurrencyService.abortController.abort();
    this.unitService.abortController.abort();
  }

  async getCurrencies() {
    const results = await this.tenantCurrencyService.getTenantsCurrencies();
    const defaultCurrency = results.find(currency => currency.isDefault)
      .currencyCode;
    this.setState({
      tenantCurrencies: results,
      defaultCurrency
    });
  }

  async getUnits() {
    const results = await this.unitService.getUnits();
    this.setState({ units: results.results });
  }

  getCategories = async () => {
    this.setState({ loading: true }, async () => {
      const results = await this.categoryService.getCategories();
      this.setState({ categories: results.results, loading: false });
    });
  };

  handleKeyPress = (event, data) => {
    if (event.key === 'Enter') {
      this.updateCategory(event, data);
    }
  };

  validateCurrency = value => {
    const { translate } = this.props;

    let errorMessage;
    if (!value) errorMessage = translate('Projects.Required');
    return errorMessage;
  };

  addCategory = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(false);

    const result = await this.categoryService.addCategory(values);
    if (result) {
      this.getCategories();
      resetForm();
    }
  };

  updateCategory = async (event, category) => {
    const { categories } = this.state;
    const cIndex = categories.findIndex(c => c.id === category.id);
    this.name = event.target.name;
    this.value = event.target.value;

    if (this.name === 'name') {
      if (category.name !== this.value) {
        if (this.value.length > 0) {
          const updatedCategory = { ...category, name: this.value };
          if (
            !categories.some(
              c =>
                c.name === updatedCategory.name &&
                c.currencyCode === updatedCategory.currencyCode &&
                c.unitId === updatedCategory.unitId
            )
          ) {
            categories[cIndex].nameClassName = 'form-control';

            const result = await this.categoryService.updateCategory(
              updatedCategory
            );
            if (result) {
              await this.getCategories();
            }
          } else {
            categories[cIndex].nameClassName = 'form-control is-invalid';
            this.setState({ categories });
          }
        }
      }
    } else if (this.name === 'unitPrice') {
      if (this.value >= 0) {
        const newValue = !(typeof this.value === 'number')
          ? parseFloat(this.value)
          : this.value;

        if (category.unitPrice !== newValue && !Number.isNaN(newValue)) {
          const updatedCategory = { ...category, unitPrice: newValue };
          const result = await this.categoryService.updateCategory(
            updatedCategory
          );
          if (result) {
            await this.getCategories();
          }
        }
      }
    } else if (this.name === 'unitId') {
      const updatedCategory = { ...category, unitId: this.value };
      if (
        !categories.some(
          c =>
            c.name === updatedCategory.name &&
            c.currencyCode === updatedCategory.currencyCode &&
            c.unitId === updatedCategory.unitId
        )
      ) {
        categories[cIndex].unitClassName = 'form-control';

        const result = await this.categoryService.updateCategory(
          updatedCategory
        );
        if (result) {
          await this.getCategories();
        }
      } else {
        categories[cIndex].unitClassName = 'form-control is-invalid';
        this.setState({ categories });
      }
    } else if (this.name === 'currencyCode') {
      const updatedCategory = { ...category, currencyCode: this.value };
      if (
        !categories.some(
          c =>
            c.name === updatedCategory.name &&
            c.currencyCode === updatedCategory.currencyCode &&
            c.unitId === updatedCategory.unitId
        )
      ) {
        categories[cIndex].currencyClassName = 'form-control';

        const result = await this.categoryService.updateCategory(
          updatedCategory
        );
        if (result) {
          await this.getCategories();
        }
      } else {
        categories[cIndex].currencyClassName = 'form-control is-invalid';
        this.setState({ categories });
      }
    }
  };

  async deleteCategory(id) {
    await this.categoryService.deleteCategory(id);
    this.getCategories();
  }

  render() {
    const {
      categories,
      tenantCurrencies,
      defaultCurrency,
      units,
      loading
    } = this.state;
    const { translate } = this.props;

    let categoryTable;
    let unitOptions;
    let tenantCurrencyOptions;

    if (tenantCurrencies.length > 0) {
      tenantCurrencyOptions = tenantCurrencies.map(obj => (
        <option key={obj.currencyCode} value={obj.currencyCode}>
          {obj.name}
        </option>
      ));
    } else if (tenantCurrencies.length === 0) {
      tenantCurrencyOptions = (
        <option key="empty" value="">
          {translate('Projects.Empty')}
        </option>
      );
    }

    if (units.length > 0) {
      unitOptions = units.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.names[0].value}
        </option>
      ));
    } else if (units.length === 0) {
      unitOptions = (
        <option key="empty" value="">
          {translate('Projects.Empty')}
        </option>
      );
    }

    if (categories.length === 0 && loading === false) {
      categoryTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (categories.length > 0 && loading === false) {
      categoryTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.Category')}</th>
              <th>{translate('Projects.UnitPrice')}</th>
              <th>{translate('Projects.Currency')}</th>
              <th>{translate('Projects.Unit')}</th>
              <th className="text-right">{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {categories.map(row => (
              <tr key={row.id}>
                <td>
                  <Input
                    id={`project-expense-category-list-name-input-${row.id}`}
                    name="name"
                    defaultValue={row.name}
                    pattern=".{1,}"
                    className={row.nameClassName}
                    required
                    onBlur={e => this.updateCategory(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <Input
                    id={`project-expense-category-list-unitPrice-input-${
                      row.id
                    }`}
                    type="number"
                    min="0"
                    step="any"
                    name="unitPrice"
                    defaultValue={row.unitPrice}
                    onBlur={e => this.updateCategory(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <Input
                    id={`project-expense-category-list-currencyCode-input-${
                      row.id
                    }`}
                    type="select"
                    name="currencyCode"
                    value={row.currencyCode}
                    className={row.currencyClassName}
                    onChange={e => this.updateCategory(e, row)}
                  >
                    {tenantCurrencyOptions}
                  </Input>
                </td>
                <td>
                  <Input
                    id={`project-expense-category-list-unitId-input-${row.id}`}
                    type="select"
                    name="unitId"
                    value={row.unitId}
                    className={row.unitClassName}
                    onChange={e => this.updateCategory(e, row)}
                  >
                    {unitOptions}
                  </Input>
                </td>
                <td className="text-right">
                  <Button
                    id={`project-expense-category-list-button-delete-${row.id}`}
                    className="btn btn-danger"
                    onClick={() => this.deleteCategory(row.id)}
                  >
                    {translate('Actions.Delete')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else if (loading === true) {
      categoryTable = <Spinner type="grow" color="success" size="lg" />;
    }

    return (
      <div className="margin-bottom">
        <Label size="lg">{translate('Projects.TitleExpenseCategories')}</Label>
        {categoryTable}
        <Label size="lg">{translate('Projects.CreateCategory')}</Label>
        <Formik
          initialValues={{
            name: '',
            unitPrice: 0.0,
            unitId: '',
            currencyCode: defaultCurrency || ''
          }}
          validate={values => {
            const errors = {};
            const unitPrice = Number(values.unitPrice);

            if (
              categories.some(
                t =>
                  t.name === values.name &&
                  t.currencyCode === values.currencyCode &&
                  t.unitId === values.unitId
              )
            ) {
              errors.name = translate('Projects.ErrorCategory');
            }
            if (!values.name) errors.name = translate('Projects.Required');
            if (!values.currencyCode)
              errors.currencyCode = translate('Projects.Required');
            if (!values.unitId) errors.unitId = translate('Projects.Required');
            if (values.name.length > 256)
              errors.name = translate('Projects.ErrorNameLength256');
            if (values.unitPrice < 0) {
              errors.unitPrice = translate('Projects.ErrorNegativeNumber');
            }
            if (Number.isNaN(unitPrice)) {
              errors.unitPrice = translate('Projects.ErrorMustBeNumber');
            }

            return errors;
          }}
          enableReinitialize
          onSubmit={this.addCategory}
        >
          {({ values, errors, touched }) => (
            <Form>
              <FormGroup row>
                <Col sm={5}>
                  <Label>{translate('Projects.Name')}</Label>
                  <Field name="name">
                    {({ field }) => (
                      <div>
                        <input
                          {...field}
                          id="project-expense-category-create-form-name-input"
                          className="form-control"
                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={3}>
                  <Field name="unitPrice">
                    {({ field }) => (
                      <div>
                        <Label>{translate('Projects.UnitPrice')}</Label>
                        <input {...field} className="form-control" />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={2}>
                  <Label for="project-expense-category-form-currencyCode-input">
                    {translate('Projects.Currency')}
                  </Label>
                  <Field
                    id="project-expense-category-form-currencyCode-input"
                    className="form-control"
                    name="currencyCode"
                    value={values.currencyCode}
                    validate={this.validateCurrency}
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.SelectCurrency')}
                    </option>
                    {tenantCurrencyOptions}
                  </Field>
                  {errors.currencyCode && touched.currencyCode ? (
                    <div className="errors text-danger">
                      {errors.currencyCode}
                    </div>
                  ) : null}
                </Col>
                <Col sm={2}>
                  <Label for="project-expense-category-form-unitId-input">
                    {translate('Projects.Unit')}
                  </Label>
                  <Field
                    id="project-default-role-form-currencyCode-input"
                    className="form-control"
                    name="unitId"
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.SelectUnit')}
                    </option>
                    {unitOptions}
                  </Field>
                  {errors.unitId && touched.unitId ? (
                    <div className="errors text-danger">{errors.unitId}</div>
                  ) : null}
                </Col>
              </FormGroup>
              <button
                type="submit"
                id="project-expense-category-form-submitButton"
                className="btn btn-secondary"
              >
                {translate('Projects.AddCategory')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withLocalization(ExpenseCategories);
