import { toast } from 'react-toastify';
import { apiBaseUrl } from '../env';
import ApiCrudServiceBase from './apiCrudServiceBase';

class ProjectTeamMemberService extends ApiCrudServiceBase {
  constructor(projectId, teamId, translate) {
    super(`${apiBaseUrl}api/Projects/${projectId}/Teams/${teamId}/Members`);
    this.translate = translate;
  }

  // Returns array of project team members or empty array
  async getMembers() {
    try {
      const response = await this.getAll();
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        return response.body;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return [];
  }

  // Returns a project team member or empty object
  async getMember(id) {
    try {
      const response = await this.get(id);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
        return {};
      }
      return response.body;
    } catch {
      toast.error(this.translate('Toasts.Failed'));
      return null;
    }
  }

  // Returns true if delete was successful, false if it failed
  async deleteMember(id) {
    try {
      const response = await this.delete(id);
      if (response.status !== 204) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.DeleteSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if add was successful, false if it failed
  async addMember(member) {
    try {
      const response = await this.add(member);
      if (response.status !== 201) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.AddSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if update was successful, false if it failed
  async updateMember(member) {
    try {
      const response = await this.update(member);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.UpdateSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default ProjectTeamMemberService;
