import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Label,
  Table,
  Col,
  FormGroup,
  Input,
  Button,
  Spinner
} from 'reactstrap';
import TextService from '../../../../Services/textsService';
import { withLocalization } from '../../../Shared/LocalizationProvider';

class UnitTranslations extends Component {
  constructor(props) {
    super(props);

    this.textService = new TextService(props.translate);
  }

  componentWillUnmount() {
    this.textService.abortController.abort();
  }

  handleKeyPress = (event, translation) => {
    if (event.key === 'Enter') {
      this.editTranslation(event, translation);
    }
  };

  createTranslation = async (values, { setSubmitting, resetForm }) => {
    const { unitService, getTranslations, selectedUnit } = this.props;

    setSubmitting(false);
    const names = [];
    const abbreviations = [];

    const newName = {
      isDefault: values.isDefault,
      languageCode: values.languageCode,
      value: values.translation
    };
    const newAbb = {
      isDefault: values.isDefault,
      languageCode: values.languageCode,
      value: values.abbreviation
    };
    names.push(newName);
    abbreviations.push(newAbb);

    const newData = {
      ...selectedUnit,
      abbreviations,
      names
    };

    const result = await unitService.updateUnit(newData);
    const updatedUnit = await unitService.getUnit(selectedUnit.id);

    if (result) {
      getTranslations(updatedUnit);
      resetForm();
    }
  };

  editTranslation = async (event, data) => {
    const { unitService, selectedUnit } = this.props;
    const abbreviations = [];
    const names = [];

    const index =
      selectedUnit.names.findIndex(name => name.id === data.id) ===
      selectedUnit.abbreviations.findIndex(
        abbr => abbr.id === data.abbreviationId
      )
        ? selectedUnit.names.findIndex(name => name.id === data.id)
        : null;

    this.name = event.target.name;
    this.value = event.target.value;

    if (this.name === 'name') {
      if (data.value !== this.value) {
        if (this.value.length > 0) {
          selectedUnit.names.forEach(name => {
            const newName = { ...name };
            names.push(newName);
          });
          names[index].value = this.value;

          const updatedUnit = { ...selectedUnit, names };

          await unitService.updateUnit(updatedUnit);
        }
      }
    } else if (this.name === 'abbr') {
      if (data.abbreviation !== this.value) {
        if (this.value.length > 0) {
          selectedUnit.abbreviations.forEach(abbr => {
            const newAbbr = { ...abbr };
            abbreviations.push(newAbbr);
          });
          abbreviations[index].value = this.value;

          const updatedUnit = { ...selectedUnit, abbreviations };

          await unitService.updateUnit(updatedUnit);
        }
      }
    }
  };

  updateDefaultTranslation = async (data, checked) => {
    const { unitService, selectedUnit } = this.props;

    const abbreviations = [];
    const names = [];
    let updatedUnit = {};
    const index = selectedUnit.names.findIndex(name => name.id === data.id);

    selectedUnit.abbreviations.forEach(abbr => {
      const newAbbr = { ...abbr, isDefault: false };
      abbreviations.push(newAbbr);
    });
    selectedUnit.names.forEach(name => {
      const newName = { ...name, isDefault: false };
      names.push(newName);
    });

    abbreviations[index].isDefault = checked;
    names[index].isDefault = checked;

    updatedUnit = { ...selectedUnit, abbreviations, names };

    await unitService.updateUnit(updatedUnit);
  };

  deleteTranslation = async data => {
    const { getTranslations, selectedUnit } = this.props;
    const abbreviations = [];
    const names = [];
    let updatedUnit = {};

    selectedUnit.names.forEach(name => {
      if (name.id !== data.id) {
        const newName = { ...name };
        names.push(newName);
      }
    });
    selectedUnit.abbreviations.forEach(abbr => {
      if (abbr.id !== data.abbreviationId) {
        const newAbbr = { ...abbr };
        abbreviations.push(newAbbr);
      }
    });

    updatedUnit = { ...selectedUnit, abbreviations, names };

    const nameResult = await this.textService.deleteText(data.id);
    const abbrResult = await this.textService.deleteText(data.abbreviationId);

    if (nameResult && abbrResult) {
      getTranslations(updatedUnit);
    }
  };

  render() {
    const { translate, translations, noTranslations } = this.props;

    let languageOptions = [];

    languageOptions = noTranslations.map(value => (
      <option key={value} value={value.slice(0, 2)}>
        {value.slice(0, 2)}
      </option>
    ));

    return (
      <div>
        <Label size="lg">{translate('Projects.Translations')}</Label>

        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.Language')}</th>
              <th>{translate('Projects.Abbreviation')}</th>
              <th>{translate('Projects.Translation')}</th>
              <th>{translate('Projects.Default')}</th>
              <th>{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {(translations !== undefined || translations) > 0 ? (
              translations.map(row => (
                <tr key={row.id}>
                  <td>{row.languageCode}</td>
                  <td>
                    <Input
                      id={`unit-translation-list-abbr-input-${row.id}`}
                      name="abbr"
                      defaultValue={row.abbreviation}
                      required
                      onBlur={e => this.editTranslation(e, row)}
                      onKeyDown={e => this.handleKeyPress(e, row)}
                    />
                  </td>
                  <td>
                    <Input
                      id={`unit-translation-list-name-input-${row.id}`}
                      name="name"
                      defaultValue={row.value}
                      required
                      onBlur={e => this.editTranslation(e, row)}
                      onKeyDown={e => this.handleKeyPress(e, row)}
                    />
                  </td>
                  <td>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        id={`unit-translation-list-isDefault-input-${row.id}`}
                        type="radio"
                        name="isDefault"
                        defaultChecked={row.isDefault}
                        onChange={() => {
                          const isDefault = !row.isDefault;
                          this.updateDefaultTranslation(row, isDefault);
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <Button
                      id={`unit-translation-list-delete-${row.id}`}
                      className="btn btn-danger"
                      onClick={() => this.deleteTranslation(row)}
                    >
                      {translate('Actions.Delete')}
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <Spinner type="grow" color="success" size="lg" />
            )}
          </tbody>
        </Table>

        <Formik
          initialValues={{
            isDefault: false,
            languageCode: '',
            translation: '',
            abbreviation: ''
          }}
          validate={values => {
            const errors = {};

            if (!values.translation) {
              errors.translation = translate('Projects.Required');
            }
            if (!values.abbreviation) {
              errors.abbreviation = translate('Projects.Required');
            }
            if (!values.languageCode) {
              errors.languageCode = translate('Projects.Required');
            }

            return errors;
          }}
          onSubmit={this.createTranslation}
        >
          {({ values, errors, touched }) => (
            <Form className="margin-all-sides">
              <Label size="lg">{translate('Projects.CreateTranslation')}</Label>
              <FormGroup row>
                <Col sm={2}>
                  <Field name="isDefault">
                    {({ field }) => (
                      <div>
                        <Label>{translate('Projects.Default')}</Label>
                        <input
                          {...field}
                          checked={values.isDefault}
                          type="checkbox"
                          id="unit-translation-create-form-isDefault-input"
                        />
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={3}>
                  <Label>{translate('Projects.Language')}</Label>
                  <Field
                    id="unit-translation-create-form-languageCode-input"
                    className="form-control"
                    name="languageCode"
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.SelectLanguage')}
                    </option>
                    {languageOptions}
                  </Field>
                  {errors.languageCode && touched.languageCode ? (
                    <div className="errors text-danger">
                      {errors.languageCode}
                    </div>
                  ) : null}
                </Col>
                <Col sm={3}>
                  <Label>{translate('Projects.Abbreviation')}</Label>
                  <Field
                    id="unit-translation-create-form-abbreviation-input"
                    name="abbreviation"
                  >
                    {({ field }) => (
                      <input {...field} className="form-control" />
                    )}
                  </Field>
                  {errors.abbreviation && touched.abbreviation ? (
                    <div className="errors text-danger">
                      {errors.abbreviation}
                    </div>
                  ) : null}
                </Col>
                <Col sm={4}>
                  <Label>{translate('Projects.Translation')}</Label>
                  <Field
                    id="unit-translation-create-form-name-input"
                    name="translation"
                  >
                    {({ field }) => (
                      <input {...field} className="form-control" />
                    )}
                  </Field>
                  {errors.translation && touched.translation ? (
                    <div className="errors text-danger">
                      {errors.translation}
                    </div>
                  ) : null}
                </Col>
              </FormGroup>
              <button
                id="unit-translation-create-form-submit-button"
                type="submit"
                className="btn btn-secondary"
              >
                {translate('Projects.AddTranslation')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withLocalization(UnitTranslations);
