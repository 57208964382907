import { toast } from 'react-toastify';
import { apiBaseUrl } from '../env';
import ApiCrudServiceBase from './apiCrudServiceBase';

class TenantCurrencyService extends ApiCrudServiceBase {
  constructor(tenantId, translate) {
    super(`${apiBaseUrl}api/tenants/${tenantId}/currencies`);
    this.translate = translate;
  }

  async getCurrencyList() {
    const currenciesApi = new ApiCrudServiceBase(`${apiBaseUrl}api/currencies`);
    try {
      const response = await currenciesApi.getAll();
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        return response.body;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return [];
  }

  // Returns array of tenant currencies or empty array
  async getTenantsCurrencies() {
    try {
      const response = await this.getAll();
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        return response.body;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return [];
  }

  // Returns true if delete was successful, false if it failed
  async deleteTenantCurrency(id) {
    try {
      const response = await this.delete(id);
      if (response.status !== 204) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.DeleteSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if add was successful, false if it failed
  async addTenantCurrency(currency) {
    try {
      const response = await this.add(currency);
      if (response.status !== 201) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.AddSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if update was successful, false if it failed
  async updateTenantCurrency(currency) {
    try {
      const response = await this.update(currency);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.UpdateSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default TenantCurrencyService;
