import { toast } from 'react-toastify';
import { apiBaseUrl } from '../env';
import ApiCrudServiceBase from './apiCrudServiceBase';

class UnitService extends ApiCrudServiceBase {
  constructor(translate) {
    super(`${apiBaseUrl}api/units`);
    this.translate = translate;
  }

  // Returns array of units or empty array
  async getUnits(skip = 0, take = 0) {
    try {
      const response = await this.getListPage(skip, take);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        return response.body;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return [];
  }

  // Returns a unit or empty object
  async getUnit(id) {
    try {
      const response = await this.get(id);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
        return {};
      }
      return response.body;
    } catch {
      toast.error(this.translate('Toasts.Failed'));
      return null;
    }
  }

  // Returns true if delete was successful, false if it failed
  async deleteUnit(id) {
    try {
      const response = await this.delete(id);
      if (response.status !== 204) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.DeleteSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if add was successful, false if it failed
  async addUnit(unit) {
    try {
      const response = await this.add(unit);
      if (response.status !== 201) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.AddSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if update was successful, false if it failed
  async updateUnit(unit) {
    try {
      const response = await this.update(unit);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.UpdateSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default UnitService;
