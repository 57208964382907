import React from 'react';
import { withLocalization } from '../../Shared/LocalizationProvider';

function AboutUs(props) {
  return (
    <div className="contact-us-map">
      <div id="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1907.0358968115004!2d23.854805416433724!3d61.446729982449156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468edfb6ff7c2003%3A0x4a3f84f43f33a073!2sNetmaa+Oy!5e0!3m2!1sfi!2sfi!4v1552302784114"
          frameBorder="0"
          style={{ border: '0', width: '100%', height: '450px' }}
          allowFullScreen
        />
      </div>
      <div className="section">
        <div className="container">
          <h1>{props.translate('Navigation.AboutUs')}</h1>
          <p>
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
            dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet
            lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum
            dolor sit amet lorem ipsum dolor sit amet
          </p>
        </div>
      </div>
    </div>
  );
}

export default withLocalization(AboutUs);
