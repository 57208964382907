import { http } from './http';

const ensureSlash = endpoint =>
  endpoint.startsWith('/') ? endpoint : `/${endpoint}`;

class ApiHttpService {
  constructor(baseUrl, signal) {
    this.baseUrl = baseUrl;
    this.signal = signal;
    if (this.baseUrl.endsWith('/'))
      this.baseUrl = this.baseUrl.substr(0, this.baseUrl.length - 1);
  }

  getEndpointUrl(endpoint) {
    return this.baseUrl + ensureSlash(endpoint || '');
  }

  get(endpoint, query = null, headers = new Headers()) {
    return http.get(this.getEndpointUrl(endpoint), query, headers, this.signal);
  }

  post(endpoint, data, query = null, headers = new Headers()) {
    return http.post(
      this.getEndpointUrl(endpoint),
      data,
      query,
      headers,
      this.signal
    );
  }

  put(endpoint, data, query = null, headers = new Headers()) {
    return http.put(
      this.getEndpointUrl(endpoint),
      data,
      query,
      headers,
      this.signal
    );
  }

  delete(endpoint, query = null, headers = new Headers()) {
    return http.delete(
      this.getEndpointUrl(endpoint),
      query,
      headers,
      this.signal
    );
  }
}

export default ApiHttpService;
