import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner
} from 'reactstrap';
import { withLocalization } from '../../Shared/LocalizationProvider';

class TenantInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tenant: props.tenant || {}
    };
  }

  handleTenantInfoChange(e) {
    const { tenant } = this.state;
    this.setState({
      tenant: { ...tenant, [e.target.id]: e.target.value }
    });
  }

  async updateTenantInfo() {
    const updated = await this.props.tenantService.updateTenantInfo(
      this.state.tenant
    );
    if (updated) await this.props.getTenantInfo();
  }

  render() {
    const { tenant } = this.state;
    const { translate, originalValues, loading, initialLoad } = this.props;

    if (initialLoad && loading) {
      return (
        <div className="text-center">
          <Spinner color="success" type="grow" size="lg" />
        </div>
      );
    }
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="name">{translate('TenantInfo.Name')}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={this.state.tenant.name || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="host">{translate('TenantInfo.Host')}</Label>
              <Input
                type="text"
                name="host"
                id="host"
                value={tenant.host || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="businessId">
                {translate('TenantInfo.BusinessId')}
              </Label>
              <Input
                type="text"
                name="businessId"
                id="businessId"
                value={tenant.businessId || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="vatId">{translate('TenantInfo.VatId')}</Label>
              <Input
                type="text"
                name="vatId"
                id="vatId"
                value={tenant.vatId || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="email">{translate('TenantInfo.Email')}</Label>
              <Input
                type="text"
                name="email"
                id="email"
                value={tenant.email || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="phone">{translate('TenantInfo.Phone')}</Label>
              <Input
                type="text"
                name="phone"
                id="phone"
                value={tenant.phone || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="postAddress">
                {translate('TenantInfo.PostAddress')}
              </Label>
              <Input
                type="text"
                name="postAddress"
                id="postAddress"
                value={tenant.postAddress || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="postOffice">
                {translate('TenantInfo.PostOffice')}
              </Label>
              <Input
                type="text"
                name="postOffice"
                id="postOffice"
                value={tenant.postOffice || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="postCode">{translate('TenantInfo.PostCode')}</Label>
              <Input
                type="text"
                name="postCode"
                id="postCode"
                value={tenant.postCode || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="countryCode">
                {translate('TenantInfo.CountryCode')}
              </Label>
              <Input
                type="text"
                name="countryCode"
                id="countryCode"
                value={tenant.countryCode || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="cultureCode">
                {translate('TenantInfo.CultureCode')}
              </Label>
              <Input
                type="text"
                name="cultureCode"
                id="cultureCode"
                value={tenant.cultureCode || ''}
                onChange={e => this.handleTenantInfoChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button
          block
          className="btn-shadow btn-netmaa"
          onClick={() => this.updateTenantInfo()}
          disabled={originalValues === tenant || loading}
        >
          {!loading ? (
            translate('Actions.Save')
          ) : (
            <Spinner color="light" size="sm" />
          )}
        </Button>
        <div className="mb-3" />
      </Form>
    );
  }
}

export default withLocalization(TenantInformation);
