import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Col,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { withLocalization } from '../../../Shared/LocalizationProvider';
import AutoComplete from '../../../Shared/AutoComplete';

class CreateMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmployee: '',
      selectedRole: {},
      createRole: false
    };
  }

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(false);
    await this.addMember(values);
    this.emptyField();
    resetForm();
    this.setState({ selectedEmployee: '', selectedRole: {} });
  };

  setUserInput = value => {
    this.setState({
      userInput: value
    });
  };

  validateMember = value => {
    let errorMessage;
    if (!value) {
      errorMessage = this.props.translate('Projects.Required');
    } else {
      this.setState({ selectedEmployee: value });
    }
    return errorMessage;
  };

  emptyField = () => {
    this.setState({
      userInput: ''
    });
  };

  selectRole = id => {
    const { roles } = this.props;
    const { selectedRole } = this.state;
    const role = roles.find(target => target.id === id);

    if (role.id === selectedRole.id) {
      this.setState({ selectedRole: {} });
    } else if (typeof role === 'object') {
      this.setState({ selectedRole: role });
    }
  };

  toggleCreateRole = () => {
    const { createRole } = this.state;

    this.setState({
      createRole: !createRole
    });
  };

  async addMember(data) {
    const { createRole, uniqueKey } = this.state;
    const { memberService, getMembers, toggleModal, addRole } = this.props;

    if (createRole) {
      const newRole = {
        name: data.role,
        isBillable: data.isBillable,
        unitPrice: data.unitPrice,
        currencyCode: data.currencyCode
      };
      await addRole(newRole);
    }
    const result = await memberService.addMember(data);

    const newUniqueKey = uniqueKey + 1;

    if (result) {
      this.setState({
        selectedRole: {},
        createRole: false,
        uniqueKey: newUniqueKey
      });
      getMembers();
      toggleModal();
    }
  }

  render() {
    const {
      selectedEmployee,
      selectedRole,
      createRole,
      userInput
    } = this.state;

    const {
      translate,
      modalOpen,
      toggleModal,
      employees,
      members,
      roles,
      selectedTeam,
      selectedProject
    } = this.props;

    let roleOptions = [];

    const memberOptions = employees
      .filter(
        employee => !members.some(member => employee.id === member.employeeId)
      )
      .map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.firstName} {obj.lastName}
        </option>
      ));

    memberOptions.unshift(
      <option key="" value="">
        {translate('Projects.SelectMember')}
      </option>
    );

    if (roles.length > 0) {
      roleOptions = roles.filter(
        role => role.currencyCode === selectedProject.currencyCode
      );
    }

    return (
      <div>
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={this.props.toggleModal}>
            {translate('Projects.CreateMember')}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                employeeId: selectedEmployee || '',
                role: selectedRole.name || userInput,
                unitPrice: selectedRole.unitPrice || 0.0,
                isBillable: selectedRole.isBillable || false,
                currencyCode: selectedProject.currencyCode
              }}
              validate={values => {
                const errors = {};
                const unitPrice = Number(values.unitPrice);

                if (createRole) {
                  if (
                    roles.some(
                      t =>
                        t.name === values.role &&
                        t.currencyCode === values.currencyCode
                    )
                  ) {
                    errors.role = translate('Projects.RoleExists');
                  }
                }
                if (values.role.length > 128) {
                  errors.role = translate('Projects.ErrorRoleLength128');
                }
                if (!values.role) errors.role = translate('Projects.Required');
                if (values.unitPrice < 0) {
                  errors.unitPrice = translate(
                    'Projects.UnitPriceErrorNegative'
                  );
                }
                if (Number.isNaN(unitPrice)) {
                  errors.unitPrice = translate(
                    'Projects.UnitPriceErrorMustBeNumber'
                  );
                }

                return errors;
              }}
              enableReinitialize
              onSubmit={this.handleSubmit}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <FormGroup row>
                    <Col sm={6}>
                      <Label for="project-team-member-create-form-employee-input">
                        {translate('Projects.Name')}
                      </Label>
                      <Field
                        id="project-team-member-create-form-employee-input"
                        className="form-control"
                        name="employeeId"
                        validate={this.validateMember}
                        component="select"
                      >
                        {memberOptions.length !== 1 ? (
                          memberOptions
                        ) : (
                          <option key="" value="">
                            {translate('Projects.NoEmployees')}
                          </option>
                        )}
                      </Field>
                      {errors.employeeId && touched.employeeId ? (
                        <div className="errors text-danger">
                          {errors.employeeId}
                        </div>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={6}>
                      <Label for="project-team-member-create-form-role-input">
                        {translate('Projects.Role')}
                      </Label>
                      <Field
                        id="project-team-member-create-form-role-input"
                        name="role"
                        values={roleOptions}
                        selectedOption={selectedRole}
                        selectedProject={selectedProject}
                        selectOption={this.selectRole}
                        setUserInput={this.setUserInput}
                        component={AutoComplete}
                      />
                    </Col>
                    <Col sm={6}>
                      <Field name="unitPrice">
                        {({ field }) => (
                          <div>
                            <Label>
                              {translate('Projects.UnitPrice')} (
                              {selectedProject.currencyCode})
                            </Label>
                            <input
                              {...field}
                              id="project-team-member-create-form-unitPrice-input"
                              className="form-control"
                            />
                            {touched[field.name] && errors[field.name] && (
                              <div className="error text-danger">
                                {errors[field.name]}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={6}>
                      <Field name="isBillable">
                        {({ field }) => (
                          <div>
                            <Label for="project-team-member-create-form-isBillable-input">
                              {translate('Projects.Billable')}
                            </Label>
                            <input
                              {...field}
                              checked={values.isBillable}
                              type="checkbox"
                              id="project-team-member-create-form-isBillable-input"
                            />
                          </div>
                        )}
                      </Field>
                    </Col>
                    <Col sm={6}>
                      <Label for="project-time-task-create-form-createTemplate-input">
                        {translate('Projects.DefaultRole')}?
                      </Label>
                      <input
                        onChange={this.toggleCreateRole}
                        checked={createRole}
                        type="checkbox"
                        id="project-time-task-create-form-createTemplate-input"
                      />
                    </Col>
                  </FormGroup>
                  <button type="submit" className="btn btn-lg btn-secondary">
                    {translate('Actions.Submit')}
                  </button>
                </Form>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter>
            {translate('Projects.Project')}: {selectedProject.name}
            <br />
            {translate('Projects.Team')}: {selectedTeam.name}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withLocalization(CreateMemberModal);
