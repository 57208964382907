import React, { Component } from 'react';
import Select from 'react-select';
import { withLocalization } from './LocalizationProvider';

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      userInput: ''
    };
  }

  onInputChange = (input, action) => {
    const {
      setUserInput,
      field: { name },
      form: { setFieldValue }
    } = this.props;

    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      this.setState({ selectedOption: {}, userInput: input });
      setUserInput(input);

      setFieldValue(name, input);
    }
  };

  onChange = option => {
    const {
      selectOption,
      field: { name },
      form: { setFieldValue }
    } = this.props;

    this.setState({ selectedOption: option, userInput: '' });
    if (option !== null) {
      if (Object.keys(option).length !== 0) {
        selectOption(option.value);
        setFieldValue(name, option.value);
      }
    }
  };

  render() {
    const { selectedOption, userInput } = this.state;
    const {
      translate,
      values,
      field,
      form: { touched, errors }
    } = this.props;

    const options = [];

    if (values.length > 0) {
      values.forEach((obj, i) => {
        options[i] = { value: obj.id, label: obj.name };
        return null;
      });
    }

    return (
      <div>
        <Select
          className="basic-single"
          options={options}
          value={selectedOption}
          inputValue={userInput}
          placeholder={translate('Projects.PlaceholderSearch')}
          noOptionsMessage={() => translate('Projects.NoSuggestions')}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          onBlurResetsInput={false}
          isClearable
          isSearchable
        />
        {touched[field.name] && errors[field.name] && (
          <div className="errors text-danger">{errors[field.name]}</div>
        )}
      </div>
    );
  }
}

export default withLocalization(Autocomplete);
