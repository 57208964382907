import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { withLocalization } from '../../Shared/LocalizationProvider';
import XtraTimePage from './XtraTimePage';
import ProjectContainer from './Projects/ProjectContainer';
import TaskTemplates from './Projects/TaskTemplates';
import DefaultRoles from './Projects/DefaultRoles';
import ExpenseCategories from './Projects/ExpenseCategories';
import UnitContainer from './Projects/UnitContainer';

function XtraTimeContainer() {
  return (
    <div className="container">
      <Row>
        <Col md={3}>
          <NavLink className="navlink-netmaa" exact to="/xtratime">
            <h1>XtraTime</h1>
          </NavLink>
        </Col>
      </Row>
      <Switch>
        <Route exact path="/xtratime" component={XtraTimePage} />
        <Route path="/xtratime/projects" component={ProjectContainer} />
        <Route path="/xtratime/tasktemplates" component={TaskTemplates} />
        <Route path="/xtratime/defaultroles" component={DefaultRoles} />
        <Route path="/xtratime/units" component={UnitContainer} />
        <Route
          path="/xtratime/expensecategories"
          component={ExpenseCategories}
        />
      </Switch>
    </div>
  );
}

export default withLocalization(XtraTimeContainer);
