import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { applicationInsightsKey } from './env';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: applicationInsightsKey,
    disableFetchTracking: false,
    enableAutoRouteTracking: true
  }
});
appInsights.loadAppInsights();

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
