import { toast } from 'react-toastify';
import { apiBaseUrl } from '../env';
import ApiCrudServiceBase from './apiCrudServiceBase';

class TextService extends ApiCrudServiceBase {
  constructor(translate) {
    super(`${apiBaseUrl}api/Texts`);
    this.translate = translate;
  }

  async deleteText(id) {
    try {
      const response = await this.delete(id);
      if (response.status !== 204) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.DeleteSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default TextService;
