export function ticksToDays(ticks) {
  return ticks / 864000000000;
}

export function ticksToHours(ticks = 0) {
  return ticks / 36000000000;
}

export function hoursToTicks(hours) {
  return hours * 36000000000;
}

export function hoursToMilliseconds(hours) {
  return hours * 3600000;
}

export function millisecondsToHours(mSecs) {
  return mSecs / 3600000;
}

export function minutesToHours(minutes) {
  return minutes / 60;
}

export function hoursToClockFormat(decimalHours) {
  let decimalTime = parseFloat(decimalHours);
  decimalTime *= 3600;
  let hours = Math.floor(decimalTime / 3600);
  decimalTime -= hours * 3600;
  let minutes = Math.round(decimalTime / 60);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  } else if (minutes >= 60) {
    minutes = '00';
    hours += 1;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }

  return `${hours}:${minutes}`;
}

export function clockFormatToHours(time) {
  const parsedTime = time.split(':');

  const hours = parseFloat(parsedTime[0]) + minutesToHours(parsedTime[1]);
  return hours;
}
