import { toast } from 'react-toastify';
import apiCrudServiceBase from './apiCrudServiceBase';
import { apiBaseUrl } from '../env';

class CompanyService extends apiCrudServiceBase {
  constructor(tenantId, translate) {
    super(`${apiBaseUrl}api/Tenants/${tenantId}/Companies`);
    this.translate = translate;
  }

  async getBasicInfo(companyId) {
    const companyInfo = await this.http.get(`${companyId}/BasicInfo`);
    return companyInfo.body;
  }

  async getCompanyInfo(companyId) {
    try {
      const tenant = await this.get(companyId);
      if (tenant.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
        return {};
      }
      return tenant.body;
    } catch {
      toast.error(this.translate('Toasts.Failed'));
      return null;
    }
  }

  async updateCompanyInfo(info) {
    try {
      const response = await this.update(info);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.UpdateSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default CompanyService;
