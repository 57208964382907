import { toast } from 'react-toastify';
import { apiBaseUrl } from '../env';
import ApiCrudServiceBase from './apiCrudServiceBase';

class TenantCultureService extends ApiCrudServiceBase {
  constructor(tenantId = null, translate) {
    if (tenantId !== null) {
      super(`${apiBaseUrl}api/tenants/${tenantId}/cultures`);
    } else {
      super(`${apiBaseUrl}api/tenants/current/cultures`);
    }
    this.translate = translate;
  }

  async getCultureList() {
    const culturesAPI = new ApiCrudServiceBase(`${apiBaseUrl}api/cultures`);
    try {
      const response = await culturesAPI.getAll();
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        return response.body;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return [];
  }

  // Returns array of tenant cultures or empty array
  async getTenantCultures() {
    try {
      const response = await this.getAll();
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        return response.body;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return [];
  }

  // Returns true if delete was successful, false if it failed
  async deleteTenantCulture(id) {
    try {
      const response = await this.delete(id);
      if (response.status !== 204) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.DeleteSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if add was successful, false if it failed
  async addTenantCulture(culture) {
    try {
      const response = await this.add(culture);
      if (response.status !== 201) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.AddSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if update was successful, false if it failed
  async updateTenantCulture(culture) {
    try {
      const response = await this.update(culture);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.UpdateSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default TenantCultureService;
