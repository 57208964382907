import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner
} from 'reactstrap';
import { withLocalization } from '../../Shared/LocalizationProvider';

class CompanyInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: props.company || {}
    };
  }

  handleCompanyInfoChange(e) {
    const { company } = this.state;
    this.setState({
      company: { ...company, [e.target.id]: e.target.value }
    });
  }

  async updateCompanyInfo() {
    const updated = await this.props.companyService.updateCompanyInfo(
      this.state.company
    );
    if (updated) await this.props.getCompanyInfo();
  }

  render() {
    const { company } = this.state;
    const { translate, originalValues, initialLoad, loading } = this.props;

    if (initialLoad && loading) {
      return (
        <div className="text-center">
          <Spinner color="success" type="grow" size="lg" />
        </div>
      );
    }
    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="name1">{translate('CompanyInfo.Name1')}</Label>
              <Input
                type="text"
                name="name1"
                id="name1"
                value={company.name1 || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="name2">{translate('CompanyInfo.Name2')}</Label>
              <Input
                type="text"
                name="name2"
                id="name2"
                value={company.name2 || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="businessId">
                {translate('CompanyInfo.BusinessId')}
              </Label>
              <Input
                type="text"
                name="businessId"
                id="businessId"
                value={company.businessId || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="vatId">{translate('CompanyInfo.VatId')}</Label>
              <Input
                type="text"
                name="vatId"
                id="vatId"
                value={company.vatId || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label for="postAddress">
                {translate('CompanyInfo.PostAddress')}
              </Label>
              <Input
                type="text"
                name="postAddress"
                id="postAddress"
                value={company.postAddress || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="postOffice">
                {translate('CompanyInfo.PostOffice')}
              </Label>
              <Input
                type="text"
                name="postOffice"
                id="postOffice"
                value={company.postOffice || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="postCode">{translate('CompanyInfo.PostCode')}</Label>
              <Input
                type="text"
                name="postCode"
                id="postCode"
                value={company.postCode || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="email">{translate('CompanyInfo.Email')}</Label>
              <Input
                type="text"
                name="email"
                id="email"
                value={company.email || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="phone">{translate('CompanyInfo.Phone')}</Label>
              <Input
                type="text"
                name="phone"
                id="phone"
                value={company.phone || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <hr />
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="invoiceEmail">
                {translate('CompanyInfo.InvoiceEmail')}
              </Label>
              <Input
                type="text"
                name="invoiceEmail"
                id="invoiceEmail"
                value={company.invoiceEmail || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="eiacode">{translate('CompanyInfo.Eiacode')}</Label>
              <Input
                type="text"
                name="eiacode"
                id="eiacode"
                value={company.eiacode || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="eiaoperator">
                {translate('CompanyInfo.Eiaoperator')}
              </Label>
              <Input
                type="text"
                name="eiaoperator"
                id="eiaoperator"
                value={company.eiaoperator || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="eiabankOperator">
                {translate('CompanyInfo.EiabankOperator')}
              </Label>
              <Input
                type="text"
                name="eiabankOperator"
                id="eiabankOperator"
                value={company.eiabankOperator || ''}
                onChange={e => this.handleCompanyInfoChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button
          block
          className="btn-shadow btn-netmaa"
          onClick={() => this.updateCompanyInfo()}
          disabled={originalValues === company || loading}
        >
          {!loading ? (
            translate('Actions.Save')
          ) : (
            <Spinner color="light" size="sm" />
          )}
        </Button>
        <div className="mb-3" />
      </Form>
    );
  }
}

export default withLocalization(CompanyInformation);
