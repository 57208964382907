import React, { Component } from 'react';
import { Alert, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import qs from 'query-string';
import { withLocalization } from './LocalizationProvider';
import UserService from '../../Services/userService';

class AccountConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
      companyName: '',
      id: qs.parse(this.props.location.search).id || '',
      emailToken: `${qs.parse(this.props.location.search).token}` || ''
    };
    this.userService = new UserService(this.props.translate);
  }

  async componentDidMount() {
    await this.checkForPassword();
  }

  async checkForPassword() {
    const hasPassword = await this.userService.checkForPassword(this.state.id);
    if (hasPassword) this.props.history.push('/login');
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  async handleConfirmation() {
    const { password, id, emailToken, companyName } = this.state;
    const confirmed = await this.userService.confirmAccount({
      id,
      emailToken,
      password,
      options: {
        create: 'tenant',
        companyName
      }
    });
    if (confirmed) this.props.history.push('/');
  }

  render() {
    const { password, passwordConfirm } = this.state;
    const { translate } = this.props;
    return (
      <div className="container">
        <Col sm={{ size: 6, offset: 3 }}>
          <h1>{translate('AccountConfirm.AccountConfirm')}</h1>
          <Form onSubmit={e => e.preventDefault()}>
            <FormGroup>
              <Label for="companyName" size="lg">
                {translate('AccountConfirm.companyName')}
              </Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password" size="lg">
                {translate('AccountConfirm.Password')}
              </Label>
              <Input
                type="password"
                name="password"
                id="password"
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="passwordConfirm" size="lg">
                {translate('AccountConfirm.ConfirmPassword')}
              </Label>
              <Input
                type="password"
                name="passwordConfirm"
                id="passwordConfirm"
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
            <Alert color="light">
              {translate('AccountConfirm.PasswordLength')}
            </Alert>
            <hr />
            <Button
              id="accountconfirmation-button"
              block
              className="btn-shadow btn-netmaa"
              onClick={() => this.handleConfirmation()}
              disabled={
                password !== passwordConfirm ||
                (password === '' ||
                  (passwordConfirm === '' &&
                    password.length < 8 &&
                    passwordConfirm.length < 8))
              }
            >
              {translate('Actions.Send')}
            </Button>
            <div className="mb-3" />
          </Form>
        </Col>
      </div>
    );
  }
}

export default withLocalization(AccountConfirmation);
