import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Label,
  Table,
  Button,
  Input,
  FormGroup,
  Col,
  Spinner
} from 'reactstrap';
import { withLocalization } from '../../../Shared/LocalizationProvider';
import AutoComplete from '../../../Shared/AutoComplete';

class TaskList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTemplate: {},
      createTemplate: false,
      templateExists: false,
      uniqueKey: 0
    };
  }

  handleKeyPress = (event, data) => {
    if (event.key === 'Enter') {
      this.updateTask(event, data);
    }
  };

  setUserInput = value => {
    this.setState({
      userInput: value
    });
  };

  selectTemplate = id => {
    const { templates } = this.props;
    const { selectedTemplate } = this.state;
    const template = templates.find(target => target.id === id);

    if (template.id === selectedTemplate.id) {
      this.setState({ selectedTemplate: {} });
    } else if (typeof template === 'object') {
      this.setState({ selectedTemplate: template });
    }
  };

  toggleCreateTemplate = () => {
    const { createTemplate } = this.state;
    this.setState({
      createTemplate: !createTemplate,
      templateExists: false
    });
  };

  addTask = async (values, { setSubmitting, resetForm }) => {
    const { createTemplate, uniqueKey } = this.state;
    const { taskService, getTasks, templates, addTemplate } = this.props;
    setSubmitting(false);
    if (createTemplate) {
      if (templates.map(item => item.name).includes(values.name)) {
        this.setState({ templateExists: true });
        return;
      }
      await addTemplate(values);
    }

    const newUniqueKey = uniqueKey + 1;

    const result = await taskService.addTask(values);
    if (result) {
      getTasks();
      this.setState({
        selectedTemplate: {},
        createTemplate: false,
        templateExists: false,
        userInput: '',
        uniqueKey: newUniqueKey
      });
      resetForm();
    }
  };

  updateTask = async (event, task) => {
    const { taskService, getTasks } = this.props;
    this.name = event.target.name;
    this.value = event.target.value;

    if (this.name === 'name') {
      if (task.name !== this.value) {
        if (this.value.length > 0) {
          const updatedTask = { ...task, name: this.value };
          const result = await taskService.updateTask(updatedTask);
          if (result) {
            await getTasks();
          }
        }
      }
    } else if (this.name === 'unitPrice') {
      if (this.value >= 0) {
        const newValue = !(typeof this.value === 'number')
          ? parseFloat(this.value)
          : this.value;

        if (task.unitPrice !== newValue && !Number.isNaN(newValue)) {
          const updatedTask = { ...task, unitPrice: newValue };
          const result = await taskService.updateTask(updatedTask);
          if (result) {
            await getTasks();
          }
        }
      }
    }
  };

  updateBillable = async (task, checked) => {
    const { taskService, getTasks } = this.props;
    const newTask = { ...task, isBillable: checked };
    const result = await taskService.updateTask(newTask);
    if (result) {
      await getTasks();
    }
  };

  async deleteTask(id) {
    const { taskService, getTasks } = this.props;
    await taskService.deleteTask(id);
    getTasks();
  }

  render() {
    const {
      selectedTemplate,
      createTemplate,
      templateExists,
      userInput,
      uniqueKey
    } = this.state;
    const {
      translate,
      tasks,
      templates,
      selectedProject,
      loadingTasks
    } = this.props;

    let templateExistsError = null;
    let templateOptions = [];
    let taskTable;

    if (templateExists) {
      templateExistsError = (
        <div className="error text-danger">
          {translate('Projects.TemplateExists')}
        </div>
      );
    }

    if (templates.length > 0) {
      templateOptions = templates.filter(
        template => template.currencyCode === selectedProject.currencyCode
      );
    }

    if (tasks.length === 0 && loadingTasks === false) {
      taskTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (tasks.length > 0 && loadingTasks === false) {
      taskTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.Tasks')}</th>
              <th>
                {translate('Projects.UnitPrice')} (
                {selectedProject.currencyCode})
              </th>
              <th>{translate('Projects.Billable')}</th>
              <th className="text-right">{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map(row => (
              <tr key={row.id}>
                <td>
                  <Input
                    id={`project-time-task-list-name-input-${row.id}`}
                    name="name"
                    defaultValue={row.name}
                    pattern=".{1,256}"
                    required
                    onBlur={e => this.updateTask(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <Input
                    id={`project-time-task-list-unitPrice-input-${row.id}`}
                    type="number"
                    min="0"
                    step="any"
                    name="unitPrice"
                    defaultValue={row.unitPrice}
                    onBlur={e => this.updateTask(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      id={`project-time-task-list-isBillable-input-${row.id}`}
                      type="checkbox"
                      name="isBillable"
                      defaultChecked={row.isBillable}
                      onChange={() => {
                        const isBillable = !row.isBillable;
                        this.updateBillable(row, isBillable);
                      }}
                    />
                  </div>
                </td>
                <td className="text-right">
                  <Button
                    id="project-time-task-list-button-delete"
                    className="btn btn-danger"
                    onClick={() => this.deleteTask(row.id)}
                  >
                    {translate('Actions.Delete')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else if (loadingTasks === true) {
      taskTable = <Spinner type="grow" color="success" size="lg" />;
    }

    return (
      <div>
        {taskTable}
        <Label size="lg">{translate('Projects.CreateTask')}</Label>
        <Formik
          initialValues={{
            name: selectedTemplate.name || userInput,
            unitPrice: selectedTemplate.unitPrice || 0.0,
            isBillable: selectedTemplate.isBillable || false,
            currencyCode: selectedProject.currencyCode
          }}
          validate={values => {
            const errors = {};
            const unitPrice = Number(values.unitPrice);

            if (!values.name) errors.name = translate('Projects.Required');
            if (values.name.length > 256)
              errors.name = translate('Projects.ErrorNameLength256');
            if (values.unitPrice < 0) {
              errors.unitPrice = translate('Projects.UnitPriceErrorNegative');
            }
            if (Number.isNaN(unitPrice)) {
              errors.unitPrice = translate(
                'Projects.UnitPriceErrorMustBeNumber'
              );
            }

            return errors;
          }}
          enableReinitialize
          onSubmit={this.addTask}
        >
          {({ values, errors, touched }) => (
            <Form>
              <FormGroup row>
                <Col sm={6}>
                  <Label>{translate('Projects.TaskName')}</Label>
                  <Field
                    id="project-time-task-create-form-name-input"
                    name="name"
                    key={uniqueKey}
                    values={templateOptions}
                    selectedOption={this.selectedTemplate}
                    selectOption={this.selectTemplate}
                    setUserInput={this.setUserInput}
                    component={AutoComplete}
                  />
                </Col>
                <Col sm={2}>
                  <Field name="unitPrice">
                    {({ field }) => (
                      <div>
                        <Label>
                          {translate('Projects.UnitPrice')} (
                          {selectedProject.currencyCode})
                        </Label>
                        <input {...field} className="form-control" />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={2}>
                  <Field name="isBillable">
                    {({ field }) => (
                      <div>
                        <Label for="project-time-task-create-form-isBillable-input">
                          {translate('Projects.Billable')}?
                        </Label>
                        <input
                          {...field}
                          checked={values.isBillable}
                          type="checkbox"
                          id="project-time-task-create-form-isBillable-input"
                        />
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={2}>
                  <Label for="project-time-task-create-form-createTemplate-input">
                    {translate('Projects.Template')}?
                  </Label>
                  <input
                    onChange={this.toggleCreateTemplate}
                    checked={createTemplate}
                    type="checkbox"
                    id="project-time-task-create-form-createTemplate-input"
                  />
                  {templateExistsError}
                </Col>
              </FormGroup>
              <button
                type="submit"
                id="project-time-task-form-submitButton"
                className="btn btn-secondary"
              >
                {translate('Projects.AddTask')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withLocalization(TaskList);
