import React, { Component } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Spinner
} from 'reactstrap';
import avatar from '../../../Images/avatar.png';
import { withLocalization } from '../../Shared/LocalizationProvider';

class MyInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user || {}
    };
  }

  handleUserInfoChange(e) {
    const { user } = this.state;
    this.setState({
      user: { ...user, [e.target.id]: e.target.value }
    });
  }

  async updateUserInfo() {
    const updated = await this.props.userInfoService.updateUserInfo(
      this.state.user
    );
    if (updated) await this.props.getUserInfo();
  }

  render() {
    const { user } = this.state;
    const { translate, originalValues, initialLoad, loading } = this.props;

    if (initialLoad && loading) {
      return (
        <div className="text-center">
          <Spinner color="success" type="grow" size="lg" />
        </div>
      );
    }

    return (
      <Form onSubmit={e => e.preventDefault()}>
        <FormGroup row>
          <Label for="profilePic" sm={2} size="lg">
            {translate('OwnInfo.ProfilePicture')}
          </Label>
          <Col sm={4}>
            <img
              style={{ height: '64px', width: '64px', borderRadius: '40%' }}
              src={avatar}
              alt="User"
            />
          </Col>
          <Col sm={6}>
            <Input
              type="file"
              name="profilePic"
              id="profilePic"
              accept="image/jpeg,image/x-png"
            />
            <FormText color="muted">
              *{translate('OwnInfo.PicFormats')}
            </FormText>
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for="firstName" sm={2} size="lg">
            {translate('OwnInfo.FirstName')}
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              bsSize="lg"
              value={user.firstName || ''}
              onChange={e => this.handleUserInfoChange(e)}
            />
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for="lastName" sm={2} size="lg">
            {translate('OwnInfo.LastName')}
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              bsSize="lg"
              value={user.lastName || ''}
              onChange={e => this.handleUserInfoChange(e)}
            />
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for="email" sm={2} size="lg">
            {translate('OwnInfo.Email')}
          </Label>
          <Col sm={10}>
            <Input
              type="email"
              name="email"
              id="email"
              bsSize="lg"
              value={user.email || ''}
              onChange={e => this.handleUserInfoChange(e)}
            />
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for="phone" sm={2} size="lg">
            {translate('OwnInfo.Phone')}
          </Label>
          <Col sm={10}>
            <Input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              bsSize="lg"
              value={user.phoneNumber || ''}
              onChange={e => this.handleUserInfoChange(e)}
            />
          </Col>
        </FormGroup>
        <Button
          block
          className="btn-shadow btn-netmaa"
          onClick={() => this.updateUserInfo()}
          disabled={originalValues === user || loading}
        >
          {!loading ? (
            translate('Actions.Save')
          ) : (
            <Spinner color="light" size="sm" />
          )}
        </Button>
        <div className="mb-3" />
      </Form>
    );
  }
}

export default withLocalization(MyInformation);
