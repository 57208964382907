import { toast } from 'react-toastify';
import ApiCrudServiceBase from './apiCrudServiceBase';
import { apiBaseUrl } from '../env';

class TenantService extends ApiCrudServiceBase {
  constructor(translate) {
    super(`${apiBaseUrl}api/Tenants`);
    this.translate = translate;
  }

  async getTenantInfo(tenantId) {
    try {
      const tenant = await this.get(tenantId);
      if (tenant.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
        return {};
      }
      return tenant.body;
    } catch {
      toast.error(this.translate('Toasts.Failed'));
      return null;
    }
  }

  async updateTenantInfo(info) {
    try {
      const response = await this.update(info);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.UpdateSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default TenantService;
