import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FrontPage from '../Pages/FrontPage/FrontPage';
import AboutUs from '../Pages/AboutUs/AboutUs';
import NotFound from './NotFound';
import AccountConfirmation from './AccountConfirmation';
import InformationContainer from '../Pages/Information/InformationContainer';
import XtraTimeContainer from '../Pages/XtraTime/XtraTimeContainer';

function Content(props) {
  return (
    <Switch>
      <Route exact path="/" component={FrontPage} />
      <Route path="/about" component={AboutUs} />
      <Route
        path="/information"
        render={() => <InformationContainer user={props.user} />}
      />
      <Route path="/xtratime" component={XtraTimeContainer} />
      <Route path="/accountConfirmation" component={AccountConfirmation} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default Content;
