import { toast } from 'react-toastify';
import { apiBaseUrl } from '../env';
import ApiCrudServiceBase from './apiCrudServiceBase';

class UserService extends ApiCrudServiceBase {
  constructor(translate) {
    super(`${apiBaseUrl}api/users`);
    this.translate = translate;
  }

  async checkForPassword(email) {
    const hasPassword = await this.http.get(`/passwordCheck/${email}/`);
    try {
      if (hasPassword.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
        return true;
      }
      if (hasPassword.body) {
        toast.error(this.translate('Toasts.InvalidEmailToken'));
      }
      return hasPassword.body;
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return true;
  }

  async confirmAccount(confirmInfo) {
    try {
      const register = await this.http.post('/confirmation', confirmInfo);
      if (register.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.ConfirmSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  async register(userInfo) {
    try {
      const register = await this.http.post('/register', userInfo);
      if (register.status === 200) {
        toast.success(this.translate('Toasts.RegisterSuccess'));
        return true;
      }
      if (register.status === 409) {
        toast.error(this.translate('Toasts.UserAlreadyExists'));
      } else {
        toast.error(this.translate('Toasts.Failed'));
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  async getUserInfo() {
    try {
      const response = await this.http.get();
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
        return {};
      }
      return response.body;
    } catch {
      toast.error(this.translate('Toasts.Failed'));
      return null;
    }
  }

  async updateUserInfo(info) {
    try {
      const response = await this.update(info);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.UpdateSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default UserService;
