import React from 'react';
import { NavLink } from 'react-router-dom';
import { withLocalization } from './LocalizationProvider';
import { adminPanelUrl } from '../../env';

function SideBar(props) {
  return (
    <nav className="nav-menu">
      <div className="main-menu">
        <h1 className="text-center">
          {props.user && props.user.info ? props.user.info.tenantName : ''}
        </h1>
        <hr />
        <h2>
          {props.user && props.user.info ? props.user.info.companyName1 : ''}{' '}
          {props.user &&
          props.user.info &&
          props.user.info.companyName2 !== null
            ? props.user.info.companyName2
            : ''}
        </h2>
        <p>
          <small>{props.user ? props.user.name : ''}</small>
        </p>
        <NavLink to="/information" className="item">
          {props.translate('Navigation.Information')}
        </NavLink>
        <NavLink to="/xtratime" className="item">
          XtraTime
        </NavLink>
        <a
          id="sidebar-adminpanel-link"
          href={adminPanelUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.translate('Navigation.AdminPanel')}
        </a>
      </div>
      <div className="container">
        <button
          id="sidebar-logout-button"
          type="button"
          className="btn-shadow btn-netmaa btn-block"
          onClick={() => props.destroyToken()}
        >
          {props.translate('Actions.Logout')}
        </button>
      </div>
    </nav>
  );
}

export default withLocalization(SideBar);
