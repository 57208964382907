import React, { Component } from 'react';
import {
  ListGroup,
  ListGroupItem,
  Label,
  Row,
  Col,
  UncontrolledPopover,
  PopoverBody
} from 'reactstrap';
import { withLocalization } from '../../Shared/LocalizationProvider';
import {
  hoursToClockFormat,
  clockFormatToHours,
  millisecondsToHours,
  hoursToMilliseconds
} from './timeSpanHelperMethods';

class TimeSheetTimePicker extends Component {
  constructor(props) {
    super(props);

    this.textInput = React.createRef();
    this.popover = React.createRef();
    this.clickOutside = this.clickOutside.bind(this);

    const hours = [];
    for (let i = 0; i <= 23; i += 1) {
      let x;
      if (i < 10) {
        x = `0${i}`;
      } else {
        x = `${i}`;
      }
      hours.push(x);
    }
    const minutes = [];
    for (let i = 0; i <= 59; i += this.props.interval || 5) {
      let x;
      if (i < 10) {
        x = `0${i}`;
      } else {
        x = `${i}`;
      }
      minutes.push(x);
    }

    this.state = {
      time: props.field.value || '00:00',
      popoverOpen: false,
      hours,
      minutes
    };
  }

  componentWillMount() {
    this.timer = null;
  }

  componentDidUpdate(prevProps) {
    const {
      field: { name },
      form: { values }
    } = this.props;

    if (name === 'totalTime') {
      if (values.totalTime !== prevProps.form.values.totalTime) {
        this.updateTimeState('total');
      }
    } else if (name === 'pauseTime') {
      if (values.pauseTime !== prevProps.form.values.pauseTime) {
        this.updateTimeState('pause');
      }
    } else if (name === 'billableTime') {
      if (values.billableTime !== prevProps.form.values.billableTime) {
        this.updateTimeState('billable');
      }
    }
  }

  changeHour = hours => {
    const {
      field: { value }
    } = this.props;

    this.textInput.current.focus();
    const time = value.split(':');
    const valueString = `${hours}:${time[1]}`;
    this.triggerTimeChange(valueString);
  };

  changeMinute = minutes => {
    const {
      field: { value }
    } = this.props;

    this.textInput.current.focus();
    const time = value.split(':');
    const valueString = `${time[0]}:${minutes}`;
    this.triggerTimeChange(valueString);
    this.hidePopover();
  };

  showPopover = () => {
    document.addEventListener('click', this.clickOutside);
    this.setState({ popoverOpen: true });
  };

  hidePopover = () => {
    document.removeEventListener('click', this.clickOutside);
    this.setState({ popoverOpen: false });
  };

  clickOutside(e) {
    if (this.popover.current.contains(e.target)) {
      return;
    }
    this.hidePopover();
  }

  updateTimeState(value) {
    const {
      form: { values }
    } = this.props;
    if (value === 'total') {
      this.setState({ time: values.totalTime });
    } else if (value === 'pause') {
      this.setState({ time: values.pauseTime });
    } else if (value === 'billable') {
      this.setState({ time: values.billableTime });
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      clearTimeout(this.timer);
      const valueString = event.target.value.toString();
      this.triggerTimeChange(valueString);
    }
  }

  handleTimeChange(event) {
    clearTimeout(this.timer);
    const valueString = event.target.value.toString();
    this.setState({ time: valueString });
    this.timer = setTimeout(() => {
      this.triggerTimeChange(valueString);
    }, 1000);
  }

  triggerTimeChange(valueString) {
    const {
      setTimeError,
      field: { name },
      form: { values, setFieldValue }
    } = this.props;

    let newTime;

    if (valueString.length === 0) {
      setTimeError(`${name}Error`, true);
    } else if (valueString.length > 0) {
      if (valueString.includes('.')) {
        newTime = Number(valueString);
      } else if (valueString.includes(',')) {
        newTime = Number(valueString.replace(',', '.'));
      } else if (valueString.includes(':')) {
        newTime = clockFormatToHours(valueString);
      } else {
        setTimeError(`${name}Error`, true);
        return;
      }

      if (name === 'totalTime') {
        if (Number.isNaN(newTime)) {
          setTimeError(`${name}Error`, true);
          setFieldValue(name, valueString);
        } else {
          setTimeError(`${name}Error`, false);
          if (values.startTime !== null) {
            let startTime;
            if (
              values.startTime.getTime &&
              typeof values.startTime === 'function'
            ) {
              startTime = { ...values.startTime };
            } else {
              startTime = new Date(values.startTime);
            }
            let endTime = new Date(startTime);

            endTime = endTime.setTime(
              endTime.getTime() + hoursToMilliseconds(newTime)
            );
            setFieldValue('endTime', new Date(endTime));
          }
          if (clockFormatToHours(values.pauseTime) > newTime) {
            setFieldValue(
              'pauseTime',
              hoursToClockFormat(millisecondsToHours(newTime))
            );
            setFieldValue('billableTime', '00:00');
          } else {
            const newBillableTime =
              newTime - clockFormatToHours(values.pauseTime);
            setFieldValue('billableTime', hoursToClockFormat(newBillableTime));
          }
          this.setState({ time: hoursToClockFormat(newTime) });
          setFieldValue(name, hoursToClockFormat(newTime));
        }
      } else if (name === 'pauseTime') {
        if (Number.isNaN(newTime)) {
          setTimeError(`${name}Error`, true);
          setFieldValue(name, valueString);
          this.setState({ time: newTime });
        } else {
          setTimeError(`${name}Error`, false);
          const totalTime = clockFormatToHours(values.totalTime);
          if (newTime >= totalTime) {
            setFieldValue(name, values.totalTime);
            setFieldValue('billableTime', '00:00');
            this.setState({ time: values.totalTime });
          } else {
            const newBillableTime = totalTime - newTime;

            setFieldValue(name, hoursToClockFormat(newTime));
            setFieldValue('billableTime', hoursToClockFormat(newBillableTime));
            this.setState({ time: hoursToClockFormat(newTime) });
          }
        }
      } else if (name === 'billableTime') {
        const totalTime = clockFormatToHours(values.totalTime);
        let pauseTime = clockFormatToHours(values.pauseTime);
        if (Number.isNaN(newTime)) {
          setTimeError(`${name}Error`, true);
          setFieldValue(name, valueString);
          this.setState({ time: valueString });
        } else {
          setTimeError(`${name}Error`, false);
          if (newTime > totalTime) {
            newTime = totalTime;
          }
          if (newTime > totalTime - pauseTime) {
            pauseTime = totalTime - newTime;
            setFieldValue('pauseTime', hoursToClockFormat(pauseTime));
          }
          setFieldValue(name, hoursToClockFormat(newTime));
          this.setState({ time: hoursToClockFormat(newTime) });
        }
      }
    }
  }

  render() {
    const { time, hours, minutes, popoverOpen } = this.state;
    const {
      translate,
      field,
      id,
      form: { touched, errors }
    } = this.props;

    const timePickerComponent = (
      <div>
        <UncontrolledPopover
          placement="bottom"
          isOpen={popoverOpen}
          target={id}
          toggle={this.showPopover}
        >
          <div ref={this.popover}>
            <PopoverBody>
              <div>
                <Row>
                  <Col sm={6}>
                    <Label>{translate('Projects.Hours')}</Label>
                    <ListGroup
                      id="hour-list"
                      className="form-control time-sheet-popover-list"
                    >
                      {hours.map(hour => (
                        <ListGroupItem
                          className="time-sheet-popover-list-item"
                          tag="button"
                          href="#"
                          key={hour}
                          id={`hour-${hour}`}
                          onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.changeHour(hour);
                          }}
                        >
                          {hour}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </Col>
                  <Col sm={6}>
                    <Label>{translate('Projects.Minutes')}</Label>
                    <ListGroup
                      id="minute-list"
                      className="form-control time-sheet-popover-list"
                    >
                      {minutes.map(minute => (
                        <ListGroupItem
                          className="time-sheet-popover-list-item"
                          tag="button"
                          href="#"
                          width="250px"
                          key={minute}
                          value={minute}
                          id={`minute-${minute}`}
                          onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.changeMinute(minute);
                          }}
                        >
                          {minute}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </Col>
                </Row>
              </div>
            </PopoverBody>
          </div>
        </UncontrolledPopover>
      </div>
    );

    return (
      <div>
        <input
          id={id}
          ref={this.textInput}
          className="form-control"
          onClick={() => this.showPopover()}
          onChange={event => this.handleTimeChange(event)}
          onKeyDown={event => this.handleKeyDown(event)}
          value={time}
        />
        {timePickerComponent}
        {touched[field.name] && errors[field.name] && (
          <div className="error text-danger">{errors[field.name]}</div>
        )}
      </div>
    );
  }
}

export default withLocalization(TimeSheetTimePicker);
