import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import {
  Col,
  Label,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  Row
} from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';
import ProjectTeamService from '../../../../Services/projectTeamService';
import ProjectTimeTaskService from '../../../../Services/projectTimeTaskService';
import ProjectTaskTemplateService from '../../../../Services/projectTaskTemplateService';
import ProjectExpenseService from '../../../../Services/projectExpenseService';
import ProjectExpenseCategoryService from '../../../../Services/projectExpenseCategoryService';
import { withLocalization } from '../../../Shared/LocalizationProvider';
import DescriptionRtEditor from '../../../Shared/DescriptionRtEditor';
import FormDatePicker from '../../../Shared/FormDatePicker';
import TeamList from './TeamList';
import TeamDetails from './TeamDetails';
import TaskList from './TaskList';
import ExpenseList from './ExpenseList';

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: this.props.getLocale(),
      activeTab: '1',
      selectedTeam: {},
      selectedTask: {},
      templates: [],
      teams: [],
      tasks: [],
      expenses: [],
      categories: [],
      loadingTeams: false,
      loadingTasks: false,
      loadingExpenses: false
    };

    this.teamService = new ProjectTeamService(
      props.selectedProject.id,
      props.translate
    );

    this.taskService = new ProjectTimeTaskService(
      props.selectedProject.id,
      props.translate
    );

    this.templateService = new ProjectTaskTemplateService(props.translate);
    this.expenseService = new ProjectExpenseService(
      props.selectedProject.id,
      props.translate
    );
    this.categoryService = new ProjectExpenseCategoryService(props.translate);
  }

  componentDidMount() {
    this.getTeams();
    this.getTasks();
    this.getTemplates();
    this.getExpenses();
    this.getCategories();
  }

  componentWillUnmount() {
    this.teamService.abortController.abort();
    this.taskService.abortController.abort();
    this.templateService.abortController.abort();
    this.expenseService.abortController.abort();
    this.categoryService.abortController.abort();
  }

  getTeams = async () => {
    this.setState({ loadingTeams: true }, async () => {
      const results = await this.teamService.getTeams();
      this.setState({ teams: results.results, loadingTeams: false });
    });
  };

  getTasks = async () => {
    this.setState({ loadingTasks: true }, async () => {
      const results = await this.taskService.getTasks();
      this.setState({ tasks: results.results, loadingTasks: false });
    });
  };

  getTemplates = async () => {
    const results = await this.templateService.getTemplates();
    this.setState({ templates: results.results });
  };

  getExpenses = async () => {
    this.setState({ loadingExpenses: true }, async () => {
      const results = await this.expenseService.getExpenses();
      this.setState({ expenses: results.results, loadingExpenses: false });
    });
  };

  getCategories = async () => {
    const results = await this.categoryService.getCategories();
    this.setState({ categories: results.results });
  };

  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);

    this.updateProject(values);
  };

  addTemplate = async values => {
    const result = await this.templateService.addTemplate(values);
    if (result) {
      this.getTemplates();
    }
  };

  validateCurrency = value => {
    let errorMessage;
    if (!value) errorMessage = this.props.translate('Projects.Required');
    return errorMessage;
  };

  selectTeam = team => {
    const { selectedTeam } = this.state;
    const { history, selectedProject } = this.props;
    if (team.id === selectedTeam.id) {
      this.setState({ selectedTeam: {} });
    } else if (typeof team === 'object') {
      this.setState({ selectedTeam: team, activeTab: '4' });
      history.push(`/xtratime/projects/${selectedProject.id}/teams/${team.id}`);
    }
  };

  selectCategory = async id => {
    const result = await this.categoryService.getCategory(id);

    return result;
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      if (tab !== '4') {
        this.setState({
          selectedTeam: {}
        });
      }
    }
  };

  async updateProject(data) {
    const {
      history,
      selectedProject,
      projectService,
      getProjects
    } = this.props;
    const result = await projectService.updateProject({
      id: selectedProject.id,
      ...data
    });
    if (result) {
      await getProjects(true);
      history.push('/xtratime/projects');
    }
  }

  render() {
    const {
      locale,
      selectedTeam,
      selectedTask,
      teams,
      tasks,
      templates,
      expenses,
      categories,
      activeTab,
      loadingTeams,
      loadingTasks,
      loadingExpenses
    } = this.state;

    const {
      translate,
      selectedProject,
      tenantCurrencies,
      customers,
      toolbarConfig
    } = this.props;

    let memberTab = null;
    let memberContent = null;
    let tenantCurrencyOptions;
    let customerOptions;

    if (!customers) {
      return [];
    }

    if (tenantCurrencies.length > 0) {
      tenantCurrencyOptions = tenantCurrencies.map(obj => (
        <option key={obj.currencyCode} value={obj.currencyCode}>
          {obj.name}
        </option>
      ));
    } else if (tenantCurrencies.length === 0) {
      tenantCurrencyOptions = (
        <option key="empty" value="">
          {translate('Projects.Empty')}
        </option>
      );
    }

    if (customers.length > 0) {
      customerOptions = customers.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.name1}
        </option>
      ));
    } else if (customers.length === 0) {
      customerOptions = (
        <option key="empty" value="">
          {translate('Projects.Empty')}
        </option>
      );
    }

    if (Object.keys(selectedTeam).length !== 0) {
      memberTab = (
        <NavItem>
          <NavLink
            id="project-link-team-list"
            className={classnames({ active: activeTab === '4' })}
            tag={Link}
            to={`/xtratime/projects/${selectedProject.id}/teams/${
              selectedTeam.id
            }`}
            onClick={() => {
              this.toggle('4');
            }}
          >
            {translate('Projects.SelectedTeam')}: {selectedTeam.name}
          </NavLink>
        </NavItem>
      );
    }

    if (Object.keys(selectedTeam).length !== 0) {
      memberContent = (
        <TabPane tabId="4">
          <Col sm="12">
            <TeamDetails
              tasks={tasks}
              selectedTeam={selectedTeam}
              selectedProject={selectedProject}
            />
          </Col>
        </TabPane>
      );
    }

    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            currencyCode: selectedProject.currencyCode || '',
            customerId: selectedProject.customerId || '',
            name: selectedProject.name || '',
            projectCode: selectedProject.projectCode || '',
            startDate: selectedProject.startDate || null,
            endDate: selectedProject.endDate || null,
            description: selectedProject.description || '',
            timeInterval: selectedProject.timeInterval || 5
          }}
          validate={values => {
            const errors = {};
            const timeInterval = Number(values.timeInterval);

            if (!values.name) errors.name = translate('Projects.Required');
            if (values.name.length > 256)
              errors.name = translate('Projects.ErrorNameLength256');
            if (values.projectCode.length > 128) {
              errors.projectCode = translate(
                'Projects.ErrorProjectCodeLength128'
              );
            }
            if (values.endDate) {
              if (
                values.startDate > values.endDate ||
                new Date(selectedProject.startDate) > values.endDate
              ) {
                errors.startDate = translate(
                  'Projects.StartDateErrorBeforeEndDate'
                );
              }
            }
            if (!Number.isInteger(timeInterval)) {
              errors.timeInterval = translate('Projects.ErrorInteger');
            }
            if (timeInterval < 0) {
              errors.timeInterval = translate('Projects.ErrorBelowZero');
            }

            return errors;
          }}
          onSubmit={this.handleSubmit}
        >
          {({ values, errors, touched }) => (
            <Form>
              <FormGroup row>
                <Col sm={8}>
                  <Field name="name">
                    {({ field }) => (
                      <div>
                        <Label for="project-edit-form-name-input">
                          {translate('Projects.Name')}
                        </Label>
                        <input
                          {...field}
                          id="project-edit-form-name-input"
                          className="form-control"
                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={4}>
                  <Field name="projectCode">
                    {({ field }) => (
                      <div>
                        <Label for="project-edit-form-projectCode-input">
                          {translate('Projects.ProjectCode')}
                        </Label>
                        <input
                          {...field}
                          id="project-edit-form-projectCode-input"
                          className="form-control"
                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col sm={6}>
                  <Label for="project-edit-form-currencyCode-input">
                    {translate('Projects.Currency')}
                  </Label>
                  <Field
                    id="project-edit-form-currencyCode-input"
                    className="form-control"
                    name="currencyCode"
                    validate={this.validateCurrency}
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.SelectCurrency')}
                    </option>
                    {tenantCurrencyOptions}
                  </Field>
                  {errors.currencyCode && touched.currencyCode ? (
                    <div className="errors text-danger">
                      {errors.currencyCode}
                    </div>
                  ) : null}
                </Col>
                <Col sm={6}>
                  <Label for="project-edit-form-customerId-input">
                    {translate('Projects.Customer')}
                  </Label>
                  <Field
                    id="project-edit-form-customerId-input"
                    className="form-control"
                    name="customerId"
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.NoCustomer')}
                    </option>
                    {customerOptions}
                  </Field>
                </Col>
              </FormGroup>

              <FormGroup row style={{ position: 'relative', zIndex: 99 }}>
                <Col sm={4}>
                  <Label for="project-edit-form-startDate-input">
                    {translate('Projects.StartDate')}
                  </Label>
                  <Field
                    name="startDate"
                    id="project-edit-form-startDate-input"
                    placeholder={
                      values.startDate
                        ? moment(values.startDate).format('DD/MM/YYYY')
                        : translate('Projects.StartDate')
                    }
                    locale={locale}
                    component={FormDatePicker}
                  />
                </Col>
                <Col sm={4}>
                  <Label for="project-edit-form-endDate-input">
                    {translate('Projects.EndDate')}
                  </Label>
                  <Field
                    name="endDate"
                    id="project-edit-form-endDate-input"
                    placeholder={
                      values.endDate
                        ? moment(values.endDate).format('DD/MM/YYYY')
                        : translate('Projects.EndDate')
                    }
                    locale={locale}
                    component={FormDatePicker}
                  />
                </Col>
                <Col sm={4}>
                  <Field name="timeInterval">
                    {({ field }) => (
                      <div>
                        <Label for="project-edit-form-timeInterval-input">
                          {translate('Projects.TimeInterval')}
                        </Label>
                        <input
                          {...field}
                          id="project-edit-form-timeInterval-input"
                          className="form-control"
                        />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
              </FormGroup>

              <Label for="description">
                {translate('Projects.Description')}
              </Label>
              <Field
                name="description"
                id="project-edit-form-id-input"
                toolbarConfig={toolbarConfig}
                component={DescriptionRtEditor}
              />

              <br />

              <button
                type="submit"
                id="project-edit-form-submit"
                className="btn btn-lg btn-secondary"
              >
                {translate('Actions.Submit')}
              </button>
            </Form>
          )}
        </Formik>

        <hr />
        <hr />

        <Nav tabs>
          <NavItem>
            <NavLink
              id="project-link-team-list"
              className={classnames({ active: activeTab === '1' })}
              to={`/xtratime/projects/${selectedProject.id}`}
              tag={Link}
              onClick={() => {
                this.toggle('1');
              }}
            >
              {translate('Projects.Teams')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              to={`/xtratime/projects/${selectedProject.id}/tasks`}
              tag={Link}
              onClick={() => {
                this.toggle('2');
              }}
            >
              {translate('Projects.Tasks')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              to={`/xtratime/projects/${selectedProject.id}/expenses`}
              tag={Link}
              onClick={() => {
                this.toggle('3');
              }}
            >
              {translate('Projects.Expenses')}
            </NavLink>
          </NavItem>
          {memberTab}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <TeamList
                  teams={teams}
                  loadingTeams={loadingTeams}
                  activeTab={activeTab}
                  toggle={this.toggle}
                  selectTeam={this.selectTeam}
                  selectedTeam={selectedTeam}
                  teamService={this.teamService}
                  getTeams={this.getTeams}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Col sm="12">
              <TaskList
                tasks={tasks}
                loadingTasks={loadingTasks}
                templates={templates}
                addTemplate={this.addTemplate}
                selectedProject={selectedProject}
                selectedTask={selectedTask}
                taskService={this.taskService}
                getTasks={this.getTasks}
              />
            </Col>
          </TabPane>
          <TabPane tabId="3">
            <Col sm="12">
              <ExpenseList
                expenses={expenses}
                loadingExpenses={loadingExpenses}
                categories={categories}
                selectCategory={this.selectCategory}
                selectedProject={selectedProject}
                getExpenses={this.getExpenses}
                expenseService={this.expenseService}
              />
            </Col>
          </TabPane>
          {memberContent}
        </TabContent>
      </div>
    );
  }
}

export default withLocalization(ProjectDetails);
