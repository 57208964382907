import React, { Component } from 'react';
import { NavLink, Route, Switch, Link } from 'react-router-dom';
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Badge
} from 'reactstrap';
import { withLocalization } from './LocalizationProvider';
import avatar from '../../Images/avatar.png';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  selectLanguage = language => {
    const { setLanguage } = this.props;
    setLanguage(language);
  };

  toggleNavigation() {
    const { navbarOpen } = this.state;
    this.setState({
      navbarOpen: !navbarOpen
    });
  }

  render() {
    const { toggleSidebar, loggedIn, translate } = this.props;

    const xtraTimeNavBar = () => (
      <div className="row">
        <NavItem>
          <NavLink
            id="topbar-xtraTime-navlink"
            className="nav-link"
            to="/xtratime/projects"
          >
            {translate('Navigation.Projects')}
          </NavLink>
        </NavItem>
        <UncontrolledDropdown nav>
          <DropdownToggle id="topbar-project-manage-toggler" nav caret>
            {translate('Navigation.BasicInformation')}
            <i className="ion-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              id="topbar-project-manage-default-role"
              to="/xtratime/defaultroles"
              tag={Link}
            >
              {translate('Navigation.DefaultRoles')}
            </DropdownItem>
            <DropdownItem
              id="topbar-project-manage-expense-category"
              to="/xtratime/expensecategories"
              tag={Link}
            >
              {translate('Navigation.ExpenseCategories')}
            </DropdownItem>
            <DropdownItem
              id="topbar-project-manage-task-template"
              to="/xtratime/tasktemplates"
              tag={Link}
            >
              {translate('Navigation.TaskTemplates')}
            </DropdownItem>
            <DropdownItem
              id="topbar-project-manage-unit"
              to="/xtratime/units"
              tag={Link}
            >
              {translate('Navigation.Units')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );

    return (
      <Navbar className="fixed-top" color="white" light expand="lg">
        <div className="container-fluid">
          <NavbarToggler
            id="topbar-navbar-toggler"
            onClick={() => this.toggleNavigation()}
          />
          <NavLink id="topbar-brand-link" className="navbar-brand" to="/">
            XtraSuite
          </NavLink>
          {loggedIn ? (
            <div
              id="topbar-sidebar-toggler-div"
              className="sidebar-toggle d-lg-none d-xl-none"
              onClick={e => toggleSidebar(e)}
            >
              <img
                id="topbar-sidebar-toggler-img"
                className="sidebar-toggle"
                src={avatar}
                alt="User"
              />
              <Badge color="warning" pill>
                1
              </Badge>
            </div>
          ) : (
            ''
          )}
          <Collapse isOpen={this.state.navbarOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink
                  id="topbar-homepage-link"
                  className="nav-link"
                  exact
                  to="/"
                >
                  {translate('Navigation.Homepage')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  id="topbar-aboutus-link"
                  className="nav-link"
                  to="/about"
                >
                  {translate('Navigation.AboutUs')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  id="topbar-technologies-link"
                  className="nav-link"
                  to="/technologies"
                >
                  {translate('Navigation.Technologies')}
                </NavLink>
              </NavItem>
              <UncontrolledDropdown
                nav
                inNavbar
                className="d-lg-none d-xl-none"
              >
                <DropdownToggle id="topbar-language-toggler" nav caret>
                  {translate('Navigation.Language')}
                  <i className="ion-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    id="topbar-language-en"
                    onClick={() => this.selectLanguage('en')}
                  >
                    {translate('Languages.English')}
                  </DropdownItem>
                  <DropdownItem
                    id="topbar-language-fi"
                    onClick={() => this.selectLanguage('fi')}
                  >
                    {translate('Languages.Finnish')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <Switch>
                <Route path="/xtratime" component={xtraTimeNavBar} />
              </Switch>

              {!loggedIn ? (
                <React.Fragment>
                  <NavItem className="d-lg-none d-xl-none">
                    <NavLink
                      id="topbar-login-link"
                      className="nav-link"
                      to="/login"
                    >
                      {translate('Actions.Login')}
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-lg-none d-xl-none">
                    <NavLink
                      id="topbar-register-link"
                      className="nav-link nav-link--rounded"
                      to="/register"
                    >
                      {translate('Actions.Register')}
                    </NavLink>
                  </NavItem>
                </React.Fragment>
              ) : (
                ''
              )}
            </Nav>
          </Collapse>

          {loggedIn ? (
            <React.Fragment>
              <Navbar className="d-none d-lg-block d-xl-block justify-content-end">
                <Nav navbar>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle id="topbar-language-toggler" nav caret>
                      {translate('Navigation.Language')}
                      <i className="ion-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        id="topbar-language-en"
                        onClick={() => this.selectLanguage('en')}
                      >
                        {translate('Languages.English')}
                      </DropdownItem>
                      <DropdownItem
                        id="topbar-language-fi"
                        onClick={() => this.selectLanguage('fi')}
                      >
                        {translate('Languages.Finnish')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Navbar>
              <div
                id="topbar-sidebar-toggle-div"
                className="sidebar-toggle d-none d-lg-block d-xl-block"
                onClick={e => toggleSidebar(e)}
              >
                <img
                  id="topbar-sidebar-toggle-img"
                  className="sidebar-toggle"
                  src={avatar}
                  alt="User"
                />
                <Badge color="warning" pill>
                  1
                </Badge>
              </div>
            </React.Fragment>
          ) : (
            <Navbar className="d-none d-lg-block d-xl-block justify-content-end">
              <Nav navbar>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle id="topbar-language-toggler" nav caret>
                    {translate('Navigation.Language')}
                    <i className="ion-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      id="topbar-language-en"
                      onClick={() => this.selectLanguage('en')}
                    >
                      {translate('Languages.English')}
                    </DropdownItem>
                    <DropdownItem
                      id="topbar-language-fi"
                      onClick={() => this.selectLanguage('fi')}
                    >
                      {translate('Languages.Finnish')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    id="topbar-login-link"
                    className="nav-link"
                    to="/login"
                  >
                    {translate('Actions.Login')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    id="topbar-register-link"
                    className="nav-link nav-link--rounded"
                    to="/register"
                  >
                    {translate('Actions.Register')}
                  </NavLink>
                </NavItem>
              </Nav>
            </Navbar>
          )}
        </div>
      </Navbar>
    );
  }
}

export default withLocalization(TopBar);
