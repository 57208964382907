import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Label,
  Table,
  Button,
  Input,
  FormGroup,
  Col,
  Spinner
} from 'reactstrap';
import { withLocalization } from '../../../Shared/LocalizationProvider';

class TeamList extends Component {
  handleKeyPress = (event, data) => {
    if (event.key === 'Enter') {
      this.updateTeam(data, event.target.value);
    }
  };

  addTeam = async (values, { setSubmitting, resetForm }) => {
    const { teamService } = this.props;
    setSubmitting(false);

    const result = await teamService.addTeam(values);
    if (result) {
      this.props.getTeams();
      resetForm();
    }
  };

  updateTeam = async (team, teamName) => {
    const { teamService, getTeams } = this.props;
    if (typeof teamName === 'string' && typeof team === 'object') {
      if (team.name !== teamName) {
        if (teamName.length > 0) {
          const newTeam = { ...team, name: teamName };
          const result = await teamService.updateTeam(newTeam);
          if (result) {
            await getTeams();
          }
        }
      }
    }
  };

  async deleteTeam(id) {
    const { teamService, getTeams } = this.props;
    await teamService.deleteTeam(id);
    getTeams();
  }

  render() {
    const { translate, teams, selectTeam, loadingTeams } = this.props;

    let teamTable;

    if (teams.length === 0 && loadingTeams === false) {
      teamTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (teams.length > 0 && loadingTeams === false) {
      teamTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.TeamName')}</th>
              <th className="text-right">{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {teams.map(row => (
              <tr key={row.id}>
                <td>
                  <Input
                    id="project-team-list-name-input"
                    name="name"
                    defaultValue={row.name}
                    pattern=".{1,}"
                    required
                    onBlur={e => this.updateTeam(row, e.target.value)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td className="text-right">
                  <Button
                    id="project-team-list-button-delete"
                    className="btn btn-danger"
                    onClick={() => this.deleteTeam(row.id)}
                  >
                    {translate('Actions.Delete')}
                  </Button>
                  <Button
                    id="project-team-list-button-members"
                    className="btn btn-success"
                    onClick={() => selectTeam(row)}
                  >
                    {translate('Projects.Members')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else if (loadingTeams === true) {
      teamTable = <Spinner type="grow" color="success" size="lg" />;
    }

    return (
      <div>
        {teamTable}
        <Label size="lg">{translate('Projects.CreateTeam')}</Label>
        <Formik
          initialValues={{ name: '' }}
          validate={values => {
            const errors = {};

            if (values.name.length <= 0) {
              errors.name = translate('Projects.Required');
            }
            if (values.name.lengh > 256)
              errors.name = translate('Projects.ErrorNameLength256');
            return errors;
          }}
          onSubmit={this.addTeam}
          render={() => (
            <Form>
              <FormGroup row>
                <Col sm={6}>
                  <Label>{translate('Projects.TeamName')}</Label>
                  <Field name="name">
                    {({ field }) => (
                      <input
                        {...field}
                        id="project-team-create-form-name-input"
                        className="form-control"
                      />
                    )}
                  </Field>
                </Col>
              </FormGroup>
              <button
                type="submit"
                id="project-team-form-submitButton"
                className="btn btn-secondary"
              >
                {translate('Projects.AddTeam')}
              </button>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default withLocalization(TeamList);
