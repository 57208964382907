import React from 'react';
import { withLocalization } from '../../Shared/LocalizationProvider';

function FrontPage(props) {
  return (
    <React.Fragment>
      <div className="index-sidebar-hero">
        <div id="cover-image" className="animated fadeIn">
          <div className="container">
            <h1 className="hero-text animated fadeIn">
              <q>{props.translate('FrontPage.Quote')}.</q>
              <p> - Mahatma Gandhi, 2018</p>
            </h1>
            <div className="cta animated fadeIn">
              <a
                id="frontpage-button"
                href="#top"
                className="btn-shadow btn-white btn-shadow-lg"
              >
                {props.translate('FrontPage.AskPrice')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section">
          <h1>{props.translate('FrontPage.Content')}</h1>
          <p>
            lorem ipsun dolor sit amet lorem ipsun dolor sit amet lorem ipsun
            dolor sit amet lorem ipsun dolor sit amet lorem ipsun dolor sit amet
            lorem ipsun dolor sit amet lorem ipsun dolor sit amet lorem ipsun
            dolor sit amet lorem ipsun dolor sit amet lorem ipsun dolor sit amet
            lorem ipsun dolor sit amet lorem ipsun dolor sit amet lorem ipsun
            dolor sit amet lorem ipsun dolor sit amet lorem ipsun dolor sit amet
            lorem ipsun dolor sit amet lorem ipsun dolor sit amet lorem ipsun
            dolor sit amet lorem ipsun dolor sit amet lorem ipsun dolor sit amet
            lorem ipsun dolor sit amet lorem ipsun dolor sit amet lorem ipsun
            dolor sit amet lorem ipsun dolor sit amet
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withLocalization(FrontPage);
