import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import moment from 'moment';
import {
  Table,
  Row,
  Col,
  ButtonGroup,
  Button,
  Input,
  Spinner,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import TimeSheet from './TimeSheet';
import DescriptionRtEditor from '../../Shared/DescriptionRtEditor';
import TimeSheetDatePicker from './TimeSheetDatePicker';
import TimeSheetTimePicker from './TimeSheetTimePicker';
import { parseJwt } from '../../../Services/loginService';
import ProjectService from '../../../Services/projectService';
import EmployeeService from '../../../Services/employeeService';
import ProjectTimeEntryService from '../../../Services/projectTimeEntryService';
import ProjectTimeTaskService from '../../../Services/projectTimeTaskService';
import { withLocalization } from '../../Shared/LocalizationProvider';
import {
  hoursToTicks,
  ticksToHours,
  hoursToClockFormat,
  clockFormatToHours,
  millisecondsToHours
} from './timeSpanHelperMethods';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fi';

class XtraTimePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: this.props.getLocale(),
      selectedWeekday: moment().isoWeekday(),
      date: new Date(),
      weekDays: [],
      weekOfYear: 1,
      weekProjectHours: [],
      employee: {},
      selectedProject: {},
      selectedEntry: {},
      projects: [],
      tasks: [],
      entryList: [],
      modalOpen: false,
      loading: false,
      hideTimeSheet: true,
      disableEndTime: true,
      totalTimeError: false,
      pauseTimeError: false,
      billableTimeError: false,
      toolbarConfig: { display: [] },
      rteClassName: 'rte-toolbar-hidden-border'
    };

    this.entryService = new ProjectTimeEntryService(props.translate);
    this.projectService = new ProjectService(props.translate);
    this.employeeService = new EmployeeService(props.translate);
    this.taskService = null;
  }

  componentDidMount() {
    const user = parseJwt(sessionStorage.getItem('token'));
    if (user !== null || user !== undefined) {
      this.getWeekdays();
      this.getProjects();
      if (
        Object.prototype.hasOwnProperty.call(user, 'project_manager') ||
        Object.prototype.hasOwnProperty.call(user, 'project_member')
      ) {
        this.getEmployee(user.sub);
      }
    }
  }

  componentWillUnmount() {
    this.entryService.abortController.abort();
    this.projectService.abortController.abort();
    this.employeeService.abortController.abort();
    if (this.taskService !== null) this.taskService.abortController.abort();
  }

  getEmployee = async id => {
    this.setState({ loading: true }, async () => {
      const employees = await this.employeeService.getEmployees();
      if (Object.prototype.hasOwnProperty.call(employees, 'results')) {
        const employee = employees.results.find(e => e.identityId === id);
        if (employee !== undefined) {
          this.getEntries(employee.id);

          this.setState({ employee });
        }
        this.setState({ loading: false });
      }
    });
  };

  getEntries = async (
    employeeId,
    projectId = this.state.selectedProject.id
  ) => {
    this.setState({ loading: true }, async () => {
      const results = await this.entryService.getEntries(employeeId);
      if (results) {
        const entryList = results.results.filter(
          v => v.projectId === projectId
        );
        this.setState({ entryList }, () => this.setProjectHoursOfWeek());
      }
      this.setState({ loading: false });
    });
  };

  getTasks = async () => {
    this.setState({ loading: true }, async () => {
      const results = await this.taskService.getTasks();
      this.setState({ loading: false, tasks: results.results });
    });
  };

  getProjects = async () => {
    this.setState({ loading: true }, async () => {
      const result = await this.projectService.getProjects();
      this.setState({
        projects: result.results,
        loading: false
      });
    });
  };

  getWeekdays = (date = moment()) => {
    const weekOfYear = date.week();
    const weekDays = [];
    for (let i = 0; i < 7; i += 1) {
      weekDays.push(
        date
          .startOf('isoWeek')
          .add(i, 'days')
          .format('DD.MM.YYYY')
      );
    }
    this.setState({ weekDays, weekOfYear });
  };

  setDay = selectedWeekday => {
    const { date } = this.state;
    this.setState({
      selectedWeekday,
      date: moment(date)
        .startOf('isoWeek')
        .add(selectedWeekday - 1, 'days')
        .toDate()
    });
  };

  setTimeError(name, value) {
    this.setState({ [name]: value });
  }

  setProjectHoursOfWeek = () => {
    const { entryList, weekDays } = this.state;

    const weekDates = [];
    const weekProjectHours = [];

    weekDays.forEach(day => {
      const dayArray = day.split('.');
      const newDay = new Date(dayArray[2], dayArray[1], dayArray[0]);
      newDay.setMonth(newDay.getMonth() - 1);
      weekDates.push(newDay);
    });

    weekDates.forEach(date => {
      let sum = 0;
      const filteredEntries = entryList.filter(
        entry =>
          (new Date(entry.startTime).setHours(0, 0, 0, 0) <=
            date.setHours(0, 0, 0, 0) &&
            new Date(entry.endTime).setHours(0, 0, 0, 0) >=
              date.setHours(0, 0, 0, 0)) ||
          (new Date(entry.startTime).setHours(0, 0, 0, 0) <=
            date.setHours(0, 0, 0, 0) &&
            entry.endTime === null) ||
          (entry.startTime === null &&
            new Date(entry.endTime).setHours(0, 0, 0, 0) >=
              date.setHours(0, 0, 0, 0)) ||
          (entry.startTime === null && entry.endTime === null)
      );

      filteredEntries.forEach(entry => {
        sum += entry.timeSpan;
      });
      weekProjectHours.push(sum);
    });
    this.setState({ weekProjectHours });
  };

  toggleModal = async () => {
    await this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  };

  deleteEntry = async id => {
    const { employee } = this.state;
    await this.entryService.deleteEntry(id);
    this.getEntries(employee.id);
  };

  selectProject = event => {
    const { projects, employee } = this.state;
    const { translate } = this.props;
    this.value = event.target.value;

    if (this.taskService !== null) this.taskService.abortController.abort();

    if (this.value.length !== 0 && employee.id) {
      const selectedProject = projects.find(v => v.id === this.value);
      this.setState({
        hideTimeSheet: false,
        selectedProject
      });
      this.taskService = new ProjectTimeTaskService(this.value, translate);
      this.getTasks();
      this.getEntries(employee.id, this.value);
    } else {
      this.setState({
        hideTimeSheet: true,
        selectedProject: {},
        entryList: []
      });
    }
  };

  createEntry = () => {
    this.setState({ selectedEntry: {} });
    this.toggleModal();
  };

  selectEntry = data => {
    this.setState({
      selectedEntry: data,
      disableEndTime: !data.startTime
    });
    this.toggleModal();
  };

  handleDay = num => {
    const { date } = this.state;
    const newDate = moment(date.setDate(date.getDate() + num));

    this.setState({ date: newDate.toDate() }, () =>
      this.setProjectHoursOfWeek()
    );
    this.getWeekdays(newDate);
  };

  handleDate = date => {
    this.setState({ date, selectedWeekday: moment(date).isoWeekday() });
    this.getWeekdays(moment(date));
  };

  handleSubmit = (values, { setSubmitting, resetForm }) => {
    const totalTicks = hoursToTicks(clockFormatToHours(values.totalTime));
    const pauseTicks = hoursToTicks(clockFormatToHours(values.pauseTime));
    const billableTicks = hoursToTicks(clockFormatToHours(values.billableTime));

    const newValues = {
      ...values,
      id: this.state.selectedEntry.id,
      timeSpan: totalTicks,
      pauseSpan: pauseTicks,
      billableSpan: billableTicks
    };

    setSubmitting(false);
    if (Object.keys(this.state.selectedEntry).length > 0) {
      this.editEntry(newValues);
    } else {
      this.addEntry(newValues);
    }
    resetForm();
  };

  toggleEndTime = async value => {
    if (typeof value === 'object' && Date.parse(value)) {
      await this.setState({ disableEndTime: false });
    } else if (!Date.parse(value)) {
      await this.setState({ disableEndTime: true });
    }
  };

  async addEntry(data) {
    await this.entryService.addEntry(data);
    this.getEntries(this.state.employee.id);
    this.toggleModal();
  }

  async editEntry(data) {
    await this.entryService.updateEntry(data);
    this.getEntries(this.state.employee.id);
    this.toggleModal();
  }

  render() {
    const {
      locale,
      date,
      selectedWeekday,
      weekDays,
      weekOfYear,
      weekProjectHours,
      modalOpen,
      loading,
      employee,
      selectedProject,
      selectedEntry,
      projects,
      tasks,
      entryList,
      toolbarConfig,
      rteClassName,
      disableEndTime,
      hideTimeSheet,
      totalTimeError,
      pauseTimeError,
      billableTimeError
    } = this.state;
    const { translate, getLocale } = this.props;

    let noEmployeeAccount;
    let timeSheet;
    let entryTable;
    let projectOptions;
    let sum = 0;
    let taskOptions = [];
    let min;
    let max;

    if (selectedProject.startDate !== null) {
      min = new Date(selectedProject.startDate);
    } else {
      min = new Date(1970, 1, 1);
    }
    if (selectedProject.endDate !== null) {
      max = new Date(selectedProject.endDate);
    } else {
      max = new Date(2999, 1, 1);
    }

    if (tasks.length > 0) {
      taskOptions = tasks.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.name}
        </option>
      ));
      taskOptions.unshift(
        <option key="" value="">
          {translate('Projects.SelectTask')}
        </option>
      );
    } else {
      taskOptions = (
        <option key="empty" value="">
          {translate('Projects.NoTasks')}
        </option>
      );
    }

    const filteredEntries = entryList.filter(
      v =>
        (new Date(v.startTime).setHours(0, 0, 0, 0) <=
          date.setHours(0, 0, 0, 0) &&
          new Date(v.endTime).setHours(0, 0, 0, 0) >=
            date.setHours(0, 0, 0, 0)) ||
        (new Date(v.startTime).setHours(0, 0, 0, 0) <=
          date.setHours(0, 0, 0, 0) &&
          v.endTime === null) ||
        (v.startTime === null &&
          new Date(v.endTime).setHours(0, 0, 0, 0) >=
            date.setHours(0, 0, 0, 0)) ||
        (v.startTime === null && v.endTime === null)
    );

    filteredEntries.forEach(entry => {
      sum += entry.timeSpan;
    });

    if (projects.length > 0) {
      projectOptions = projects.map(obj => (
        <option key={obj.id} value={obj.id}>
          [{obj.projectCode}] {obj.name}
        </option>
      ));
    }

    if (loading) {
      return <Spinner type="grow" color="success" size="lg" />;
    }

    if (
      employee === null ||
      employee === undefined ||
      Object.keys(employee).length === 0
    ) {
      noEmployeeAccount = (
        <div>
          <p>{translate('Projects.NoEmployeeAccount')}</p>
        </div>
      );
    } else if (filteredEntries.length === 0 && loading === false) {
      entryTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (filteredEntries.length > 0 && loading === false) {
      entryTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.Task')}</th>
              <th>{translate('Projects.StartTime')}</th>
              <th>{translate('Projects.EndTime')}</th>
              <th>{translate('Projects.TotalTime')}</th>
              <th>{translate('Projects.PauseTime')}</th>
              <th>{translate('Projects.BillableTime')}</th>
              <th className="text-right">{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredEntries.map(row => (
              <tr key={row.id}>
                <td>{row.projectTimeTask.name}</td>
                <td>
                  {row.startTime !== null
                    ? moment(row.startTime).format('DD/MM/YYYY HH:mm')
                    : ''}
                </td>
                <td>
                  {row.endTime !== null
                    ? moment(row.endTime).format('DD/MM/YYYY HH:mm')
                    : ''}
                </td>
                <td>{hoursToClockFormat(ticksToHours(row.timeSpan))}</td>
                <td>{hoursToClockFormat(ticksToHours(row.pauseSpan))}</td>
                <td>
                  {row.isBillable
                    ? hoursToClockFormat(ticksToHours(row.billableSpan))
                    : ''}
                </td>
                <td className="text-right">
                  <ButtonGroup>
                    <Button
                      id="timesheet-time-entry-list-info-button"
                      className="btn btn-success"
                      onClick={() => this.selectEntry(row)}
                    >
                      {translate('Projects.Details')}
                    </Button>
                    <Button
                      id="timesheet-time-entry-list-delete-button"
                      className="btn btn-danger"
                      onClick={() => this.deleteEntry(row.id)}
                    >
                      {translate('Actions.Delete')}
                    </Button>
                    <Button
                      id="timesheet-time-entry-list-pause-button"
                      className="btn btn-warning"
                      onClick={() => console.log('pause button clicked')}
                    >
                      {translate('Projects.Pause')}
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      onClick={() => console.log('start button clicked')}
                    >
                      {translate('Projects.Start')}
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }

    if (!hideTimeSheet) {
      timeSheet = (
        <TimeSheet
          weekDays={weekDays}
          weekProjectHours={weekProjectHours}
          weekOfYear={weekOfYear}
          selectedWeekday={selectedWeekday}
          date={date}
          handleDay={this.handleDay}
          handleDate={this.handleDate}
          setDay={this.setDay}
          getLocale={getLocale}
          createEntry={this.createEntry}
        />
      );
    }

    return (
      <React.Fragment>
        <Modal isOpen={modalOpen} size="lg">
          <ModalHeader toggle={this.toggleModal}>
            {translate('Projects.TimeEntry')}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                projectId: selectedProject.id,
                projectTaskId: selectedEntry.projectTaskId || '',
                employeeId: employee.id,
                description: selectedEntry.description || '',
                innerDescription: selectedEntry.innerDescription || '',
                startTime: selectedEntry.startTime || null,
                endTime: selectedEntry.endTime || null,
                isBillable: selectedEntry.isBillable || false,
                totalTime:
                  hoursToClockFormat(ticksToHours(selectedEntry.timeSpan)) ||
                  '00:00',
                pauseTime:
                  hoursToClockFormat(ticksToHours(selectedEntry.pauseSpan)) ||
                  '00:00',
                billableTime:
                  hoursToClockFormat(
                    ticksToHours(selectedEntry.billableSpan)
                  ) || '00:00',
                discountPercent: selectedEntry.discountPercent || 0.0
              }}
              validate={values => {
                const errors = {};
                const discount = Number(values.discountPercent);

                if (totalTimeError) {
                  errors.totalTime = translate('Projects.ErrorCannotConvert');
                }
                if (pauseTimeError) {
                  errors.pauseTime = translate('Projects.ErrorCannotConvert');
                }
                if (billableTimeError) {
                  errors.billableTime = translate(
                    'Projects.ErrorCannotConvert'
                  );
                }
                if (Number.isNaN(discount)) {
                  errors.discountPercent = translate(
                    'Projects.ErrorMustBeNumber'
                  );
                }
                if (!values.projectTaskId) {
                  errors.projectTaskId = translate('Projects.Required');
                }
                if (!values.description) {
                  errors.description = translate('Projects.Required');
                }
                if (
                  clockFormatToHours(values.pauseTime) >
                  clockFormatToHours(values.totalTime)
                ) {
                  errors.pauseTime = translate('Projects.ErrorPauseTime');
                }
                if (
                  clockFormatToHours(values.billableTime) >
                  clockFormatToHours(values.totalTime) -
                    clockFormatToHours(values.pauseTime)
                ) {
                  errors.billableTime = translate('Projects.ErrorBillableTime');
                }

                if (values.startTime) {
                  const startTime =
                    typeof values.startTime === 'string'
                      ? new Date(values.startTime)
                      : values.startTime;
                  if (values.endTime) {
                    const endTime =
                      typeof values.endTime === 'string'
                        ? new Date(values.endTime)
                        : values.endTime;
                    if (
                      values.totalTime >
                      millisecondsToHours(endTime - startTime)
                    ) {
                      errors.totalTime = translate('Projects.TotalTime');
                    }
                  }
                }

                if (values.endTime) {
                  if (
                    values.startTime > values.endTime ||
                    new Date(selectedEntry.startTime) > values.endTime
                  ) {
                    errors.endTime = translate('Projects.ErrorEndTime');
                  }
                }

                return errors;
              }}
              enableReinitialize
              onSubmit={this.handleSubmit}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <FormGroup row>
                    <Col sm={5}>
                      <Label for="project-time-entry-create-form-task-input">
                        {selectedProject.name} {translate('Projects.Tasks')}
                      </Label>
                      <Field
                        id="project-time-entry-create-form-task-input"
                        className="form-control"
                        name="projectTaskId"
                        component="select"
                      >
                        {taskOptions.length !== 1 ? (
                          taskOptions
                        ) : (
                          <option key="" value="">
                            {translate('Projects.NoTasks')}
                          </option>
                        )}
                      </Field>
                      {errors.projectTaskId && touched.projectTaskId ? (
                        <div className="errors text-danger">
                          {errors.projectTaskId}
                        </div>
                      ) : null}
                    </Col>
                    <Col sm={5}>
                      <Field name="discountPercent">
                        {({ field }) => (
                          <div>
                            <Label for="project-time-entry-create-form-discount-input">
                              {translate('Projects.Discount')}
                            </Label>
                            <input
                              {...field}
                              id="project-time-entry-create-form-discount-input"
                              className="form-control"
                            />
                            {touched[field.name] && errors[field.name] && (
                              <div className="error text-danger">
                                {errors[field.name]}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                    <Col sm={2}>
                      <Field name="isBillable">
                        {({ field }) => (
                          <div>
                            <Label for="project-time-entry-create-form-isBillable-input">
                              {translate('Projects.Billable')}
                            </Label>
                            <input
                              {...field}
                              checked={values.isBillable}
                              type="checkbox"
                              id="project-time-entry-create-form-isBillable-input"
                            />
                          </div>
                        )}
                      </Field>
                    </Col>
                  </FormGroup>

                  <FormGroup row style={{ position: 'relative', zIndex: 99 }}>
                    <Col sm={6}>
                      <Label for="project-time-entry-create-form-startTime-input">
                        {translate('Projects.StartTime')}
                      </Label>
                      <Field
                        name="startTime"
                        id="project-time-entry-create-form-startTime-input"
                        min={min}
                        values
                        interval={selectedProject.timeInterval}
                        toggleEndTime={this.toggleEndTime}
                        placeholder={
                          values.startTime
                            ? moment(values.startTime).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : translate('Projects.StartTime')
                        }
                        locale={locale}
                        component={TimeSheetDatePicker}
                      />
                    </Col>
                    <Col sm={6}>
                      <Label for="project-time-entry-create-form-endTime-input">
                        {translate('Projects.EndTime')}
                      </Label>
                      <Field
                        name="endTime"
                        id="project-time-entry-create-form-endTime-input"
                        min={min}
                        max={max}
                        interval={selectedProject.timeInterval}
                        disableEndTime={disableEndTime}
                        toggleEndTime={this.toggleEndTime}
                        placeholder={
                          values.endTime
                            ? moment(values.endTime).format('DD/MM/YYYY HH:mm')
                            : translate('Projects.EndTime')
                        }
                        locale={locale}
                        component={TimeSheetDatePicker}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col sm={4}>
                      <Label for="project-time-entry-create-form-totalTime-input">
                        {translate('Projects.TotalTime')}
                      </Label>
                      <Field
                        name="totalTime"
                        id="project-time-entry-create-form-totalTime-input"
                        setTimeError={(name, value) =>
                          this.setTimeError(name, value)
                        }
                        component={TimeSheetTimePicker}
                      />
                    </Col>
                    <Col sm={4}>
                      <Label for="project-time-entry-create-form-pauseTime-input">
                        {translate('Projects.PauseTime')}
                      </Label>
                      <Field
                        name="pauseTime"
                        id="project-time-entry-create-form-pauseTime-input"
                        setTimeError={(name, value) =>
                          this.setTimeError(name, value)
                        }
                        component={TimeSheetTimePicker}
                      />
                    </Col>
                    <Col sm={4}>
                      <Label for="project-time-entry-create-form-billableTime-input">
                        {translate('Projects.BillableTime')}
                      </Label>
                      <Field
                        name="billableTime"
                        id="project-time-entry-create-form-billableTime-input"
                        setTimeError={(name, value) =>
                          this.setTimeError(name, value)
                        }
                        component={TimeSheetTimePicker}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Label for="project-time-entry-create-form-description-input">
                      {translate('Projects.Description')}
                    </Label>
                    <Field
                      name="description"
                      id="project-time-entry-create-form-description-input"
                      toolbarConfig={toolbarConfig}
                      rteClassName={rteClassName}
                      component={DescriptionRtEditor}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="project-time-entry-create-form-innerDescription-input">
                      {translate('Projects.InnerDescription')}
                    </Label>
                    <Field
                      name="innerDescription"
                      id="project-time-entry-create-form-innerDescription-input"
                      toolbarConfig={toolbarConfig}
                      rteClassName={rteClassName}
                      component={DescriptionRtEditor}
                    />
                  </FormGroup>

                  <button
                    type="submit"
                    id="project-time-entry-create-form-submit"
                    className="btn btn-lg btn-secondary"
                  >
                    {translate('Actions.Submit')}
                  </button>
                </Form>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter>
            {translate('Projects.SelectedProject')}: {selectedProject.name}
          </ModalFooter>
        </Modal>
        <Row>
          <Col xs={6}>
            <Input
              type="select"
              name="selectProject"
              id="selectProject"
              bsSize="lg"
              defaultValue={selectedProject.id || ''}
              onChange={e => this.selectProject(e)}
            >
              <option key="empty" value="">
                {translate('Projects.SelectProject')}
              </option>
              {projectOptions}
            </Input>
          </Col>
          <Col xs={6}>
            <Button
              size="lg"
              className="btn-netmaa"
              onClick={() => console.log('Requires employeeWorkShift?')}
            >
              {translate('XtraTime.StartWorkday')}
            </Button>
          </Col>
        </Row>
        {timeSheet}
        <Row>
          {noEmployeeAccount}
          {entryTable}
        </Row>
        <div>
          {translate('Projects.Total')}: {hoursToClockFormat(ticksToHours(sum))}
        </div>
      </React.Fragment>
    );
  }
}

export default withLocalization(XtraTimePage);
