import { toast } from 'react-toastify';
import { apiBaseUrl } from '../env';
import ApiCrudServiceBase from './apiCrudServiceBase';

class ProjectTaskTemplateService extends ApiCrudServiceBase {
  constructor(translate) {
    super(`${apiBaseUrl}api/tasktemplates`);
    this.translate = translate;
  }

  // Returns array of project task templates or empty array
  async getTemplates(skip = 0, take = 0) {
    try {
      const response = await this.getListPage(skip, take);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        return response.body;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return [];
  }

  // Returns a project task template or empty object
  async getTemplate(id) {
    try {
      const response = await this.get(id);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
        return {};
      }
      return response.body;
    } catch {
      toast.error(this.translate('Toasts.Failed'));
      return null;
    }
  }

  // Returns true if delete was successful, false if it failed
  async deleteTemplate(id) {
    try {
      const response = await this.delete(id);
      if (response.status !== 204) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.DeleteSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if add was successful, false if it failed
  async addTemplate(template) {
    try {
      const response = await this.add(template);
      if (response.status !== 201) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.AddSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }

  // Returns true if update was successful, false if it failed
  async updateTemplate(template) {
    try {
      const response = await this.update(template);
      if (response.status !== 200) {
        toast.error(this.translate('Toasts.Failed'));
      } else {
        toast.success(this.translate('Toasts.UpdateSuccess'));
        return true;
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
    }
    return false;
  }
}

export default ProjectTaskTemplateService;
