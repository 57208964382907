import React, { Component } from 'react';
import { Button } from 'reactstrap';

class DatePickerButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Button
        color={this.props.color}
        className="border"
        onClick={this.props.onClick}
      >
        <i className="entypo-calendar" />
      </Button>
    );
  }
}

export default DatePickerButton;
