import React, { Component } from 'react';
import { Button, InputGroup, InputGroupAddon, ButtonGroup } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import {
  hoursToClockFormat,
  clockFormatToHours,
  hoursToMilliseconds
} from './timeSpanHelperMethods';
import { withLocalization } from '../../Shared/LocalizationProvider';

class TimeSheetDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.field.value || null,
      localeName: moment.locale(Object.keys(props.getLocale())[0])
    };
  }

  componentDidMount() {
    registerLocale(this.state.localeName, this.props.locale);
  }

  componentDidUpdate(prevProps) {
    const {
      form: { values }
    } = this.props;

    if (this.props.field.name === 'endTime') {
      if (values.endTime !== prevProps.form.values.endTime) {
        this.updateEndTimeState();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleEndTime(null);
  }

  onChange = async date => {
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;

    this.handleTimeChanges(date);

    const newDate = new Date(date);
    await this.setState({
      date: newDate || null
    });

    setFieldValue(name, newDate);
  };

  getDate = () => {
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;

    let newDate = new Date(moment());
    newDate = new Date(newDate.setSeconds(0, 0));

    this.setState({ date: newDate });

    this.handleTimeChanges(newDate);

    setFieldValue(name, newDate);
  };

  emptyDate = () => {
    const {
      toggleEndTime,
      field: { name },
      form: { setFieldValue }
    } = this.props;

    this.setState({ date: null });

    if (name === 'startTime') {
      toggleEndTime({});
      setFieldValue('endTime', null);
    }

    setFieldValue('totalTime', '00:00');
    setFieldValue('billableTime', '00:00');
    setFieldValue('pauseTime', '00:00');
    setFieldValue(name, null);
  };

  updateEndTimeState() {
    this.setState({ date: this.props.form.values.endTime });
  }

  handleTimeChanges(newDate) {
    const {
      toggleEndTime,
      field: { name },
      form: { values, setFieldValue }
    } = this.props;

    if (name === 'startTime' && newDate) {
      toggleEndTime(newDate);

      if (values.totalTime !== '00:00') {
        const totalTime = clockFormatToHours(values.totalTime);
        let newEndTime = new Date();
        newEndTime = newEndTime.setTime(
          newDate.getTime() + hoursToMilliseconds(totalTime)
        );
        setFieldValue('endTime', new Date(newEndTime));
      } else {
        setFieldValue('endTime', null);
      }
    }

    if (name === 'endTime' && newDate) {
      const newTotalTime =
        (newDate.getTime() - new Date(values.startTime).getTime()) / 3600000;

      const newBillableTime =
        newTotalTime - clockFormatToHours(values.pauseTime);

      setFieldValue('totalTime', hoursToClockFormat(newTotalTime));
      setFieldValue('billableTime', hoursToClockFormat(newBillableTime));
    }
  }

  render() {
    const { date, localeName } = this.state;
    const {
      min,
      max,
      disableEndTime,
      interval,
      placeholder,
      field,
      form: { touched, errors }
    } = this.props;

    let dateObject = null;

    if (date) {
      dateObject = new Date(date);
    }

    const formattedDate = date
      ? moment(date.toString()).format('DD/MM/YYYY  HH:mm')
      : '';

    return (
      <div>
        <InputGroup>
          <DatePicker
            className="form-control"
            dateFormat="dd/MM/yyyy HH:mm"
            timeFormat="HH:mm"
            minDate={min}
            maxDate={max}
            timeIntervals={interval}
            defaultValue={date}
            selected={dateObject}
            placeholderText={formattedDate || placeholder}
            onChange={value => this.onChange(value)}
            showMonthDropdown
            showYearDropdown
            showWeekNumbers
            showTimeSelect
            disabled={disableEndTime}
            timeCaption="time"
            locale={localeName}
          />
          <InputGroupAddon addonType="append">
            <ButtonGroup>
              <Button onClick={this.emptyDate} disabled={disableEndTime}>
                <i className="entypo-cross" />
              </Button>
              <Button onClick={this.getDate} disabled={disableEndTime}>
                <i className="icomoon-stopwatch" />
              </Button>
            </ButtonGroup>
          </InputGroupAddon>
        </InputGroup>

        {touched[field.name] && errors[field.name] && (
          <div className="errors text-danger">{errors[field.name]}</div>
        )}
      </div>
    );
  }
}

export default withLocalization(TimeSheetDatePicker);
