import React from 'react';
import { Row, Col, ButtonGroup, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import DatePickerButton from '../../Shared/DatePickerButton';
import { withLocalization } from '../../Shared/LocalizationProvider';
import { ticksToHours, hoursToClockFormat } from './timeSpanHelperMethods';

function TimeSheet({
  translate,
  weekDays,
  weekOfYear,
  weekProjectHours,
  selectedWeekday,
  date,
  handleDay,
  handleDate,
  setDay,
  getLocale,
  createEntry
}) {
  return (
    <div>
      <Row>
        <Col xs={4} sm={6}>
          <h3>{weekDays[selectedWeekday - 1]}</h3>
        </Col>
        <Col xs={3} sm={3}>
          <h3>
            {translate('Projects.Week')} {weekOfYear}
          </h3>
        </Col>
        <Col xs={5} sm={3}>
          <ButtonGroup className="float-right">
            <Button
              color="light"
              onClick={() => handleDay(-7)}
              className="border"
            >
              <i className="entypo-arrow-left" />
            </Button>
            <DatePicker
              customInput={<DatePickerButton color="light" />}
              selected={date}
              onChange={d => handleDate(d)}
              locale={getLocale()}
              todayButton={translate('TimeSheet.Today')}
              withPortal
            />
            <Button
              color="light"
              onClick={() => handleDay(7)}
              className="border"
            >
              <i className="entypo-arrow-right" />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={1} className="d-none d-lg-block">
          <Button
            className="btn-netmaa"
            block
            size="lg"
            onClick={() => createEntry()}
          >
            <i className="entypo-plus" />
          </Button>
        </Col>
        <Col xs={12} sm={11}>
          <ButtonGroup className="d-none d-lg-block float-right" size="lg">
            <Button
              color="light"
              onClick={() => setDay(1)}
              active={selectedWeekday === 1}
              className="border"
            >
              {translate('TimeSheet.Monday')}
              <br />
              00:00
              <br />
              {hoursToClockFormat(ticksToHours(weekProjectHours[0]))}
            </Button>
            <Button
              color="light"
              onClick={() => setDay(2)}
              active={selectedWeekday === 2}
              className="border"
            >
              {translate('TimeSheet.Tuesday')}
              <br />
              00:00
              <br />
              {hoursToClockFormat(ticksToHours(weekProjectHours[1]))}
            </Button>
            <Button
              color="light"
              onClick={() => setDay(3)}
              active={selectedWeekday === 3}
              className="border"
            >
              {translate('TimeSheet.Wednesday')}
              <br />
              00:00
              <br />
              {hoursToClockFormat(ticksToHours(weekProjectHours[2]))}
            </Button>
            <Button
              color="light"
              onClick={() => setDay(4)}
              active={selectedWeekday === 4}
              className="border"
            >
              {translate('TimeSheet.Thursday')}
              <br />
              00:00
              <br />
              {hoursToClockFormat(ticksToHours(weekProjectHours[3]))}
            </Button>
            <Button
              color="light"
              onClick={() => setDay(5)}
              active={selectedWeekday === 5}
              className="border"
            >
              {translate('TimeSheet.Friday')}
              <br />
              00:00
              <br />
              {hoursToClockFormat(ticksToHours(weekProjectHours[4]))}
            </Button>
            <Button
              color="light"
              onClick={() => setDay(6)}
              active={selectedWeekday === 6}
              className="border"
            >
              {translate('TimeSheet.Saturday')}
              <br />
              00:00
              <br />
              {hoursToClockFormat(ticksToHours(weekProjectHours[5]))}
            </Button>
            <Button
              color="light"
              onClick={() => setDay(7)}
              active={selectedWeekday === 7}
              className="border"
            >
              {translate('TimeSheet.Sunday')}
              <br />
              00:00
              <br />
              {hoursToClockFormat(ticksToHours(weekProjectHours[6]))}
            </Button>
            <Button color="light" disabled className="border">
              {translate('TimeSheet.Sum')}.
              <br />
              00:00
              <br />
              {hoursToClockFormat(
                ticksToHours(
                  weekProjectHours[0] +
                    weekProjectHours[1] +
                    weekProjectHours[2] +
                    weekProjectHours[3] +
                    weekProjectHours[4] +
                    weekProjectHours[5] +
                    weekProjectHours[6]
                )
              )}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
}

export default withLocalization(TimeSheet);
