import React, { Component } from 'react';
import { Button, InputGroup, InputGroupAddon } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { withLocalization } from './LocalizationProvider';

class FromDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.props.field.value
        ? moment(this.props.field.value).format('DD/MM/YYYY')
        : '',
      localeName: moment.locale(Object.keys(this.props.getLocale())[0])
    };
  }

  componentDidMount() {
    registerLocale(this.state.localeName, this.props.locale);
  }

  onChange = date => {
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;

    this.setState({
      date: date ? moment(date.toString()).format('DD/MM/YYYY') : ''
    });

    setFieldValue(name, date);
  };

  emptyDate = () => {
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;

    this.setState({ date: null });

    setFieldValue(name, null);
  };

  render() {
    const { date, localeName } = this.state;
    const {
      min,
      max,
      placeholder,
      field,
      form: { touched, errors }
    } = this.props;

    return (
      <div>
        <InputGroup>
          <DatePicker
            className="form-control"
            dateFormat="dd/MM/yyyy"
            minDate={min}
            maxDate={max}
            defaultValue={date}
            placeholderText={date || placeholder}
            onChange={this.onChange}
            showMonthDropdown
            showYearDropdown
            showWeekNumbers
            locale={localeName}
          />
          <InputGroupAddon addonType="append">
            <Button className="btn btn-secondary" onClick={this.emptyDate}>
              <i className="entypo-cross" />
            </Button>
          </InputGroupAddon>
        </InputGroup>

        {touched[field.name] && errors[field.name] && (
          <div className="errors text-danger">{errors[field.name]}</div>
        )}
      </div>
    );
  }
}

export default withLocalization(FromDatePicker);
