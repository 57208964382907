import 'whatwg-fetch'; // fetch polyfill
import '../Utils/dateJsonFormat';
import history from './historyService';
import { refreshToken } from './loginService';

let refreshRequest;

function parseQueryObject(query) {
  let queryParams = '';
  if (query) {
    Object.keys(query).forEach((prop, index) => {
      const value = query[prop];
      if (value instanceof Array) {
        value.forEach(arrayValue => {
          queryParams += `${index > 0 ? '&' : '?'}${prop}=${arrayValue}`;
        });
      } else {
        queryParams += `${index > 0 ? '&' : '?'}${prop}=${value}`;
      }
    });
  }
  return queryParams;
}

const ensureTrailingSlash = url => (url.endsWith('/') ? url : `${url}/`);

async function httpRequest(
  method,
  url,
  query = null,
  data = null,
  headers = null,
  signal = new AbortController().signal
) {
  if (typeof method !== 'string') {
    throw new Error(
      `Expected method parameter to be string. Got '${typeof method}'.`
    );
  }
  if (typeof url !== 'string') {
    throw new Error(
      `Expected URL parameter to be string. Got '${typeof url}'.`
    );
  }

  switch (method.toUpperCase()) {
    case 'GET':
    case 'POST':
    case 'PUT':
    case 'DELETE':
      break;
    default:
      throw new Error(
        `Unknown HTTP method '${method.toUpperCase()}'. Expected GET, POST, PUT or DELETE.`
      );
  }

  const request = {
    method: method.toUpperCase(),
    headers: headers || new Headers()
  };

  // Get the stored access token and add Authorization -header if it exists.
  const accessToken = sessionStorage.getItem('token');
  if (accessToken) {
    request.headers.append('Authorization', `Bearer ${accessToken}`);
  }

  if (data) {
    if (!(data instanceof URLSearchParams)) {
      // If data is anything but form data, assume JSON.
      request.headers.append('Content-Type', 'application/json; charset=utf-8');
      request.body = JSON.stringify(data);
    } else {
      request.headers.append(
        'Content-Type',
        'application/x-www-form-urlencoded; charset=utf-8'
      );
      request.body = data.toString();
    }
  }

  let response = await fetch(
    ensureTrailingSlash(url) + parseQueryObject(query),
    {
      signal,
      ...request
    }
  );

  if (response.status === 403) history.push('/forbidden');
  if (response.status === 401) {
    if (!accessToken || !sessionStorage.getItem('refresh_token')) {
      history.push('/login');
    } else {
      if (!refreshRequest) {
        refreshRequest = refreshToken();
      }

      try {
        await refreshRequest;
        return httpRequest(method, url, query, data, headers);
      } catch (error) {
        response = error;
        history.push('/login');
      } finally {
        refreshRequest = null;
      }
    }
  }

  const result = {
    status: response.status,
    headers: response.headers,
    body: null
  };

  const contentType = (response.headers.get('Content-Type') || '')
    .split(';')[0]
    .toLowerCase();

  if (contentType === 'application/json' || contentType === 'text/json') {
    result.body = await response.json().catch(() => null);
  } else {
    result.body = await response.text().catch(() => null);
  }

  return result; // { status: 200, headers: Headers, body: (optional payload) }
}

export const http = {
  get: (
    url,
    query = null,
    headers = new Headers(),
    signal = new AbortController().signal
  ) => httpRequest('GET', url, query, null, headers, signal),

  post: (
    url,
    data,
    query = null,
    headers = new Headers(),
    signal = new AbortController().signal
  ) => httpRequest('POST', url, query, data, headers, signal),

  put: (
    url,
    data,
    query = null,
    headers = new Headers(),
    signal = new AbortController().signal
  ) => httpRequest('PUT', url, query, data, headers, signal),

  delete: (
    url,
    query = null,
    headers = new Headers(),
    signal = new AbortController().signal
  ) => httpRequest('DELETE', url, query, null, headers, signal)
};

export default http;
