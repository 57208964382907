import moment from 'moment';
import React from 'react';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import enLocale from 'date-fns/locale/en-GB';
import fiLocale from 'date-fns/locale/fi';
import getDisplayName from '../../Utils/displayName';
import en from '../../Translations/en.json';
import fi from '../../Translations/fi.json';

const LocalizationContext = React.createContext({
  currentLanguage: '',
  setLanguage: () => {},
  translate: () => {},
  getLocale: () => {}
});

/**
 * Provides LocalizationContext for wrapped children.
 */
export class LocalizationProvider extends React.Component {
  constructor(props) {
    super(props);

    this.translations = { en, fi };
    this.locales = { enLocale, fiLocale };

    this.getLanguage = () => {
      const storedLanguage = localStorage.getItem('language');
      const translationKeys = Object.keys(this.translations);

      if (storedLanguage && translationKeys.includes(storedLanguage)) {
        return storedLanguage;
      }

      const browserLanguages = window.navigator.languages;
      const supportedBrowserLanguages = intersection(
        browserLanguages,
        translationKeys
      );

      if (supportedBrowserLanguages.length) {
        return supportedBrowserLanguages[0];
      }

      return translationKeys[0];
    };

    this.getLocale = () => {
      const storedLanguage = `${this.getLanguage()}Locale`;
      const localeKeys = Object.keys(this.locales);

      if (localeKeys.includes(storedLanguage)) {
        return this.locales[storedLanguage];
      }

      return this.locales.enLocale;
    };

    this.setLanguage = code => {
      this.setState({
        currentLanguage: code
      });
      moment.locale(code);
      localStorage.setItem('language', code);
    };

    this.translate = key =>
      get(
        this.translations[this.state.currentLanguage],
        key,
        'MISSING TRANSLATION'
      );

    /* eslint-disable react/no-unused-state */
    this.state = {
      currentLanguage: this.getLanguage(),
      languages: Object.keys(this.translations),
      setLanguage: this.setLanguage,
      translate: this.translate,
      getLocale: this.getLocale
    };
    /* eslint-enable react/no-unused-state */

    moment.locale(this.state.currentLanguage);
    localStorage.setItem('language', this.state.currentLanguage);
  }

  render() {
    return (
      <LocalizationContext.Provider value={this.state}>
        {this.props.children}
      </LocalizationContext.Provider>
    );
  }
}

/**
 *Returns given component with localization capabilities as props.
 * @param {*} Component
 */
export const withLocalization = Component => {
  const WithLocalization = props => (
    <LocalizationContext.Consumer>
      {localization => <Component {...props} {...localization} />}
    </LocalizationContext.Consumer>
  );

  WithLocalization.displayName = `withLocalization(${getDisplayName(
    Component
  )})`;

  return WithLocalization;
};
