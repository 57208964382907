import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem
} from 'reactstrap';
import classnames from 'classnames';
import UnitService from '../../../../Services/unitService';
import TenantCultureService from '../../../../Services/tenantCultureService';
import UnitList from './UnitList';
import UnitTranslations from './UnitTranslations';
import { withLocalization } from '../../../Shared/LocalizationProvider';

class UnitContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      defaultCulture: '',
      units: [],
      cultures: [],
      translations: [],
      noTranslations: [],
      selectedUnit: {},
      loading: false
    };

    this.unitService = new UnitService(props.translate);
    this.tenantCultureService = new TenantCultureService(
      sessionStorage.getItem('tid'),
      props.translate
    );
  }

  async componentDidMount() {
    this.getCultures();
    if (sessionStorage.getItem('SelectedUnit') !== null) {
      this.getUnit();
    } else {
      this.getUnits();
    }
  }

  componentWillUnmount() {
    sessionStorage.removeItem('SelectedUnit');
    this.unitService.abortController.abort();
    this.tenantCultureService.abortController.abort();
  }

  getUnit = async () => {
    const result = await this.unitService.getUnit(
      sessionStorage.getItem('SelectedUnit')
    );
    if (result) {
      this.selectUnit(result);
    } else {
      this.getUnits();
    }
  };

  getUnits = async () => {
    this.setState({ units: [], loading: true }, async () => {
      const results = await this.unitService.getUnits();
      this.setState({ units: results.results, loading: false });
    });
  };

  async getCultures() {
    let { defaultCulture, cultures } = this.state;
    const results = await this.tenantCultureService.getAll();
    if (results.body !== null) {
      if (results.body.length > 0) {
        defaultCulture = results.body.find(
          culture => culture.isDefault && culture.isActive
        ).cultureCode;
      } else {
        defaultCulture = 'en';
      }

      cultures = results.body.filter(culture => culture.isActive);
      this.setState({
        cultures,
        defaultCulture: defaultCulture.slice(0, 2)
      });
    }
  }

  getTranslations = unit => {
    const { cultures } = this.state;
    const translations = [];
    cultures.forEach(culture => {
      let translation = { ...culture };
      unit.names.forEach(name => {
        if (name.languageCode === culture.cultureCode.slice(0, 2)) {
          translation = {
            ...culture,
            ...name,
            abbreviation: unit.abbreviations.find(
              abbr => abbr.languageCode === name.languageCode
            ).value,
            abbreviationId: unit.abbreviations.find(
              abbr => abbr.languageCode === name.languageCode
            ).id
          };
          translations.push(translation);
        }
      });
      this.setState({ translations });
    });

    const noTranslations = cultures
      .map(c => c.cultureCode)
      .filter(val => !translations.map(t => t.cultureCode).includes(val));

    this.setState({ translations, noTranslations });
  };

  selectUnit = unit => {
    const { selectedUnit } = this.state;
    const { history } = this.props;
    if (unit.id === selectedUnit.id) {
      this.setState({ selectedUnit: {} });
    } else if (typeof unit === 'object') {
      sessionStorage.setItem('SelectedUnit', unit.id);
      this.getTranslations(unit);
      this.setState({ selectedUnit: unit, activeTab: '2' });
      history.push(`/xtratime/units/${unit.id}/translations`);
    }
  };

  updateDefaultUnit = async (unit, checked) => {
    const editedUnit = { ...unit, isDefault: checked };
    const result = await this.unitService.updateUnit(editedUnit);
    if (result) {
      this.getUnits();
    }
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
      if (tab !== '2') {
        sessionStorage.removeItem('SelectedUnit');
        this.getUnits();
        this.setState({
          selectedUnit: {}
        });
      }
    }
  };

  render() {
    const {
      units,
      cultures,
      translations,
      noTranslations,
      defaultCulture,
      selectedUnit,
      activeTab,
      loading
    } = this.state;
    const { translate } = this.props;

    let translationTab = null;
    let translationContent = null;

    if (Object.keys(selectedUnit).length !== 0) {
      translationTab = (
        <NavItem>
          <NavLink
            id="unit-translation-list-link"
            className={classnames({ active: activeTab === '2' })}
            tag={Link}
            to={`/xtratime/units/${selectedUnit.id}/translations`}
            onClick={() => {
              this.toggleTab('2');
            }}
          >
            {translate('Projects.SelectedUnit')}: {selectedUnit.names[0].value}
          </NavLink>
        </NavItem>
      );
    }

    if (Object.keys(selectedUnit).length !== 0) {
      translationContent = (
        <TabPane tabId="2">
          <Col sm="12">
            <UnitTranslations
              selectedUnit={selectedUnit}
              cultures={cultures}
              translations={translations}
              noTranslations={noTranslations}
              getUnits={this.getUnits}
              getTranslations={this.getTranslations}
              unitService={this.unitService}
              loading={loading}
            />
          </Col>
        </TabPane>
      );
    }
    return (
      <div className="margin-bottom">
        <Label size="lg">{translate('Projects.Units')}</Label>
        <div className="container">
          <Nav tabs>
            <NavItem>
              <NavLink
                id="unit-list-link"
                className={classnames({ active: activeTab === '1' })}
                to="/xtratime/units"
                tag={Link}
                onClick={() => {
                  this.toggleTab('1');
                }}
              >
                {translate('Projects.Units')}
              </NavLink>
            </NavItem>
            {translationTab}
          </Nav>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Col sm="12">
              <UnitList
                units={units}
                loadingUnits={loading}
                defaultCulture={defaultCulture}
                selectUnit={this.selectUnit}
                unitService={this.unitService}
                getUnits={this.getUnits}
                updateDefaultUnit={this.updateDefaultUnit}
                deleteUnit={this.deleteUnit}
              />
            </Col>
          </TabPane>
          {translationContent}
        </TabContent>
      </div>
    );
  }
}

export default withLocalization(UnitContainer);
