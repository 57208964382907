import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { getUser, subscribeToUserChange } from './Services/loginService';
import Footer from './Components/Shared/Footer';
import Content from './Components/Shared/Content';
import TopBar from './Components/Shared/TopBar';
import SideBar from './Components/Shared/SideBar';
import LoginRegister from './Components/Shared/LoginRegister';
import CompanyService from './Services/companyService';
import { LocalizationProvider } from './Components/Shared/LocalizationProvider';
import history from './Services/historyService';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarOpen: false,
      loggedIn: false
    };
  }

  componentDidMount() {
    subscribeToUserChange(u => this.setUser(u));
    const token = sessionStorage.getItem('token');
    if (token != null) {
      const user = getUser();
      this.setUser(user);
    } else {
      this.setState({ loggedIn: false, user: { info: {} } });
    }
  }

  async setUser(user = {}) {
    const companyService = new CompanyService(
      sessionStorage.getItem('tid'),
      this.props.translate
    );
    if (Object.keys(user).length !== 0) {
      const info = await companyService.getBasicInfo(
        sessionStorage.getItem('cid')
      );
      this.setState({
        user: { ...user, info },
        loggedIn: true
      });
    }
  }

  destroyToken() {
    sessionStorage.clear();
    this.setState({ loggedIn: false, user: { info: {} } });
    this.sidebarToggle();
    history.push('/login');
  }

  sidebarToggle(e) {
    const { sideBarOpen } = this.state;
    const sidebar = document.getElementsByClassName('st-container')[0];
    if (sideBarOpen) {
      sidebar.classList.remove('nav-effect');
      this.setState({ sideBarOpen: !sideBarOpen });
    } else if (typeof e === 'undefined');
    else if (e.target.classList.contains('sidebar-toggle') && !sideBarOpen) {
      sidebar.classList.add('nav-effect');
      this.setState({ sideBarOpen: !sideBarOpen });
    }
  }

  render() {
    const { user, loggedIn } = this.state;
    return (
      <Router history={history}>
        <LocalizationProvider>
          <ToastContainer position="bottom-right" />
          <SideBar
            handleLogin={() => this.handleLogin()}
            destroyToken={() => this.destroyToken()}
            user={user}
          />
          <div onClick={e => this.sidebarToggle(e)} className="st-pusher">
            <TopBar
              loggedIn={loggedIn}
              toggleSidebar={e => this.sidebarToggle(e)}
              handleLogin={() => this.handleLogin()}
            />
            <div className="content">
              <Switch>
                <Route
                  path="/login"
                  render={props => (
                    <LoginRegister
                      {...props}
                      loggedIn={loggedIn}
                      setUser={usr => this.setUser(usr)}
                      destroyToken={() => this.destroyToken()}
                    />
                  )}
                />
                <Route
                  path="/register"
                  component={props => <LoginRegister {...props} />}
                />
                <Route
                  path="*"
                  render={props => <Content {...props} user={user} />}
                />
              </Switch>
            </div>
            <Footer />
          </div>
        </LocalizationProvider>
      </Router>
    );
  }
}

export default App;
