import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import { Col, Label, FormGroup } from 'reactstrap';
import { withLocalization } from '../../../Shared/LocalizationProvider';
import DescriptionRtEditor from '../../../Shared/DescriptionRtEditor';
import FormDatePicker from '../../../Shared/FormDatePicker';

class ProjectCreate extends Component {
  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    await this.addProject(values);
  };

  validateCurrency = value => {
    const { translate } = this.props;

    let errorMessage;
    if (!value) errorMessage = translate('Projects.Required');
    return errorMessage;
  };

  async addProject(data) {
    const { history, projectService, getProjects } = this.props;
    const result = await projectService.addProject(data);
    if (result) {
      await getProjects();
      history.push('./');
    }
  }

  render() {
    const {
      translate,
      tenantCurrencies,
      customers,
      toolbarConfig
    } = this.props;

    let tenantCurrencyOptions;
    let customerOptions;

    if (!customers) {
      return [];
    }

    if (tenantCurrencies.length > 0) {
      tenantCurrencyOptions = tenantCurrencies.map(obj => (
        <option key={obj.currencyCode} value={obj.currencyCode}>
          {obj.name}
        </option>
      ));
    } else if (tenantCurrencies.length === 0) {
      tenantCurrencyOptions = (
        <option key="empty" value="">
          {translate('Projects.Empty')}
        </option>
      );
    }

    if (customers.length > 0) {
      customerOptions = customers.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.name1}
        </option>
      ));
    } else if (customers.length === 0) {
      customerOptions = (
        <option key="empty" value="">
          {translate('Projects.Empty')}
        </option>
      );
    }

    return (
      <Formik
        initialValues={{
          currencyCode: '',
          customerId: '',
          name: '',
          projectCode: '',
          startDate: '',
          endDate: '',
          description: '',
          timeInterval: 5
        }}
        validate={values => {
          const errors = {};
          const timeInterval = Number(values.timeInterval);

          if (!values.name) errors.name = translate('Projects.Required');
          if (values.name.length > 256)
            errors.name = translate('Projects.ErrorNameLength256');
          if (values.projectCode.length > 128) {
            errors.projectCode = translate('ErrorProjectCodeLength128');
          }
          if (values.endDate) {
            if (values.startDate > values.endDate) {
              errors.startDate = translate(
                'Projects.StartDateErrorBeforeEndDate'
              );
            }
          }
          if (!Number.isInteger(timeInterval)) {
            errors.timeInterval = translate('Projects.ErrorInteger');
          }
          if (timeInterval < 0) {
            errors.timeInterval = translate('Projects.ErrorBelowZero');
          }

          return errors;
        }}
        onSubmit={this.handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <FormGroup row>
              <Col sm={8}>
                <Field name="name">
                  {({ field }) => (
                    <div>
                      <Label for="project-create-form-name-input">
                        {translate('Projects.Name')}
                      </Label>
                      <input
                        {...field}
                        id="project-create-form-name-input"
                        className="form-control"
                      />
                      {touched[field.name] && errors[field.name] && (
                        <div className="error text-danger">
                          {errors[field.name]}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
              </Col>
              <Col sm={4}>
                <Field name="projectCode">
                  {({ field }) => (
                    <div>
                      <Label for="project-create-form-projectCode-input">
                        {translate('Projects.ProjectCode')}
                      </Label>
                      <input
                        {...field}
                        id="project-create-form-projectCode-input"
                        className="form-control"
                      />
                      {touched[field.name] && errors[field.name] && (
                        <div className="error text-danger">
                          {errors[field.name]}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col sm={6}>
                <Label for="project-create-form-currencyCode-input">
                  {translate('Projects.Currency')}
                </Label>
                <Field
                  id="project-create-form-currencyCode-input"
                  className="form-control"
                  name="currencyCode"
                  validate={this.validateCurrency}
                  component="select"
                >
                  <option key="" value="">
                    {translate('Projects.SelectCurrency')}
                  </option>
                  {tenantCurrencyOptions}
                </Field>
                {errors.currencyCode && touched.currencyCode ? (
                  <div className="errors text-danger">
                    {errors.currencyCode}
                  </div>
                ) : null}
              </Col>
              <Col sm={6}>
                <Label for="project-create-form-customerId-input">
                  {translate('Projects.Customer')}
                </Label>
                <Field
                  id="project-create-form-customerId-input"
                  className="form-control"
                  name="customerId"
                  component="select"
                >
                  <option key="" value="">
                    {translate('Projects.NoCustomer')}
                  </option>
                  {customerOptions}
                </Field>
              </Col>
            </FormGroup>

            <FormGroup row style={{ position: 'relative', zIndex: 99 }}>
              <Col sm={4}>
                <Label for="project-create-form-startDate-input">
                  {translate('Projects.StartDate')}
                </Label>
                <Field
                  name="startDate"
                  id="project-create-form-startDate-input"
                  placeholder={translate('Projects.StartDate')}
                  locale={this.props.getLocale()}
                  component={FormDatePicker}
                />
              </Col>
              <Col sm={4}>
                <Label for="project-create-form-endDate-input">
                  {translate('Projects.EndDate')}
                </Label>
                <Field
                  name="endDate"
                  id="project-create-form-endDate-input"
                  placeholder={translate('Projects.EndDate')}
                  locale={this.props.getLocale()}
                  component={FormDatePicker}
                />
              </Col>
              <Col sm={4}>
                <Field name="timeInterval">
                  {({ field }) => (
                    <div>
                      <Label for="project-create-form-timeInterval-input">
                        {translate('Projects.TimeInterval')}
                      </Label>
                      <input
                        {...field}
                        id="project-create-form-timeInterval-input"
                        className="form-control"
                      />
                      {touched[field.name] && errors[field.name] && (
                        <div className="error text-danger">
                          {errors[field.name]}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
              </Col>
            </FormGroup>

            <Label for="description">{translate('Projects.Description')}</Label>
            <Field
              name="description"
              id="project-create-form-description-input"
              toolbarConfig={toolbarConfig}
              component={DescriptionRtEditor}
            />

            <br />

            <button
              type="submit"
              id="project-create-form-startDate-submit"
              className="btn btn-lg btn-secondary"
            >
              {translate('Actions.Submit')}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withLocalization(ProjectCreate);
