import ApiServiceBase from './apiServiceBase';

// Usage:
// class [Controller]Service extends ApiCrudServiceBase {
//     constructor() { super(`${env.settings.api}/api/[Controller]`) }
// }
//
// [const/this.]service = new [Controller]Service();
// service.get(id)
class ApiCrudServiceBase extends ApiServiceBase {
  getAll(query = null) {
    return this.http.get('/', query);
  }

  getListPage(page, itemsPerPage, orderBy = [], id = '') {
    const query = {
      skip: page * itemsPerPage,
      take: itemsPerPage,
      orderBy,
      id
    };
    return this.http.get('/', query);
  }

  get(id, query = null) {
    return this.http.get(`/${id}/`, query);
  }

  add(data, query = null) {
    return this.http.post('/', data, query);
  }

  update(data, query = null) {
    return this.http.put('/', data, query);
  }

  delete(id, query = null) {
    return this.http.delete(`/${id}/`, query);
  }
}

export default ApiCrudServiceBase;
