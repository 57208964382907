import React, { Component } from 'react';
import RichTextEditor from 'react-rte';

class DescriptionRtEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value:
        RichTextEditor.createValueFromString(this.props.field.value, 'html') ||
        RichTextEditor.createEmptyValue()
    };
  }

  onChange = value => {
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;

    this.setState({ value });

    setFieldValue(name, value.toString('html'));
  };

  render() {
    const { value } = this.state;
    const {
      field,
      toolbarConfig,
      rteClassName,
      form: { touched, errors }
    } = this.props;

    return (
      <div>
        <RichTextEditor
          className="rte"
          toolbarClassName={rteClassName}
          value={value}
          onChange={this.onChange}
          toolbarConfig={toolbarConfig}
        />
        {touched[field.name] && errors[field.name] && (
          <div className="error text-danger">{errors[field.name]}</div>
        )}
      </div>
    );
  }
}

export default DescriptionRtEditor;
