import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import LoginService from '../../Services/loginService';
import { withLocalization } from './LocalizationProvider';
import UserService from '../../Services/userService';

class LoginRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      showModal: false,
      loading: false,
      selectedTenant: { companies: [] },
      selectedCompany: {},
      userTenants: [
        {
          companies: null
        }
      ]
    };
    this.loginService = new LoginService(props.translate);
    this.userService = new UserService(props.translate);
  }

  componentDidMount() {
    if (this.props.location.pathname === '/login') {
      this.setState({ isLogin: true });
    }
    if (this.props.loggedIn) {
      this.props.destroyToken();
    }
  }

  componentWillUnmount() {
    this.loginService.abortController.abort();
    this.userService.abortController.abort();
  }

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  handleCheckbox() {
    if (this.state.terms) this.setState({ terms: false });
    else this.setState({ terms: true });
  }

  handleTenantSelect(e) {
    const { userTenants } = this.state;
    if (e.target.value === 'null') {
      this.setState({
        selectedTenant: { companies: [] },
        selectedCompany: {}
      });
      return;
    }
    const selectedTenant = userTenants.find(t => t.id === e.target.value);
    this.setState({ selectedTenant, selectedCompany: {} });
  }

  handleCompanySelect(e) {
    const { selectedTenant } = this.state;
    if (e.target.value === 'null') {
      this.setState({
        selectedCompany: {}
      });
      return;
    }
    const selectedCompany = selectedTenant.companies.find(
      t => t.id === e.target.value
    );
    this.setState({ selectedCompany });
  }

  async handleRegister() {
    const { registerFirstName, registerLastName, registerEmail } = this.state;
    this.setState({ loading: true });
    const registerSuccess = await this.userService.register({
      firstName: registerFirstName,
      lastName: registerLastName,
      email: registerEmail
    });
    if (registerSuccess) {
      this.setState({ loading: false });
      this.props.history.push('/');
    } else {
      this.setState({ loading: false });
    }
  }

  async handleLogin() {
    const { loginEmail, loginPassword } = this.state;
    this.setState({ loading: true });
    const user = await this.loginService.login(loginEmail, loginPassword);
    if (user.isMultiTenant) {
      const tenantResponse = await this.loginService.getTenants();
      this.setState({
        userTenants: tenantResponse.body,
        showModal: true,
        loading: false
      });
    } else if (Object.keys(user).length !== 0) {
      await this.props.setUser(user);
      this.setState({ loading: false });
      this.props.history.push('/');
    } else {
      this.setState({ loading: false });
    }
  }

  async loginFromModal() {
    const { selectedCompany, selectedTenant } = this.state;
    let user = {};
    this.setState({ loading: true });
    if (Object.keys(selectedTenant).length !== 0) {
      if (Object.keys(selectedCompany).length !== 0) {
        user = await this.loginService.changeCompany(selectedCompany.id);
      }
      this.props.setUser(user);
      this.setState({ loading: false });
      this.props.history.push('/');
    } else {
      this.setState({ loading: false });
    }
  }

  changeForm(e) {
    e.preventDefault();
    const { isLogin } = this.state;
    let x = 1;
    let y = 0;
    if (!this.state.isLogin) {
      x = 0;
      y = 1;
    }
    const wrappers = document.getElementsByClassName('wrapper');
    wrappers[x].classList.value = 'wrapper front clearfix animated flipOutY';
    setTimeout(() => {
      wrappers[y].classList.value = 'wrapper front clearfix animated flipInY';
    }, 500);
    setTimeout(() => {
      if (!isLogin) this.props.history.push('/login');
      else this.props.history.push('/register');
      this.setState({
        isLogin: !isLogin
      });
    }, 1000);
  }

  render() {
    const { isLogin, userTenants, selectedTenant, loading } = this.state;
    const { translate, destroyToken } = this.props;
    let companySelect = null;
    if (selectedTenant.companies.length !== 0) {
      companySelect = (
        <div>
          <Input
            type="select"
            name="companySelect"
            id="companySelect"
            onChange={e => this.handleCompanySelect(e)}
          >
            <option id="select-company-null" value="null">
              {translate('RegisterLogin.SelectCompany')}
            </option>
            {selectedTenant.companies.map(company => (
              <option
                id={`select-company-${company.id}`}
                key={company.id}
                value={company.id}
              >
                {`${company.name1}`}
              </option>
            ))}
          </Input>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="signup-rotate">
          <div className="container">
            <div className="header">
              <NavLink to="/" className="logo">
                XtraSuite
              </NavLink>
            </div>
            <div className="rotate-container">
              <div className="wrappers">
                <div
                  className={
                    !isLogin ? 'wrapper front clearfix' : 'wrapper invisible'
                  }
                >
                  <div className="formy">
                    <form onSubmit={e => e.preventDefault()}>
                      <div className="row">
                        <div className="col-md-12">
                          <h4>{translate('Actions.Register')}</h4>
                          <FormGroup className="form-group">
                            <Label htmlFor="registerFirstName">
                              {translate('RegisterLogin.FirstName')}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="registerFirstName"
                              onChange={e => this.handleChange(e)}
                            />
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Label htmlFor="registerLastName">
                              {translate('RegisterLogin.LastName')}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="registerLastName"
                              onChange={e => this.handleChange(e)}
                            />
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Label htmlFor="registerEmail">
                              {translate('RegisterLogin.Email')}
                            </Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="registerEmail"
                              onChange={e => this.handleChange(e)}
                            />
                          </FormGroup>
                          <div className="checkbox ml-4">
                            <Label htmlFor="terms" check>
                              <Input
                                id="registerTerms"
                                type="checkbox"
                                name="registerTerms"
                                onChange={() => this.handleCheckbox()}
                              />{' '}
                              {translate('RegisterLogin.ReadAndUnderstood')}{' '}
                              <a if="termsandconditions-link" href="#top">
                                {translate('RegisterLogin.TermsAndConditions')}
                              </a>
                              .
                            </Label>
                          </div>
                          <div className="text-center mt-4">
                            <Button
                              id="register-button"
                              type="submit"
                              className="submit btn-block btn-shadow btn-netmaa"
                              disabled={!this.state.terms || loading}
                              onClick={() => this.handleRegister()}
                            >
                              {!loading ? (
                                translate('Actions.CreateAccount')
                              ) : (
                                <Spinner color="light" size="sm" />
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div
                  className={
                    isLogin ? 'wrapper front clearfix' : 'wrapper invisible'
                  }
                  style={{ marginTop: '6.66em' }}
                >
                  <div className="formy">
                    <form onSubmit={e => e.preventDefault()}>
                      <div className="row">
                        <div className="col-md-12">
                          <h4>{translate('Actions.Login')}</h4>
                          <FormGroup className="form-group">
                            <Label htmlFor="loginEmail">
                              {translate('RegisterLogin.Email')}
                            </Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="loginEmail"
                              onChange={e => this.handleChange(e)}
                            />
                          </FormGroup>
                          <FormGroup className="form-group">
                            <Label htmlFor="loginPassword">
                              {translate('RegisterLogin.Password')}
                            </Label>
                            <Input
                              type="password"
                              className="form-control"
                              id="loginPassword"
                              onChange={e => this.handleChange(e)}
                            />
                          </FormGroup>
                          <div className="text-center mt-4">
                            <Button
                              id="login-button"
                              type="submit"
                              className="submit btn-block btn-shadow btn-netmaa"
                              onClick={() => this.handleLogin()}
                              disabled={loading}
                            >
                              {!loading ? (
                                translate('Actions.Login')
                              ) : (
                                <Spinner color="light" size="sm" />
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="change-view active">
                {!this.state.isLogin ? (
                  <React.Fragment>
                    {translate('RegisterLogin.AlreadyHaveAccount')}?{' '}
                    <NavLink
                      id="signin-link"
                      to="/login"
                      onClick={e => this.changeForm(e)}
                    >
                      {translate('RegisterLogin.SignIn')}
                    </NavLink>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {translate('RegisterLogin.NoAccount')}?{' '}
                    <NavLink
                      if="register-link"
                      to="/register"
                      onClick={e => this.changeForm(e)}
                    >
                      {translate('RegisterLogin.CreateAccount')}
                    </NavLink>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.showModal} backdrop="static">
          <ModalHeader>{translate('RegisterLogin.SelectCompany')}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="tenantSelect">
                  {translate('RegisterLogin.SelectTenant')}
                </Label>
                <Input
                  type="select"
                  name="tenantSelect"
                  id="tenantSelect"
                  onChange={e => this.handleTenantSelect(e)}
                >
                  <option id="select-tenant-null" value="null">
                    {translate('RegisterLogin.SelectTenant')}
                  </option>
                  {userTenants.length > 1
                    ? userTenants.map(tenant => (
                        <option
                          if={`select-tenant-${tenant.id}`}
                          key={tenant.id}
                          value={tenant.id}
                        >
                          {`${tenant.name}`}
                        </option>
                      ))
                    : ''}
                </Input>
              </FormGroup>
              {companySelect}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              id="loginmodal-dismiss-button"
              color="warning"
              onClick={() => {
                this.setState({ showModal: false });
                destroyToken();
              }}
              disabled={loading}
            >
              {!loading ? (
                translate('Actions.Cancel')
              ) : (
                <Spinner color="light" size="sm" />
              )}
            </Button>
            <Button
              id="loginmodal-enter-button"
              className="btn-netmaa"
              onClick={() => this.loginFromModal()}
              disabled={typeof this.state.selectedCompany.id === 'undefined'}
            >
              {!loading ? (
                translate('Actions.Confirm')
              ) : (
                <Spinner color="light" size="sm" />
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withLocalization(LoginRegister);
