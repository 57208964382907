import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Label,
  Table,
  Button,
  FormGroup,
  Col,
  Input,
  Spinner
} from 'reactstrap';
import { withLocalization } from '../../../Shared/LocalizationProvider';

class UnitList extends Component {
  handleKeyPress = (event, data) => {
    if (event.key === 'Enter') {
      this.editUnit(event, data);
    }
  };

  createUnit = async (values, { setSubmitting, resetForm }) => {
    const { unitService, getUnits, defaultCulture } = this.props;

    setSubmitting(false);

    const newData = {
      isDefault: values.isDefault,
      metricsType: values.metricsType,
      names: [
        {
          isDefault: true,
          value: values.translation,
          languageCode: defaultCulture
        }
      ],
      abbreviations: [
        {
          isDefault: true,
          value: values.abbreviation,
          languageCode: defaultCulture
        }
      ]
    };

    const result = await unitService.addUnit(newData);
    if (result) {
      await getUnits();
      resetForm();
    }
  };

  editUnit = async (event, unit) => {
    const { unitService, getUnits } = this.props;

    this.name = event.target.name;
    this.value = event.target.value;

    let result = null;

    const index = unit.names.findIndex(name => name.isDefault === true);

    if (this.name === 'name') {
      if (unit.names[index].value !== this.value) {
        if (this.value.length > 0) {
          const updatedUnit = { ...unit };
          updatedUnit.names[index].value = this.value;
          result = await unitService.updateUnit(updatedUnit);
        }
      }
    } else if (this.name === 'abbr') {
      if (unit.abbreviations[index].value !== this.value) {
        if (this.value.length > 0) {
          const updatedUnit = { ...unit };
          updatedUnit.abbreviations[index].value = this.value;
          result = await unitService.updateUnit(updatedUnit);
        }
      }
    } else if (this.name === 'metricsType') {
      if (this.value >= 0) {
        const newValue = !(typeof this.value === 'number')
          ? Number(this.value)
          : this.value;

        if (unit.metricsType !== newValue && !Number.isNaN(newValue)) {
          const updatedUnit = { ...unit, metricsType: newValue };
          result = await unitService.updateUnit(updatedUnit);
        }
      }
    }
    if (result) {
      await getUnits();
    }
  };

  async deleteUnit(row) {
    const { unitService, getUnits } = this.props;
    await unitService.deleteUnit(row.id);
    await getUnits();
  }

  render() {
    const {
      translate,
      units,
      selectUnit,
      updateDefaultUnit,
      defaultCulture,
      loadingUnits
    } = this.props;

    let unitTable;

    const metricsTypes = [
      translate('Projects.Other'),
      translate('Projects.Area'),
      translate('Projects.Length'),
      translate('Projects.Weight'),
      translate('Projects.Volume')
    ];
    const metricOptions = metricsTypes.map((value, i) => (
      <option key={value[i]} value={i}>
        {value}
      </option>
    ));

    if (units.length === 0 && loadingUnits === false) {
      unitTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (units.length > 0 && loadingUnits === false) {
      unitTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.Abbreviation')}</th>
              <th>{translate('Projects.Name')}</th>
              <th>{translate('Projects.MetricType')}</th>
              <th>{translate('Projects.Default')}</th>
              <th className="text-right">{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {units.map(row => (
              <tr key={row.id}>
                <td>
                  <Input
                    id={`unit-list-abbr-input-${row.id}`}
                    name="abbr"
                    defaultValue={
                      row.abbreviations.find(abbr => abbr.isDefault).value
                    }
                    required
                    onBlur={e => this.editUnit(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <Input
                    id={`unit-list-name-input-${row.id}`}
                    name="name"
                    defaultValue={row.names.find(name => name.isDefault).value}
                    required
                    onBlur={e => this.editUnit(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <Input
                    id={`unit-list-metricType-input-${row.id}`}
                    type="select"
                    name="metricsType"
                    defaultValue={row.metricsType}
                    onChange={e => this.editUnit(e, row)}
                  >
                    {metricOptions}
                  </Input>
                </td>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      id={`unit-list-isDefault-input-${row.id}`}
                      type="radio"
                      name="isDefault"
                      defaultChecked={row.isDefault}
                      onChange={() => {
                        const isDefault = !row.isDefault;
                        updateDefaultUnit(row, isDefault);
                      }}
                    />
                  </div>
                </td>
                <td className="text-right">
                  <Button
                    id={`unit-list-button-delete-${row.id}`}
                    className="btn btn-danger"
                    onClick={() => this.deleteUnit(row)}
                  >
                    {translate('Actions.Delete')}
                  </Button>
                  <Button
                    className="btn btn-success"
                    onClick={() => selectUnit(row)}
                  >
                    {translate('Projects.Translations')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else if (loadingUnits === true) {
      unitTable = <Spinner type="grow" color="success" size="lg" />;
    }

    return (
      <div className="margin-bottom">
        <Label size="lg">{translate('Projects.Units')}</Label>
        {unitTable}
        <Formik
          initialValues={{
            isDefault: false,
            metricsType: '',
            abbreviation: '',
            translation: ''
          }}
          validate={values => {
            const errors = {};

            if (!values.translation) {
              errors.translation = translate('Projects.Required');
            }
            if (!values.abbreviation) {
              errors.abbreviation = translate('Projects.Required');
            }
            if (!values.metricsType) {
              errors.metricsType = translate('Projects.Required');
            }

            return errors;
          }}
          enableReinitialize
          onSubmit={this.createUnit}
        >
          {({ values, errors, touched }) => (
            <Form>
              <Label size="lg">{translate('Projects.CreateUnit')}</Label>
              <FormGroup row>
                <Col sm={1}>
                  <Label>{translate('Projects.Language')}</Label>
                  <Label>{defaultCulture}</Label>
                </Col>
                <Col sm={1}>
                  <Field name="isDefault" id="unit-create-form-default-input">
                    {({ field }) => (
                      <div>
                        <Label>{translate('Projects.Default')}</Label>
                        <input
                          {...field}
                          checked={values.isDefault}
                          type="checkbox"
                          id="unit-create-form-isDefault-input"
                        />
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={3}>
                  <Label>{translate('Projects.MetricType')}</Label>
                  <Field
                    id="unit-create-form-metricType-input"
                    className="form-control"
                    name="metricsType"
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.SelectMetricType')}
                    </option>
                    {metricOptions}
                  </Field>
                  {touched.metricsType && errors.metricsType && (
                    <div className="error text-danger">
                      {errors.metricsType}
                    </div>
                  )}
                </Col>
                <Col sm={3}>
                  <Label>{translate('Projects.Abbreviation')}</Label>
                  <Field
                    id="unit-create-form-abbreviation-input"
                    className="form-control"
                    name="abbreviation"
                  />
                  {errors.abbreviation && touched.abbreviation ? (
                    <div className="errors text-danger">
                      {errors.abbreviation}
                    </div>
                  ) : null}
                </Col>
                <Col sm={4}>
                  <Label>{translate('Projects.Name')}</Label>
                  <Field
                    id="unit-create-form-translation-input"
                    className="form-control"
                    name="translation"
                  />
                  {errors.translation && touched.translation ? (
                    <div className="errors text-danger">
                      {errors.translation}
                    </div>
                  ) : null}
                </Col>
              </FormGroup>
              <button
                id="unit-create-form-submit"
                type="submit"
                className="btn btn-secondary"
              >
                {translate('Projects.AddUnit')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withLocalization(UnitList);
