import React, { Component } from 'react';
import { Table, Input, Button } from 'reactstrap';
import { withLocalization } from '../../../Shared/LocalizationProvider';
import ProjectTeamMemberService from '../../../../Services/projectTeamMemberService';
import EmployeeService from '../../../../Services/employeeService';
import ProjectDefaultRoleService from '../../../../Services/projectDefaultRoleService';
import CreateMemberModal from './CreateMemberModal';

class TeamDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      employees: [],
      roles: [],
      selectedMember: {},
      modalOpen: false,
      loading: false
    };

    this.roleService = new ProjectDefaultRoleService(props.translate);
    this.memberService = new ProjectTeamMemberService(
      this.props.selectedProject.id,
      this.props.selectedTeam.id,
      this.props.translate
    );
    this.employeeService = new EmployeeService(this.props.translate);
  }

  componentDidMount() {
    this.getMembers();
    this.getEmployees();
    this.getRoles();
  }

  componentWillUnmount() {
    this.roleService.abortController.abort();
    this.memberService.abortController.abort();
    this.employeeService.abortController.abort();
  }

  getEmployees = async () => {
    const results = await this.employeeService.getEmployees();
    if (results) {
      this.setState({ employees: results.results });
    }
  };

  getRoles = async () => {
    const results = await this.roleService.getRoles();
    this.setState({ roles: results.results });
  };

  addRole = async values => {
    const result = await this.roleService.addRole(values);
    if (result) {
      this.getRoles();
    }
  };

  handleKeyPress = (event, data) => {
    if (event.key === 'Enter') {
      this.updateMember(event, data);
    }
  };

  getMembers = async () => {
    this.setState({ loading: true }, async () => {
      const results = await this.memberService.getMembers();
      this.setState({
        members: results,
        loading: false
      });
    });
  };

  updateMember = async (event, member) => {
    this.name = event.target.name;
    this.value = event.target.value;

    if (this.name === 'role') {
      if (member.role !== this.value) {
        const updatedMember = { ...member, role: this.value };
        const result = await this.memberService.updateMember(updatedMember);
        if (result) {
          await this.getMembers();
        }
      }
    } else if (this.name === 'unitPrice') {
      if (this.value >= 0) {
        const newValue = !(typeof this.value === 'number')
          ? parseFloat(this.value)
          : this.value;

        if (member.unitPrice !== newValue && !Number.isNaN(newValue)) {
          const updatedMember = { ...member, unitPrice: newValue };
          const result = await this.memberService.updateMember(updatedMember);
          if (result) {
            await this.getMembers();
          }
        }
      }
    }
  };

  updateBillable = async (member, checked) => {
    const newMember = { ...member, isBillable: checked };
    const result = await this.memberService.updateMember(newMember);
    if (result) {
      await this.getMembers();
    }
  };

  toggleModal = async () => {
    await this.setState(prevState => ({
      modalOpen: !prevState.modalOpen
    }));
  };

  async deleteMember(id) {
    await this.memberService.deleteMember(id);
    await this.getMembers();
  }

  render() {
    const {
      members,
      employees,
      modalOpen,
      selectedMember,
      roles,
      loading
    } = this.state;
    const { translate, selectedProject, selectedTeam } = this.props;

    let memberTable;

    if (members.length === 0 && loading === false) {
      memberTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (members.length > 0 && loading === false) {
      memberTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.Members')}</th>
              <th>{translate('Projects.Role')}</th>
              <th>
                {translate('Projects.UnitPrice')} (
                {selectedProject.currencyCode})
              </th>
              <th>{translate('Projects.Billable')}</th>
              <th>{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {members.map(row => (
              <tr key={row.id}>
                <td>{row.name}</td>
                <td>
                  <div className="form-group has-success">
                    <Input
                      className="form-control"
                      id={`project-team-member-list-role-input-${row.id}`}
                      name="role"
                      defaultValue={row.role}
                      required
                      onBlur={e => this.updateMember(e, row)}
                      onKeyDown={e => this.handleKeyPress(e, row)}
                    />
                  </div>
                </td>
                <td>
                  <Input
                    id={`project-team-member-list-unitPrice-input-${row.id}`}
                    type="number"
                    min="0"
                    step="any"
                    name="unitPrice"
                    defaultValue={row.unitPrice}
                    onBlur={e => this.updateMember(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <div>
                    <Input
                      id={`project-team-member-list-isBillable-input-${row.id}`}
                      type="checkbox"
                      name="isBillable"
                      defaultChecked={row.isBillable}
                      onChange={() => {
                        const isBillable = !row.isBillable;
                        this.updateBillable(row, isBillable);
                      }}
                    />
                  </div>
                </td>
                <td>
                  <Button
                    id={`project-team-member-delete-button-${row.id}`}
                    className="btn btn-danger"
                    onClick={() => this.deleteMember(row.id)}
                  >
                    {translate('Actions.Delete')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else if (loading === true) {
      memberTable = (
        <div>
          <p>{translate('Projects.Loading')}</p>
        </div>
      );
    }

    return (
      <div>
        <hr />
        <CreateMemberModal
          modalOpen={modalOpen}
          toggleModal={this.toggleModal}
          employees={employees}
          members={members}
          roles={roles}
          memberService={this.memberService}
          getMembers={this.getMembers}
          addRole={this.addRole}
          selectedTeam={selectedTeam}
          selectedProject={selectedProject}
          selectedMember={selectedMember}
        />
        {memberTable}
        <Button
          id="project-team-member-add-button"
          className="btn btn-lg btn-secondary"
          onClick={() => this.toggleModal()}
        >
          {translate('Projects.AddMember')}
        </Button>
      </div>
    );
  }
}

export default withLocalization(TeamDetails);
