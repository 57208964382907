import React from 'react';
import { NavLink } from 'react-router-dom';
import { withLocalization } from './LocalizationProvider';

function NotFound(props) {
  return (
    <div id="not-found" className="not-found-page animated fadeIn">
      <div className="info" style={{ height: '75vh' }}>
        <h1>404</h1>
        <p>{props.translate('NotFound.DoesNotExist')}.</p>

        <p className="go-back">
          {props.translate('NotFound.Continue')}{' '}
          <NavLink id="notfound-home-link" to="/">
            {props.translate('NotFound.Homepage')}
          </NavLink>
          .
        </p>
      </div>
    </div>
  );
}

export default withLocalization(NotFound);
