import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Label,
  Table,
  Button,
  Input,
  FormGroup,
  Col,
  Spinner
} from 'reactstrap';
import EmployeeService from '../../../../Services/employeeService';
import UnitService from '../../../../Services/unitService';
import { withLocalization } from '../../../Shared/LocalizationProvider';

class ExpenseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      units: [],
      selectedCategory: {}
    };

    this.employeeService = new EmployeeService(props.translate);
    this.unitService = new UnitService(props.translate);
  }

  componentDidMount() {
    this.getEmployees();
    this.getUnits();
  }

  componentWillUnmount() {
    this.employeeService.abortController.abort();
    this.unitService.abortController.abort();
  }

  getEmployees = async () => {
    const results = await this.employeeService.getEmployees();
    if (results) {
      this.setState({ employees: results.results });
    }
  };

  async getUnits() {
    const results = await this.unitService.getUnits();
    this.setState({ units: results.results });
  }

  selectCategory = async id => {
    const result = await this.props.selectCategory(id);

    this.setState({ selectedCategory: result });
  };

  handleKeyPress = (event, data) => {
    if (event.key === 'Enter') {
      this.updateExpense(event, data);
    }
  };

  addExpense = async (values, { setSubmitting, resetForm }) => {
    const { expenseService, getExpenses } = this.props;
    setSubmitting(false);

    const result = await expenseService.addExpense(values);
    if (result) {
      this.setState({ selectedCategory: {} });
      getExpenses();
      resetForm();
    }
  };

  updateBillable = async (expense, checked) => {
    const { expenseService, getExpenses } = this.props;
    const newExpense = { ...expense, isBillable: checked };
    const result = await expenseService.updateExpense(newExpense);
    if (result) {
      await getExpenses();
    }
  };

  updateExpense = async (event, expense) => {
    const { expenseService, getExpenses } = this.props;
    this.name = event.target.name;
    this.value = event.target.value;

    if (this.name === 'unitPrice') {
      if (this.value >= 0) {
        const newValue = !(typeof this.value === 'number')
          ? parseFloat(this.value)
          : this.value;

        if (expense.unitPrice !== newValue && !Number.isNaN(newValue)) {
          const updatedExpense = { ...expense, unitPrice: newValue };
          const result = await expenseService.updateExpense(updatedExpense);
          if (result) {
            await getExpenses();
          }
        }
      }
    } else if (this.name === 'projectExpenseCategoryId') {
      const updatedExpense = {
        ...expense,
        projectExpenseCategoryId: this.value
      };
      const result = await expenseService.updateExpense(updatedExpense);
      if (result) {
        await getExpenses();
      }
    } else if (this.name === 'employeeId') {
      const updatedExpense = {
        ...expense,
        employeeId: this.value
      };
      const result = await expenseService.updateExpense(updatedExpense);
      if (result) {
        await getExpenses();
      }
    } else if (this.name === 'unitId') {
      const updatedExpense = {
        ...expense,
        unitId: this.value
      };
      const result = await expenseService.updateExpense(updatedExpense);
      if (result) {
        await getExpenses();
      }
    } else if (this.name === 'amount') {
      if (this.value >= 0) {
        const newValue = !(typeof this.value === 'number')
          ? parseFloat(this.value)
          : this.value;

        if (expense.amount !== newValue && !Number.isNaN(newValue)) {
          const updatedExpense = { ...expense, amount: newValue };
          const result = await expenseService.updateExpense(updatedExpense);
          if (result) {
            await getExpenses();
          }
        }
      }
    }
  };

  async deleteExpense(id) {
    const { expenseService, getExpenses } = this.props;
    await expenseService.deleteExpense(id);
    getExpenses();
  }

  render() {
    const { employees, units, selectedCategory } = this.state;
    const {
      translate,
      expenses,
      categories,
      selectedProject,
      loadingExpenses
    } = this.props;

    let expenseTable;
    let categoryOptions = [];
    let categoryOptionsWithNoUnit = [];
    let employeeOptions = [];
    let unitOptions = [];
    let sum = 0.0;

    const filteredCategories = categories.filter(
      c => c.currencyCode === selectedProject.currencyCode
    );

    if (
      (filteredCategories === null ||
        typeof filteredCategories === 'undefined') &&
      (employees === null || typeof employees === 'undefined') &&
      (units === null || typeof units === 'undefined')
    ) {
      return (
        <div>
          <p>{translate('Projects.Empty')}</p>
        </div>
      );
    }

    if (filteredCategories && employees && units) {
      categoryOptions = filteredCategories.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.name} [{obj.unitName}]
        </option>
      ));

      categoryOptionsWithNoUnit = filteredCategories.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.name}
        </option>
      ));

      employeeOptions = employees.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.firstName} {obj.lastName}
        </option>
      ));

      employeeOptions.unshift(
        <option key="" value="">
          {translate('Projects.NoEmployee')}
        </option>
      );

      unitOptions = units.map(obj => (
        <option key={obj.id} value={obj.id}>
          {obj.abbreviations[0].value} {'-'} {obj.names[0].value}
        </option>
      ));

      expenses
        .filter(expense => expense.isBillable === true)
        .forEach(expense => {
          sum += expense.amount * expense.unitPrice;
        });
    }

    if (expenses.length === 0 && loadingExpenses === false) {
      expenseTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (expenses.length > 0 && loadingExpenses === false) {
      expenseTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th className="col-2">{translate('Projects.Category')}</th>
              <th className="col-3">{translate('Projects.Employee')}</th>
              <th className="col-1">{translate('Projects.Amount')}</th>
              <th className="col-2">{translate('Projects.Unit')}</th>
              <th className="col-1">{translate('Projects.UnitPrice')}</th>
              <th className="col-1">{translate('Projects.Total')}</th>
              <th className="col-1">{translate('Projects.Billable')}</th>
              <th className="col-1 text-right">
                {translate('Actions.Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {expenses.map(row => (
              <tr key={row.id}>
                <td>
                  <Input
                    type="select"
                    name="projectExpenseCategoryId"
                    value={row.projectExpenseCategoryId}
                    onChange={e => this.updateExpense(e, row)}
                  >
                    {categoryOptionsWithNoUnit}
                  </Input>
                </td>
                <td>
                  <Input
                    type="select"
                    name="employeeId"
                    value={row.employeeId || ''}
                    onChange={e => this.updateExpense(e, row)}
                  >
                    {employeeOptions}
                  </Input>
                </td>
                <td>
                  <Input
                    id={`project-expense-list-amount-input-${row.id}`}
                    type="number"
                    min="0"
                    step="any"
                    name="amount"
                    defaultValue={row.amount}
                    onBlur={e => this.updateExpense(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>
                  <Input
                    type="select"
                    name="unitId"
                    value={row.unitId}
                    onChange={e => this.updateExpense(e, row)}
                  >
                    {unitOptions}
                  </Input>
                </td>
                <td>
                  <Input
                    id={`project-expense-list-unitPrice-input-${row.id}`}
                    type="number"
                    min="0"
                    step="any"
                    name="unitPrice"
                    defaultValue={row.unitPrice}
                    onBlur={e => this.updateExpense(e, row)}
                    onKeyDown={e => this.handleKeyPress(e, row)}
                  />
                </td>
                <td>{(row.amount * row.unitPrice).toFixed(2)}</td>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      id={`project-expense-list-isBillable-input-${row.id}`}
                      type="checkbox"
                      name="isBillable"
                      defaultChecked={row.isBillable}
                      onChange={() => {
                        const isBillable = !row.isBillable;
                        this.updateBillable(row, isBillable);
                      }}
                    />
                  </div>
                </td>
                <td className="text-right">
                  <Button
                    id="project-expense-list-button-delete"
                    className="btn btn-danger"
                    onClick={() => this.deleteExpense(row.id)}
                  >
                    {translate('Actions.Delete')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else if (loadingExpenses === true) {
      expenseTable = <Spinner type="grow" color="success" size="lg" />;
    }

    return (
      <div>
        {expenseTable}
        <div>
          {translate('Projects.TotalBillableExpenses')}: {sum.toFixed(2)}{' '}
          {selectedProject.currencyCode}
        </div>
        <Label size="lg">{translate('Projects.CreateExpense')}</Label>
        <Formik
          initialValues={{
            projectExpenseCategoryId: selectedCategory.id || '',
            employeeId: '',
            unitPrice: selectedCategory.unitPrice || 0.0,
            amount: 1.0,
            unitId: selectedCategory.unitId || '',
            isBillable: false
          }}
          validate={values => {
            const errors = {};
            const unitPrice = Number(values.unitPrice);
            const amount = Number(values.amount);

            if (!values.projectExpenseCategoryId)
              errors.projectExpenseCategoryId = translate('Projects.Required');
            if (values.unitPrice < 0) {
              errors.unitPrice = translate('Projects.ErrorNegativeNumber');
            }
            if (values.amount < 0) {
              errors.amount = translate('Projects.ErrorNegativeNumber');
            }
            if (Number.isNaN(unitPrice)) {
              errors.unitPrice = translate('Projects.ErrorMustBeNumber');
            }
            if (Number.isNaN(amount)) {
              errors.amount = translate('Projects.ErrorMustBeNumber');
            }

            return errors;
          }}
          enableReinitialize
          onSubmit={this.addExpense}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <FormGroup row>
                <Col sm={4}>
                  <Label for="project-expense-form-currencyCode-input">
                    {translate('Projects.ExpenseCategory')}
                  </Label>
                  <Field
                    id="project-expense-form-currencyCode-input"
                    className="form-control"
                    name="projectExpenseCategoryId"
                    onChange={e => {
                      this.selectCategory(e.target.value);
                      setFieldValue('projectExpenseCategoryId', e.target.value);
                    }}
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.SelectCategory')}
                    </option>
                    {categoryOptions}
                  </Field>
                  {errors.currencyCode && touched.currencyCode ? (
                    <div className="errors text-danger">
                      {errors.currencyCode}
                    </div>
                  ) : null}
                </Col>
                <Col sm={4}>
                  <Label for="project-expense-create-form-employee-input">
                    {translate('Projects.Employee')}
                  </Label>
                  <Field
                    id="project-expense-create-form-employee-input"
                    className="form-control"
                    name="employeeId"
                    validate={this.validateMember}
                    component="select"
                  >
                    {employeeOptions.length !== 1 ? (
                      employeeOptions
                    ) : (
                      <option key="" value="">
                        {translate('Projects.NoEmployees')}
                      </option>
                    )}
                  </Field>
                  {errors.employeeId && touched.employeeId ? (
                    <div className="errors text-danger">
                      {errors.employeeId}
                    </div>
                  ) : null}
                </Col>
                <Col sm={4}>
                  <Label for="project-expense-form-currencyCode-input">
                    {translate('Projects.Unit')}
                  </Label>
                  <Field
                    id="project-expense-form-currencyCode-input"
                    className="form-control"
                    name="unitId"
                    component="select"
                  >
                    <option key="" value="">
                      {translate('Projects.SelectUnit')}
                    </option>
                    {unitOptions}
                  </Field>
                  {errors.currencyCode && touched.currencyCode ? (
                    <div className="errors text-danger">
                      {errors.currencyCode}
                    </div>
                  ) : null}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={2}>
                  <Field name="unitPrice">
                    {({ field }) => (
                      <div>
                        <Label>{translate('Projects.UnitPrice')}</Label>
                        <input {...field} className="form-control" />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={2}>
                  <Field name="amount">
                    {({ field }) => (
                      <div>
                        <Label>{translate('Projects.Amount')}</Label>
                        <input {...field} className="form-control" />
                        {touched[field.name] && errors[field.name] && (
                          <div className="error text-danger">
                            {errors[field.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </Field>
                </Col>
                <Col sm={2}>
                  <Field name="isBillable">
                    {({ field }) => (
                      <div>
                        <Label for="project-expense-create-form-isBillable-input">
                          {translate('Projects.Billable')}?
                        </Label>
                        <input
                          {...field}
                          checked={values.isBillable}
                          type="checkbox"
                          id="project-expense-create-form-isBillable-input"
                        />
                      </div>
                    )}
                  </Field>
                </Col>
              </FormGroup>
              <button
                type="submit"
                id="project-expense-form-submitButton"
                className="btn btn-secondary"
              >
                {translate('Projects.AddExpense')}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withLocalization(ExpenseList);
