import React from 'react';
import { withLocalization } from './LocalizationProvider';

function Footer(props) {
  return (
    <footer id="cover-image" className="footer animated fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="text-white" htmlFor="newsLetter">
                <h5>{props.translate('Footer.Newsletter')}</h5>
                <input
                  type="email"
                  className="form-control"
                  id="newsLetterEmail"
                  placeholder={props.translate('Footer.Email')}
                />
              </label>
            </div>
          </div>
        </div>
        <p className="text-white text-center">
          &copy; 2019 - {props.translate('Footer.Netmaa')}
        </p>
      </div>
    </footer>
  );
}

export default withLocalization(Footer);
