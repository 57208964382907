import React, { Component } from 'react';
import { Switch, NavLink, Route } from 'react-router-dom';
import ProjectService from '../../../../Services/projectService';
import CustomerService from '../../../../Services/customerService';
import TenantCurrencyService from '../../../../Services/tenantCurrencyService';
import { withLocalization } from '../../../Shared/LocalizationProvider';
import ProjectList from './ProjectList';
import ProjectCreate from './ProjectCreate';
import ProjectDetails from './ProjectDetails';

class ProjectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProject: {},
      projects: [],
      customers: [],
      tenantCurrencies: [],
      loading: false,
      toolbarConfig: {
        display: [
          'INLINE_STYLE_BUTTONS',
          'BLOCK_TYPE_BUTTONS',
          'LINK_BUTTONS',
          'BLOCK_TYPE_DROPDOWN',
          'HISTORY_BUTTONS'
        ],
        INLINE_STYLE_BUTTONS: [
          { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
          { label: 'Italic', style: 'ITALIC' },
          { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
          { label: 'Normal', style: 'unstyled' },
          { label: 'Heading Large', style: 'header-one' },
          { label: 'Heading Medium', style: 'header-two' },
          { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: 'UL', style: 'unordered-list-item' },
          { label: 'OL', style: 'ordered-list-item' }
        ]
      }
    };

    this.projectService = new ProjectService(props.translate);
    this.customerService = new CustomerService(props.translate);
    this.tenantCurrencyService = new TenantCurrencyService(
      sessionStorage.tid,
      props.translate
    );
  }

  componentDidMount() {
    this.getCustomers();
    this.getDefaultTenantCurrency();
    if (sessionStorage.getItem('SelectedProject') !== null) {
      this.getProject();
    } else {
      this.getProjects();
    }
  }

  componentWillUnmount() {
    sessionStorage.removeItem('SelectedProject');
    this.projectService.abortController.abort();
    this.customerService.abortController.abort();
    this.tenantCurrencyService.abortController.abort();
  }

  getProject = async () => {
    const result = await this.projectService.getProject(
      sessionStorage.getItem('SelectedProject')
    );
    if (result) this.selectProject(result);
    else this.getProjects();
  };

  async getDefaultTenantCurrency() {
    let { defaultTenantCurrency } = this.state;
    const results = await this.tenantCurrencyService.getTenantsCurrencies();
    if (results.length > 0) {
      defaultTenantCurrency = results.find(currency => currency.isDefault)
        .currencyCode;
      this.setState({
        defaultCurrency: defaultTenantCurrency,
        tenantCurrencies: results
      });
    }
  }

  async getCustomers() {
    const results = await this.customerService.getCustomers();
    this.setState({
      customers: results.results
    });
  }

  getProjects = async () => {
    this.setState({ loading: true }, async () => {
      const result = await this.projectService.getProjects();
      this.setState({
        projects: result.results,
        selectedProject: {},
        loading: false
      });
    });
  };

  selectProject(project) {
    const { selectedProject } = this.state;
    if (project.id === selectedProject.id) {
      this.setState({ selectedProject: {} });
    } else if (typeof project === 'object') {
      sessionStorage.setItem('SelectedProject', project.id);
      this.setState({ selectedProject: project });
      this.props.history.push(`/xtratime/projects/${project.id}`);
    }
  }

  clearSelectedProject() {
    sessionStorage.removeItem('SelectedProject');
    this.setState({ selectedProject: {} });
    this.getProjects();
  }

  render() {
    const {
      selectedProject,
      projects,
      defaultCurrency,
      tenantCurrencies,
      customers,
      loading,
      toolbarConfig
    } = this.state;

    const { translate } = this.props;

    let projectTab = null;

    if (typeof tenantCurrencies === 'undefined') {
      return null;
    }

    if (tenantCurrencies === null || typeof tenantCurrencies === 'undefined') {
      return (
        <div>
          <p>{translate('Projects.Empty')}</p>
        </div>
      );
    }

    if (Object.keys(selectedProject).length !== 0) {
      projectTab = (
        <div className="col text-right">
          <NavLink
            id="project-link-team-list"
            className="navlink-netmaa"
            to={`/xtratime/projects/${selectedProject.id}`}
          >
            {translate('Projects.SelectedProject')}: {selectedProject.name}
          </NavLink>
        </div>
      );
    }
    return (
      <div className="container margin-bottom">
        <div className="row">
          <div className="col text-left">
            <NavLink
              id="project-link-list"
              className="navlink-netmaa"
              to="/xtratime/projects"
              onClick={() => this.clearSelectedProject()}
            >
              {translate('Projects.ListProjects')}
            </NavLink>
          </div>
          {projectTab}
        </div>
        <hr />
        <Switch>
          <Route
            exact
            path="/xtratime/projects"
            render={props => (
              <ProjectList
                {...props}
                loading={loading}
                projectService={this.projectService}
                getProjects={this.getProjects}
                projects={projects}
                selectProject={project => this.selectProject(project)}
              />
            )}
          />
          <Route
            exact
            path="/xtratime/projects/create"
            render={props => (
              <ProjectCreate
                {...props}
                projectService={this.projectService}
                getProjects={this.getProjects}
                redirectToProjects={this.redirectToProjects}
                tenantCurrencies={tenantCurrencies}
                defaultCurrency={defaultCurrency}
                customers={customers}
                toolbarConfig={toolbarConfig}
              />
            )}
          />
          <Route
            path={`/xtratime/projects/${selectedProject.id}`}
            render={props => (
              <ProjectDetails
                {...props}
                selectedProject={selectedProject}
                getProjects={this.getProjects}
                projectService={this.projectService}
                tenantCurrencies={tenantCurrencies}
                defaultCurrency={defaultCurrency}
                customers={customers}
                toolbarConfig={toolbarConfig}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withLocalization(ProjectContainer);
