import React, { Component } from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import UserService from '../../../Services/userService';
import TenantService from '../../../Services/tenantService';
import CompanyService from '../../../Services/companyService';
import { withLocalization } from '../../Shared/LocalizationProvider';
import MyInformation from './MyInformation';
import CompanyInformation from './CompanyInformation';
import TenantInformation from './TenantInformation';

class InformationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalUserInfo: {},
      originalTenantInfo: {},
      originalCompanyInfo: {},
      user: {},
      tenant: {},
      company: {},
      initialLoad: true,
      loading: false
    };
    this.userInfoService = new UserService(props.translate);
    this.tenantService = new TenantService(props.translate);
    this.companyService = new CompanyService(
      sessionStorage.getItem('tid'),
      props.translate
    );
  }

  componentDidMount() {
    this.getUserInfo();
  }

  componentWillReceiveProps(newProps) {
    if (newProps !== this.props) {
      if (
        Object.keys(this.state.company).length === 0 &&
        newProps.user &&
        newProps.user.companyAdmin === sessionStorage.getItem('cid')
      )
        this.getCompanyInfo();
      if (
        Object.keys(this.state.tenant).length === 0 &&
        newProps.user &&
        newProps.user.tenantAdmin === sessionStorage.getItem('tid')
      )
        this.getTenantInfo();
    }
  }

  componentWillUnmount() {
    this.userInfoService.abortController.abort();
    this.tenantService.abortController.abort();
    this.companyService.abortController.abort();
  }

  getUserInfo = async () => {
    this.setState({ loading: true });
    const user = await this.userInfoService.getUserInfo();
    if (user) {
      this.setState({
        user,
        originalUserInfo: user
      });
    }
    this.setState({
      loading: false,
      initialLoad: false
    });
  };

  getTenantInfo = async () => {
    this.setState({ loading: true });
    const tenant = await this.tenantService.getTenantInfo(
      sessionStorage.getItem('tid')
    );
    if (tenant) {
      this.setState({
        tenant,
        originalTenantInfo: tenant
      });
    }
    this.setState({
      loading: false,
      initialLoad: false
    });
  };

  getCompanyInfo = async () => {
    this.setState({ loading: true });
    const company = await this.companyService.getCompanyInfo(
      sessionStorage.getItem('cid')
    );
    if (company) {
      this.setState({
        company,
        originalCompanyInfo: company
      });
    }
    this.setState({
      loading: false,
      initialLoad: false
    });
  };

  render() {
    const {
      user,
      tenant,
      company,
      originalUserInfo,
      originalTenantInfo,
      originalCompanyInfo,
      initialLoad,
      loading
    } = this.state;

    const { translate } = this.props;

    return (
      <div className="container">
        <h1>{translate('Navigation.Information')}</h1>
        <hr />
        <div className="row">
          <div className="col text-center">
            <NavLink
              id="information-link-own"
              className="navlink-netmaa"
              exact
              to="/information"
            >
              {translate('Navigation.OwnInfo')}
            </NavLink>
          </div>
          {this.props.user &&
            this.props.user.companyAdmin === sessionStorage.getItem('cid') && (
              <div className="col text-center">
                <NavLink
                  id="information-link-company"
                  className="navlink-netmaa"
                  to="/information/company"
                >
                  {translate('Navigation.CompanyInfo')}
                </NavLink>
              </div>
            )}
          {this.props.user &&
            this.props.user.tenantAdmin === sessionStorage.getItem('tid') && (
              <div className="col text-center">
                <NavLink
                  id="information-link-tenant"
                  className="navlink-netmaa"
                  to="/information/tenant"
                >
                  {translate('Navigation.TenantInfo')}
                </NavLink>
              </div>
            )}
        </div>
        <hr />
        <Switch>
          <Route
            exact
            path="/information"
            component={() => (
              <MyInformation
                user={user}
                originalValues={originalUserInfo}
                getUserInfo={this.getUserInfo}
                userInfoService={this.userInfoService}
                loading={loading}
                initialLoad={initialLoad}
              />
            )}
          />
          {this.props.user &&
            this.props.user.companyAdmin === sessionStorage.getItem('cid') && (
              <Route
                path="/information/company"
                component={() => (
                  <CompanyInformation
                    company={company}
                    originalValues={originalCompanyInfo}
                    getCompanyInfo={this.getCompanyInfo}
                    companyService={this.companyService}
                    loading={loading}
                    initialLoad={initialLoad}
                  />
                )}
              />
            )}
          {this.props.user &&
            this.props.user.tenantAdmin === sessionStorage.getItem('tid') && (
              <Route
                exact
                path="/information/tenant"
                component={() => (
                  <TenantInformation
                    tenant={tenant}
                    originalValues={originalTenantInfo}
                    getTenantInfo={this.getTenantInfo}
                    tenantService={this.tenantService}
                    loading={loading}
                    initialLoad={initialLoad}
                  />
                )}
              />
            )}
        </Switch>
      </div>
    );
  }
}

export default withLocalization(InformationContainer);
