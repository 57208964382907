import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Spinner } from 'reactstrap';
import moment from 'moment';
import { withLocalization } from '../../../Shared/LocalizationProvider';
import 'moment/locale/fi';

class ProjectList extends Component {
  async deleteProject(id) {
    const { projectService, getProjects } = this.props;
    await projectService.deleteProject(id);
    getProjects();
  }

  render() {
    const { translate, projects, selectProject, loading } = this.props;

    let projectTable;

    if (projects.length === 0 && loading === false) {
      projectTable = (
        <div>
          <p>{translate('Projects.NoDataAvailable')}</p>
        </div>
      );
    } else if (projects.length > 0 && loading === false) {
      projectTable = (
        <Table className="table-striped table-hover">
          <thead>
            <tr>
              <th>{translate('Projects.Project')}</th>
              <th>{translate('Projects.StartDate')}</th>
              <th>{translate('Projects.EndDate')}</th>
              <th className="text-right">{translate('Actions.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {projects.map(row => (
              <tr key={row.id}>
                <td>
                  [{row.projectCode || translate('Projects.NoCode')}] {row.name}
                </td>
                <td>
                  {row.startDate !== null
                    ? moment(row.startDate).format('DD/MM/YYYY')
                    : ''}
                </td>
                <td>
                  {row.endDate !== null
                    ? moment(row.endDate).format('DD/MM/YYYY')
                    : ''}
                </td>
                <td className="text-right">
                  <Button
                    id="project-list-button-delete"
                    className="btn btn-danger"
                    onClick={() => this.deleteProject(row.id)}
                  >
                    {translate('Actions.Delete')}
                  </Button>
                  <Button
                    id="project-list-button-edit"
                    className="btn btn-success"
                    onClick={() => selectProject(row)}
                  >
                    {translate('Projects.Details')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else if (loading === true) {
      projectTable = <Spinner type="grow" color="success" size="lg" />;
    }

    return (
      <div>
        {projectTable}
        <Button
          id="project-link-create"
          className="btn btn-lg btn-secondary"
          to="/xtratime/projects/create"
          tag={Link}
        >
          {translate('Projects.CreateProject')}
        </Button>
      </div>
    );
  }
}

export default withLocalization(ProjectList);
