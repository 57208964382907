import ApiHttpService from './apiHttpService';

// Usage:
// class [Controller]Service extends ApiServiceBase {
//     constructor() { super(`${env.settings.api}/api/[Controller]`) }
// }
//
// [const/this.]service = new [Controller]Service();
// service.get(id)
class ApiServiceBase {
  constructor(serviceEndpoint) {
    this.abortController = new AbortController();
    this.http = new ApiHttpService(
      serviceEndpoint,
      this.abortController.signal
    );
  }
}

export default ApiServiceBase;
