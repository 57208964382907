import { toast } from 'react-toastify';
import { apiBaseUrl } from '../env';
import ApiCrudServiceBase from './apiCrudServiceBase';
import { http } from './http';

let userChangeHandler;

export function subscribeToUserChange(handler) {
  userChangeHandler = handler;
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

function parseUser(parsedToken) {
  return {
    name: parsedToken.name,
    role: parsedToken.role,
    companyAdmin: parsedToken.company_admin,
    tenantAdmin: parsedToken.tenant_admin,
    isMultiTenant: false
  };
}

export function getUser() {
  let user = {};
  const token = sessionStorage.getItem('token');

  if (token) {
    user = parseUser(parseJwt(token));
  }

  return user;
}

export async function refreshToken() {
  const refresh = new URLSearchParams();
  refresh.append('refresh_token', sessionStorage.getItem('refresh_token'));
  refresh.append('grant_type', 'refresh_token');
  refresh.append('scope', 'offline_access');

  const queryParameters = {
    src: 'homepage',
    tid: sessionStorage.getItem('tid'),
    cid: sessionStorage.getItem('cid')
  };

  let credentials = null;

  try {
    credentials = await http.post(
      `${apiBaseUrl}api/authorization/token`,
      refresh,
      queryParameters
    );
    if (credentials.status !== 200) {
      sessionStorage.clear();
      throw credentials;
    }
  } catch {
    sessionStorage.clear();
    throw credentials;
  }

  const parsed = parseJwt(credentials.body.access_token);

  sessionStorage.setItem('token', credentials.body.access_token);
  sessionStorage.setItem('cid', parsed.company_user);
  sessionStorage.setItem('tid', parsed.tenant_user);

  userChangeHandler(parseUser(parsed));
}

class LoginService extends ApiCrudServiceBase {
  constructor(translate) {
    super(`${apiBaseUrl}api/authorization/`);
    this.translate = translate;
  }

  getTenants() {
    return this.http.get('/tenants');
  }

  async login(username, password) {
    const login = new URLSearchParams();
    login.append('username', username);
    login.append('password', password);
    login.append('grant_type', 'password');
    login.append('scope', 'offline_access');

    let credentials;
    try {
      credentials = await this.http.post('/token', login, {
        src: 'homepage'
      });
      if (credentials.status !== 200) {
        toast.error(this.translate('Toasts.LoginFailed'));
        return {};
      }
    } catch {
      toast.error(this.translate('Toasts.Failed'));
      return {};
    }

    const parsed = parseJwt(credentials.body.access_token);

    const user = parseUser(parsed);

    sessionStorage.setItem('token', credentials.body.access_token);
    sessionStorage.setItem('refresh_token', credentials.body.refresh_token);
    sessionStorage.setItem('cid', parsed.company_user);
    sessionStorage.setItem('tid', parsed.tenant_user);

    if (
      typeof parsed.tenant_user === 'undefined' &&
      parsed.role !== 'SuperAdmin'
    ) {
      user.isMultiTenant = true;
    }

    return user;
  }

  async changeCompany(id) {
    const params = new URLSearchParams();
    params.append('refresh_token', sessionStorage.getItem('refresh_token'));
    params.append('grant_type', 'change_company');
    params.append('scope', 'offline_access');

    let credentials = null;

    try {
      credentials = await this.http.post('/token', params, {
        src: 'homepage',
        cid: id
      });
      if (credentials.status !== 200) {
        sessionStorage.clear();
        return {};
      }
    } catch {
      sessionStorage.clear();
      return {};
    }

    const parsed = parseJwt(credentials.body.access_token);

    sessionStorage.setItem('token', credentials.body.access_token);
    sessionStorage.setItem('cid', parsed.company_user);
    sessionStorage.setItem('tid', parsed.tenant_user);

    const user = parseUser(parsed);

    return user;
  }
}

export default LoginService;
